<template>
  <div class="hospitalTable">
    <el-aside width="12vw">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @select="selectNav"
      >
        <el-menu-item index="1">
          <span>文献列表</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span>上传文献</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main style="padding: 1vh 2vw" v-if="defaultActive == '1'">
      <div class="form_top">
        <el-form :model="form">
          <el-form-item label="名称：">
            <el-input
              v-model="form.name"
              placeholder="请输入文献名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="文献类型：">
            <el-select v-model="form.type" placeholder="请选择文献类型">
              <el-option label="视频" value="1"></el-option>
              <el-option label="ppt" value="2"></el-option>
              <el-option label="pdf" value="3"></el-option>
              <el-option label="word" value="4"></el-option>
              <el-option label="excel" value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传日期：">
            <el-date-picker
              v-model="form.date"
              format="yyyy-MM-dd HH:mm"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button type="primary" @click="searchData">查询</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
      <el-table
        :data="tableList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column prop="user_name" label="姓名"></el-table-column>
        <el-table-column label="文献类型">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.literature_type == 1"
              @click="watchFile(scope.row.literature_url)"
              >视频</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.literature_type == 2"
              @click="watchFile(scope.row.literature_url)"
              >ppt</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.literature_type == 3"
              @click="watchFile(scope.row.literature_url)"
              >pdf</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.literature_type == 4"
              @click="watchFile(scope.row.literature_url)"
              >word</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.literature_type == 5"
              @click="watchFile(scope.row.literature_url)"
              >excel</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="literature_name"
          label="文献名称"
        ></el-table-column>
        <el-table-column prop="create_dt" label="上传时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="watchFile(scope.row.literature_url)"
              >
              <!-- <a target="_blank" :href="scope.row.literature_url"> -->
                查看
                <!-- </a> -->
                </el-button
            >
            <el-button
              type="text"
              @click="leaveMsg(scope.row.id, scope.row.literature_type)"
              >留言</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[5, 10, 15, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="num"
      >
      </el-pagination>
    </el-main>
    <el-main v-if="defaultActive == '2'">
      <div class="upload_literature">
        <el-form :model="uploadForm">
          <div class="form_item_box">
            <el-form-item label="名称：">
              <el-input
                v-model="uploadForm.literature_name"
                placeholder="请输入文献名称"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="文献类型：">
              <el-select
                v-model="uploadForm.literature_type"
                placeholder="请选择文献类型"
              >
                <el-option label="视频" value="1"></el-option>
                <el-option label="ppt" value="2"></el-option>
                <el-option label="pdf" value="3"></el-option>
                <el-option label="word" value="4"></el-option>
              <el-option label="excel" value="5"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <!-- :data="uploadForm" 
           -->
        <el-upload
          class="upload-demo"
          accept=".ppt,.pdf,.avi,.mp4,.wmv,.mpg,.mpeg,.pptx,.xlsx,.doc,.docx,.xls"
          action="https://api.dahuayoushanyun.com/Api/newpublic/uploadVideo"
          limit="1"
          :on-change="onChange"
          :on-success="handleAvatarSuccess"
        >
          <el-button type="text">选择文件</el-button>
        </el-upload>
      </div>
      <div class="btn_bottom">
        <el-button type="primary" class="upload_btn" @click="uploadFile"
          >上传</el-button
        >
      </div>
    </el-main>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "Literature",
  data() {
    return {
      baseUrl: "https://api.dahuayoushanyun.com",
      defaultActive: "1",
      form: {
        name: "",
        type: "",
        date: [],
      },
      tableList: [],
      uploadForm: {
        literature_name: "",
        literature_type: "",
        user_name: "",
      },
      pageSize: 5,
      currentPage4: 1,
      num: 0,
      wenxianup: "",
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getLiterature();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getLiterature();
    },
    onChange(file) {
      console.log(file, "shudahiud");
      // this.wenxianup = file.raw;
      console.log(this.fileList, "手段是");
    },
    handleAvatarSuccess(file) {
      console.log(file, 111);
      this.wenxianup = file.ret_url;
      if (file.status != 1) {
        this.$message({
          message: file.msg,
          type: "error",
        });
      }
    },
    headerStyle() {
      return "background:#FAFAFA;text-align:center";
    },
    rowStyle() {
      return "text-align:center;";
    },
    selectNav(index) {
      this.defaultActive = index;
    },
    formatTimeMills(timeMills) {
      var date = new Date(timeMills);
      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    // 上传按钮
    uploadFile() {
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/api/newpublic/addliterature`,
        data:qs.stringify({
          literature_url:this.wenxianup,
          literature_type:this.uploadForm.literature_type,
          literature_name:this.uploadForm.literature_name,
          user_name:this.uploadForm.user_name
        }),
         headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        if (res.data.status == "1") {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.uploadForm.literature_name = ''
          this.uploadForm.literature_type = ''
          this.defaultActive = "1";
          this.getLiterature();
        }
      });
    },
    // 获取文献列表
    getLiterature() {
      let time = "";
      let time1 = "";
      if (this.form.date.length != 0) {
        time = this.formatTimeMills(this.form.date[0]);
        time1 = this.formatTimeMills(this.form.date[1]);
      } else {
        time = "";
        time1 = "";
      }
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/newpublic/literaturelist?user_access_token=${token}&page=${this.pageSize}&page_num=${this.currentPage4}&literature_type=${this.form.type}&name=${this.form.name}&time=${time}&time1=${time1}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        console.log(res, "时代胡");
        this.tableList = res.data.data.literaturelist;
        this.num = res.data.data.num;
      });
    },
    searchData() {
      this.getLiterature();
    },
    // 跳转到留言页面
    leaveMsg(id, literature_type) {
      console.log("// 跳转到留言页面");
      this.$emit("handleTab", 3);
      this.$router.push({
        path: "/Message",
        query: {
          id: id,
          status: literature_type,
        },
      });
    },
    // 查看
    watchFile(url) {
      // url = "https://api.dahuayoushanyun.com" + url;
      window.open(url, "_blank");
    },
    reset() {
      this.form.name = "";
      this.form.type = "";
      this.form.date = [];
      this.getLiterature();
    },
  },
  mounted() {
    this.$emit("handleTab", 2);
    this.uploadForm.user_name = localStorage.getItem("username");
    this.getLiterature();
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.el-menu-item {
  font-size: rem(16) !important;
}

.hospitalTable {
  width: 100%;
  display:flex;
  // border 1px solid red
  margin: 0 0;
  padding: 0 0;
}

.form_top {
  display: flex;
  height 5vh;
  margin-bottom: 2vh;

}
.form_top .el-form {
  flex: 100%;
  display: flex;
  margin-right: 2vw;
  line-height: 5vh;
}
.hospitalTable .btn_box {
  flex: 10%;
  height: 5vh;
  display:flex;
  align-items: center;
}
.form_top .el-form-item__label {
    font-size: rem(10);
    width: 40%;
}
.form_top .el-form .el-form-item__content {
    margin-right: 2vw;
}
.form_top .el-form-
.btn_box .el-button {
  width: 4.5vw;
  height:4vh;
  padding: 0;
}
.form_top .el-form-item {
  display:flex;
  flex-wrap: nowrap;
}
.el-pagination {
  margin-top: 1vh;
  padding-right: 2vw !important;
}
.form_top .el-button+.el-button:hover {
  background: #909399 !important;
}
.upload_literature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
}
.upload_literature .el-form-item {
    margin-bottom: 5vh;
}
.upload_literature .el-form-item__label {
    font-size: rem(18);
    text-align: center;
    width:100%;
    font-weight: 550;
}
.upload_literature .el-input__inner {
    width: 15.6vw;
}
.upload_literature .el-input__inner::placeholder {
    text-align: center;
}
.upload-demo {
    width: 20vw;
    height: 10vh;
    margin-top: -10vh;
}
.upload_btn {
    position: fixed;
    right: rem(86);
    bottom: rem(114);
    width: 6vw;
    height: 4vh;
    line-height: 4vh !important;
    padding: 0 !important;
}
</style>
