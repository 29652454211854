<template>
  <div class="CheckUser">
    <div class="check-lun">
      <button
        style="position: absolute; z-index: 100000"
        @click="youchang"
        class="left"
      >
        <span> &lt;</span>
      </button>
      <button
        style="position: absolute; z-index: 100000"
        @click="change"
        class="you"
      >
        <span> &gt; </span>
      </button>
      <div class="check-box" :style="{ marginLeft: marginLeft + 'rem' }">
        <div class="firstq-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">随访病历查询 </span>
          <div class="firstq-deils">
            <div class="firstq-hang">
              <p>
                <span>姓名</span>
                <input
                  type="text"
                  v-model="name"
                  placeholder="请输入姓名"
                  name=""
                />
              </p>
              <p>
                <span>年龄</span>
                <input
                  type="text"
                  v-model="userYear"
                  placeholder="请输入年龄"
                  name=""
                />
              </p>
              <p>
                <span>性别</span>
                <input
                  v-model="valuesex"
                  class="userSex"
                  list="options14"
                  placeholder="请选择性别"
                />
                <datalist id="options14">
                  <option value="男">男</option>
                  <option value="女">女</option>
                </datalist>
              </p>
              <p>
                <span>籍贯</span>
                <input
                  type="text"
                  v-model="UserJi"
                  placeholder="请输入籍贯"
                  name=""
                />
              </p>
            </div>
            <div class="firstq-hang">
              <p>
                <span>职业</span>
                <input
                  type="text"
                  v-model="userjob"
                  name=""
                  placeholder="请输入职业"
                />
              </p>
              <p>
                <span>国籍</span>
                <input
                  type="text"
                  v-model="UserGuo"
                  name=""
                  placeholder="请输入国籍"
                />
              </p>
              <p>
                <span>民族</span>
                <input type="text" v-model="UserMin" placeholder="请输入民族" />
              </p>
            </div>
            <div class="thidq-hang">
              <p>
                <span>身份证号</span>
                <input
                  type="text"
                  v-model="UserID"
                  name=""
                  placeholder="请输入身份证号"
                />
              </p>
              <p>
                <span>电话号码</span>
                <input
                  type="text"
                  v-model="UserDian"
                  name=""
                  placeholder="请输入电话号码"
                />
              </p>
            </div>
            <div class="forthq-hang">
              <p>
                <span>手机号码</span>
                <input
                  type="text"
                  v-model="UserPhone"
                  name=""
                  placeholder="请输入手机号码"
                />
              </p>
               <p>
                <span>所在医院</span>
                <input
                  type="text"
                  v-model="addressHos"
                  list="suozai"
                  id="address"
                  name=""
                  placeholder="请输入所在医院名称"
                />
                <datalist id="suozai">
                  <option value="大华医院">大华医院</option>
                  <option value="徐汇中心医院">徐汇中心医院</option>
                  <option value="第八人民医院">第八人民医院</option>
                </datalist>
              </p>
            </div>
                <div class="six11-hang">
              <p>
                <span>科室</span>
                <input
                  type="text"
                  v-model="UserKe"
                  name=""
                  placeholder="请输入科室"
                />
              </p>
              <div class="block1">
                <span class="chutiem">手术日期</span>
                <el-date-picker
                  v-model="value1"
                  format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="seven11-hang">
              <p>
                <span>住院天数</span>
                <input type="text" v-model="zhuyuantian" name="" />
              </p>

              <div class="block4">
                <span class="chutime">复诊日期:</span>
                <el-date-picker
                  v-model="value24"
                  type="date"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="fif11-hang">
              <p>
                <span>随访时间间隔</span>

                <input
                  list="suifang"
                  id="editablesuifang"
                  v-model="suifang"
                  placeholder="请选择随访时间间隔"
                />
                <datalist id="suifang">
                  <option value="手术后1月内每周一次">
                    手术后1月内每周一次
                  </option>
                  <option value="术后1月-6月内每月一次">
                    术后1月-6月内每月一次
                  </option>
                  <option value="术后6月后每年一次">术后6月后每年一次</option>
                </datalist>
              </p>
              <p>
                <span>有无发热</span>
                <input
                  list="suifa"
                  id="editablesuifa"
                  v-model="fare"
                  placeholder="请选择有无发热"
                />
                <datalist id="suifa">
                  <option value="有">有</option>
                  <option value="无">无</option>
                </datalist>
              </p>
            </div>
            <div class="six11-hang">
              <p>
                <span>疼痛评分VAS</span>
                <input
                  type="text"
                  v-model="tengping"
                  placeholder="请输入疼痛评分"
                  name=""
                />
                1-10分
              </p>
            </div>
          </div>
        </div>
        <div class="Second7-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">随访病历查询 </span>
          <div class="two7-deils">
            <div class="twofirst7-hang">
              <p>
                <span>伤口愈合情况</span>
                <input
                  list="optionsyu"
                  id="editableyu"
                  v-model="shangfen2"
                  placeholder="伤愈分类"
                />
                <datalist id="optionsyu">
                  <option value="Ⅰ">Ⅰ</option>
                  <option value="Ⅱ">Ⅱ</option>
                  <option value="Ⅲ">Ⅲ</option>
                  <option value="Ⅳ">Ⅳ</option>
                </datalist>
              </p>
              <p>
                <input
                  list="optionsdfeng"
                  id="dengyuhe"
                  v-model="shangdeng2"
                  placeholder="愈合等级"
                />
                <datalist id="optionsdfeng">
                  <option value="甲">甲</option>
                  <option value="乙">乙</option>
                  <option value="丙">丙</option>
                </datalist>
              </p>
            </div>
            <div class="twofirst7-hang">
              <p>
                <span>有无并发症</span>
                <input
                  list="options9"
                  id="bin"
                  v-model="binfa"
                  placeholder="请选择有无并发症"
                />
                <datalist id="options9">
                  <option value="伤口积液">伤口积液</option>
                  <option value="伤口裂开">伤口裂开</option>
                  <option value="阴囊水肿">阴囊水肿</option>
                  <option value="下肢血栓">下肢血栓</option>
                </datalist>
              </p>
              <p>
                <span>有无复发</span>
                <input
                  type="text"
                  list="optionsfu"
                  placeholder="请选择有无复发"
                  name=""
                  v-model="isHave"
                  id="isHave"
                />
                <datalist id="optionsfu">
                  <option value="有">有</option>
                  <option value="无">无</option>
                </datalist>
              </p>
            </div>
            <div class="thid7-hang">
              <div class="xin--bc">
                <span class="xin2">心电图</span>
                <img class="xinB" src="./assets/jiahao.png" />
                <p class="upBc">上传心电图</p>
                <el-upload
                  class="el--upload55"
                  ref="uploadExcel55"
                  action="#"
                  :limit="limitNum55"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange55"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl55"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG5(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="forth27-hang">
              <div class="xin--bc">
                <span class="xin2">胸片</span>
                <img class="xinB" src="./assets/jiahao.png" />
                <p class="upBc">上传胸片</p>
                <el-upload
                  class="el--upload66"
                  ref="uploadExcel66"
                  action="#"
                  :limit="limitNum66"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange66"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl66"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG6(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>
        <div class="three32-show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">随访病历查询 </span>
          <div class="three22-colim">
            <div class="xiong2">
              <div class="xin--bc">
                <span class="xin2">B超</span>
                <img class="xinB" src="./assets/jiahao.png" />
                <p class="upBc">上传B超</p>
                <el-upload
                  class="el--upload77"
                  ref="uploadExcel77"
                  action="#"
                  :limit="limitNum77"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange77"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl77"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG7(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="bpic2">
              <div class="xin--bc">
                <span class="xin2">CT</span>
                <img class="xinB" src="./assets/jiahao.png" />
                <p class="upBc">上传CT</p>
                <el-upload
                  class="el--upload88"
                  ref="uploadExcel88"
                  action="#"
                  :limit="limitNum88"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange88"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl88"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG8(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>
        <div class="Second9-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">随访病历查询 </span>
          <div class="two9-deils">
            <div class="twofirst9-hang">
              <p>
                <span>血常规</span>
                <input
                  type="text"
                  v-model="shuhouxue"
                  name=""
                  placeholder="请输入血常规检测结果"
                />
              </p>
              <p>
                <span>尿常规</span>
                <input
                  type="text"
                  v-model="shuhouniao2"
                  name=""
                  placeholder="请输入尿常规检测结果"
                />
              </p>
            </div>
            <div class="twofirst9-hang">
              <p>
                <span>粪常规</span>
                <input
                  type="text"
                  v-model="shuhoufen"
                  name=""
                  placeholder="请输入粪常规检测结果"
                />
              </p>
              <p>
                <span>血糖</span>
                <input
                  type="text"
                  v-model="shuhoutang"
                  name=""
                  placeholder="请输入血糖检测结果"
                />
              </p>
            </div>
            <div class="thid9-hang">
              <p>
                <span>肝功能</span>
                <input
                  type="text"
                  v-model="shuhougan"
                  name=""
                  placeholder="请输入肝功能检测结果"
                />
              </p>
              <p>
                <span>肾功能</span>
                <input
                  type="text"
                  v-model="shushen"
                  name=""
                  placeholder="请输入肾功能检测结果"
                />
              </p>
            </div>
            <div class="forth29-hang">
              <p>
                <span>电解质</span>
                <input
                  type="text"
                  v-model="shuhoudian"
                  name=""
                  placeholder="请输入电解质检测结果"
                />
              </p>
              <p>
                <span>血凝</span>
                <input
                  type="text"
                  v-model="shuhouning"
                  name=""
                  placeholder="请输入血凝检测结果"
                />
              </p>
            </div>
            <div class="fif29-hang">
              <p>
                <span>是否失访</span>
                <input
                  list="options10"
                  id="isShifang"
                  v-model="isFang"
                  placeholder="请选择是否失访"
                />
                <datalist id="options10">
                  <option value="死亡">死亡</option>
                  <option value="搬离">搬离</option>
                  <option value="拒绝">拒绝</option>
                </datalist>
              </p>
            </div>
            <button class="btn-sub222" @click="saveBtnCon">提交</button>
          </div>
        </div>
      </div>

      <div class="ulbox2">
        <p
          :class="[index == 0 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(0)"
        ></p>
        <p
          :class="[index == 1 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(1)"
        ></p>
        <p
          :class="[index == 2 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(2)"
        ></p>
        <p
          :class="[index == 3 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(3)"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyAppFirst",

  data() {
    return {
      marginLeft: 0,
      index: 0,
      baseUrl: "https://api.dahuayoushanyun.com",
      value24: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      addDetail: {},
      editlist: false,
      editDetail: {},
      newsList: [],
      editid: "",
      addDetail2: {},
      editlist2: false,
      editDetail2: {},
      newsList2: [],
      editid2: "",
      value1: "",
      value2: "",
      name: "",
      userYear: "",
      userjob: "",
      UserGuo: "",
      UserMin: "",
      UserJi: "",
      UserID: "",
      UserDian: "",
      UserPhone: "",
      addressHos: "",
      zhuyuanhao: "",
      UserKe: "",
      zhuyuantian: "",
      UserFee: "",
      UserMedicne: "",
      yiliaofee: "",
      UserZhen: "",
      Userzheng: "",
      UserShan: "",
      xiyannian: "",
      yinjiunian: "",
      qitanian: "",
      UserYue: "",
      UserHun: "",
      UserTi: "",
      UserXue: "",
      UserMai: "",
      UserShen: "",
      UserTizhong: "",
      UserBmi: "",
      Userxue: "",
      UserNiao: "",
      UserFen: "",
      UserXuetang: "",
      UserShou1: "",
      UserGan: "",
      UserShen2: "",
      UserDianjie: "",
      UserNing: "",
      Userqita: "",
      UserIn: "",
      UserChu: "",
      zhiname: "",
      zhixing: "",
      zhichi: "",
      zhifee: "",
      zhudao: "",
      shuhouTime: "",
      niaoTime: "",
      shuhouqi: "",
      kangjuntime: "",
      daoniaotime: "",
      yinliubaTime: "",
      shuhoubing: "",
      shangchai: "",
      tengping: "",
      shuhouxue: "",
      shuhouniao2: "",
      shuhoufen: "",
      shuhoutang: "",
      shuhougan: "",
      shushen: "",
      shuhoudian: "",
      shuhouning: "",
      limitNum11: 10,
      limitNum22: 10,
      limitNum33: 10,
      limitNum44: 10,
      limitNum55: 10,
      limitNum66: 10,
      limitNum77: 10,
      limitNum88: 10,
      valueisFu: "",
      valuesex: "",
      arrshoushufang: {},
      changzhun: "",
      daoniaozhun: "",
      kangyu: "",
      isHave: "",
      form: {
        file: "",
        type: 1,
      },
      qianxinUrl: [],
      form2: {
        file2: "",
        type2: 1,
      },
      qianxinUrl2: [],
      form3: {
        file3: "",
        type3: 1,
      },
      qianxinUrl3: [],
      form4: {
        file4: "",
        type4: 1,
      },
      qianxinUrl4: [],

      qianxinUrl55: [],
      form55: {
        file55: "",
        type55: 1,
      },
      qianxinUrl66: [],
      form66: {
        file66: "",
        type66: 1,
      },
      qianxinUrl77: [],
      form77: {
        file77: "",
        type77: 1,
      },
      qianxinUrl88: [],
      form88: {
        file88: "",
        type88: 1,
      },
      buwei: "",
      shanxing: "",
      fuya: "",
      heji: "",
      mazui: "",
      shoushu: "",
      suifang: "",
      fare: "",
      binfa: "",
      isFang: "",
      shangdeng: "",
      shangfen: "",
      shangdeng2: "",
      shangfen2: "",
    };
  },
  components: {},

  mounted() {
    this.getUidData();
  },

  methods: {
    shanIMG(item) {
      this.qianxinUrl.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl.indexOf(itemcheng);
          this.qianxinUrl.splice(indexwei, 1);
        }
      });
    },
    shanIMG2(item) {
      this.qianxinUrl2.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl2.indexOf(itemcheng);
          this.qianxinUrl2.splice(indexwei, 1);
        }
      });
    },
    shanIMG3(item) {
      this.qianxinUrl3.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl3.indexOf(itemcheng);
          this.qianxinUrl3.splice(indexwei, 1);
        }
      });
    },
    shanIMG4(item) {
      this.qianxinUrl4.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl4.indexOf(itemcheng);
          this.qianxinUrl4.splice(indexwei, 1);
        }
      });
    },
    shanIMG5(item) {
      this.qianxinUrl55.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl55.indexOf(itemcheng);
          this.qianxinUrl55.splice(indexwei, 1);
        }
      });
    },
    shanIMG6(item) {
      this.qianxinUrl66.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl66.indexOf(itemcheng);
          this.qianxinUrl66.splice(indexwei, 1);
        }
      });
    },
    shanIMG7(item) {
      this.qianxinUrl77.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl77.indexOf(itemcheng);
          this.qianxinUrl77.splice(indexwei, 1);
        }
      });
    },
    shanIMG8(item) {
      this.qianxinUrl88.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl88.indexOf(itemcheng);
          this.qianxinUrl88.splice(indexwei, 1);
        }
      });
    },
    change() {
      this.index++;
      if (this.index > 3) {
        this.index = 3;
      } else {
        this.marginLeft = 6.25 * -this.index;
      }

      if (this.marginLeft < -62.5) {
        this.marginLeft = -62.5;
      }
    },
    youchang() {
      this.index--;
      if (this.index < 0) {
        this.index = 0;
      } else {
        this.marginLeft += 6.25;
      }
    },
    changeIndex(index) {
      this.index = index;

      this.marginLeft = -this.index * 6.25;
    },
    getUidData() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$store.dispatch("setUid");
      let uid = this.$store.getters.getUid;
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/Api/Applets/ysOne?user_access_token=${token}&type=2`,
        data: {
          list: [
            {
              id: Number(uid),
            },
          ],
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        let resData = res.data.data;
        if (resData.name) {
          this.name = resData.name;
        }

        if (resData.age) {
          this.userYear = resData.age;
        }

        if (resData.sex) {
          this.valuesex = resData.sex;
        }

        if (resData.profession) {
          this.userjob = resData.profession;
        }

        if (resData.country) {
          this.UserGuo = resData.country;
        }

        if (resData.nationality) {
          this.UserMin = resData.nationality;
        }

        if (resData.hometown) {
          this.UserJi = resData.hometown;
        }

        if (resData.card) {
          this.UserID = resData.card;
        }

        if (resData.phone) {
          this.UserDian = resData.tel;
        }

        if (resData.tel) {
          this.UserPhone = resData.phone;
        }

        if (resData.hospital) {
          this.addressHos = resData.hospital;
        }

        if (resData.number) {
          this.zhuyuanhao = resData.number;
        }

        if (resData.department) {
          this.UserKe = resData.department;
        }

        if (resData.ssrqi) {
          this.value1 = resData.ssrqi;
        }

        if (resData.fzrq) {
          this.value24 = resData.fzrq;
        }

        if (resData.discharge) {
          this.zhuyuantian = resData.discharge;
        }

        if (resData.hospital_costs) {
          this.UserFee = resData.hospital_costs;
        }

        if (resData.medicine_fee) {
          this.UserMedicne = resData.medicine_fee;
        }

        if (resData.m_consumables) {
          this.yiliaofee = resData.m_consumables;
        }

        if (resData.diagnosis) {
          this.UserZhen = resData.diagnosis;
        }

        if (resData.location) {
          this.buwei = resData.location;
        }
        if (resData.it_recurred) {
          this.valueisFu = resData.it_recurred;
        }

        if (resData.onset_time) {
          this.value2 = resData.onset_time;
        }
        if (resData.symptoms) {
          this.Userzheng = resData.symptoms;
        }

        if (resData.hernia) {
          this.UserShan = resData.hernia;
        }

        if (resData.hernia_type) {
          this.shanxing = resData.hernia_type;
        }

        if (resData.pressure) {
          this.fuya = resData.pressure;
        }

        if (resData.smoking) {
          this.xiyannian = resData.smoking;
        }

        if (resData.drinking) {
          this.yinjiunian = resData.drinking;
        }

        if (resData.other) {
          this.qitanian = resData.other;
        }

        if (resData.menstrual_history) {
          this.UserYue = resData.menstrual_history;
        }

        if (resData.childbirth) {
          this.UserHun = resData.childbirth;
        }

        if (resData.body_temperature) {
          this.UserTi = resData.body_temperature;
        }

        if (resData.blood_pressure) {
          this.UserXue = resData.blood_pressure;
        }

        if (resData.pulse) {
          this.UserMai = resData.pulse;
        }

        if (resData.height) {
          this.UserShen = resData.height;
        }

        if (resData.weight) {
          this.UserTizhong = resData.weight;
        }

        if (resData.bmi_value) {
          this.UserBmi = resData.bmi_value;
        }

        if (resData.comorbidity) {
          this.heji = resData.comorbidity;
        }

        if (resData.blood_routine) {
          this.Userxue = resData.blood_routine;
        }

        if (resData.fecal_routine) {
          this.UserFen = resData.fecal_routine;
        }

        if (resData.urine_routine) {
          this.UserNiao = resData.urine_routine;
        }

        if (resData.blood_sugar) {
          this.UserXuetang = resData.blood_sugar;
        }

        if (resData.liver_function) {
          this.UserGan = resData.liver_function;
        }

        if (resData.kidney_function) {
          this.UserShen2 = resData.kidney_function;
        }

        if (resData.electrolyte) {
          this.UserDianjie = resData.electrolyte;
        }

        if (resData.hemagglutination) {
          this.UserNing = resData.hemagglutination;
        }

        if (resData.other_results) {
          this.Userqita = resData.other_results;
        }

        if (resData.b_preparation) {
          this.changzhun = resData.b_preparation;
        }

        if (resData.catheterization) {
          this.daoniaozhun = resData.catheterization;
        }

        if (resData.antibiotics) {
          this.kangyu = resData.antibiotics;
        }

        if (resData.anesthesia) {
          this.mazui = resData.anesthesia;
        }

        if (resData.surgical_approach) {
          this.arrshoushufang = resData.surgical_approach;
        }

        if (resData.implant_patch_name) {
          this.zhiname = resData.implant_patch_name;
        }

        if (resData.implanted_patch_model) {
          this.zhixing = resData.implanted_patch_model;
        }

        if (resData.implant_patch_size) {
          this.zhichi = resData.implant_patch_size;
        }
        if (resData.implantation_patch_cost) {
          this.zhifee = resData.implantation_patch_cost;
        }

        if (resData.about_drainage) {
          this.UserIn = resData.about_drainage;
        }

        if (resData.bleeding_volume) {
          this.UserChu = resData.bleeding_volume;
        }

        if (resData.operation_time) {
          this.value3 = resData.operation_time;
        }

        if (resData.surgeon_information) {
          this.newsList2 = resData.surgeon_information;
        }

        if (resData.postoperative_information) {
          this.shuhouTime = resData.postoperative_information;
        }

        if (resData.surgery_time) {
          this.newsList = resData.surgery_time;
        }

        if (resData.urination_time) {
          this.value4 = resData.urination_time;
        }

        if (resData.defecation_time) {
          this.value5 = resData.defecation_time;
        }

        if (resData.defecation_time_after) {
          this.value6 = resData.defecation_time_after;
        }

        if (resData.antibacterial_time) {
          this.value7 = resData.antibacterial_time;
        }

        if (resData.catheter_removal_time) {
          this.value8 = resData.catheter_removal_time;
        }
        if (resData.drainage_tube_removal_time) {
          this.value9 = resData.drainage_tube_removal_time;
        }
        if (resData.postoperative_complications) {
          this.shuhoubing = resData.postoperative_complications;
        }
        if (resData.suture_removal_time) {
          this.value10 = resData.suture_removal_time;
        }

        if (resData.wound_healing) {
          this.shangfen = resData.wound_healing.shangeyu;
        }
        if (resData.wound_healing) {
          this.shangdeng = resData.wound_healing.selectjia;
        }
        if (resData.follow_up_interval) {
          this.suifang = resData.follow_up_interval;
        }
        if (resData.fever) {
          this.fare = resData.fever;
        }
        if (resData.pain_score_vas) {
          this.tengping = resData.pain_score_vas;
        }
        if (resData.electrocardiogram) {
          this.qianxinUrl = resData.electrocardiogram;
        }
        if (resData.radiograph) {
          this.qianxinUrl2 = resData.radiograph;
        }

        if (resData.ultrasound) {
          this.qianxinUrl3 = resData.ultrasound;
        }

        if (resData.ct) {
          this.qianxinUrl4 = resData.ct;
        }
        if (resData.ecg_2) {
          this.qianxinUrl55 = resData.ecg_2;
        }
        if (resData.x_ray_2) {
          this.qianxinUrl66 = resData.x_ray_2;
        }
        if (resData.super_2) {
          this.qianxinUrl77 = resData.super_2;
        }
        if (resData.ct2) {
          this.qianxinUrl88 = resData.ct2;
        }

        if (resData.wound_healing2) {
          this.shangdeng2 = resData.wound_healing2.editableyuval;
        }
        if (resData.wound_healing2) {
          this.shangfen2 = resData.wound_healing2.dengyuheVal;
        }
        if (resData.complications_2) {
          this.binfa = resData.complications_2;
        }
        if (resData.recurrence) {
          this.isHave = resData.recurrence;
        }

        if (resData.blood_routine_2) {
          this.shuhouxue = resData.blood_routine_2;
        }
        if (resData.urine_routine1) {
          this.shuhouniao2 = resData.urine_routine1;
        }
        if (resData.fecalroutin_2) {
          this.shuhoufen = resData.fecalroutin_2;
        }

        if (resData.blood_sugar_2) {
          this.shuhoutang = resData.blood_sugar_2;
        }
        if (resData.liver_function_2) {
          this.shuhougan = resData.liver_function_2;
        }
        if (resData.kidney_function_2) {
          this.shushen = resData.kidney_function_2;
        }

        if (resData.electrolyte_2) {
          this.shuhoudian = resData.electrolyte_2;
        }
        if (resData.hemagglutination_2) {
          this.shuhouning = resData.hemagglutination_2;
        }
        if (resData.is_follow_up) {
          this.isFang = resData.is_follow_up;
        }
      });
    },
    //   新店
    // 文件状态改变时的钩子
    fileChange11(file) {
      this.$refs.uploadExcel11.clearFiles(); //去掉文件列表
      this.form.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile11();
      }
    },

    uploadFile11() {
      this.$refs.uploadExcel11.submit();
      this.$refs.uploadExcel11.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange22(file) {
      this.$refs.uploadExcel22.clearFiles(); //去掉文件列表
      this.form2.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile22();
      }
    },

    uploadFile22() {
      this.$refs.uploadExcel22.submit();
      this.$refs.uploadExcel22.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form2.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl2.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange33(file) {
      this.$refs.uploadExcel33.clearFiles(); //去掉文件列表
      this.form3.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile33();
      }
    },

    uploadFile33() {
      this.$refs.uploadExcel33.submit();
      this.$refs.uploadExcel33.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form3.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl3.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange44(file) {
      this.$refs.uploadExcel44.clearFiles(); //去掉文件列表
      this.form4.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile44();
      }
    },

    uploadFile44() {
      this.$refs.uploadExcel44.submit();
      this.$refs.uploadExcel44.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form4.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl4.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    //   新店
    // 文件状态改变时的钩子
    fileChange55(file) {
      this.$refs.uploadExcel55.clearFiles(); //去掉文件列表
      this.form55.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile55();
      }
    },

    uploadFile55() {
      this.$refs.uploadExcel55.submit();
      this.$refs.uploadExcel55.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form55.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl55.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange66(file) {
      this.$refs.uploadExcel66.clearFiles(); //去掉文件列表
      this.form66.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile66();
      }
    },

    uploadFile66() {
      this.$refs.uploadExcel66.submit();
      this.$refs.uploadExcel66.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form66.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl66.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange77(file) {
      this.$refs.uploadExcel77.clearFiles(); //去掉文件列表
      this.form77.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile77();
      }
    },

    uploadFile77() {
      this.$refs.uploadExcel77.submit();
      this.$refs.uploadExcel77.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form77.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl77.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange88(file) {
      this.$refs.uploadExcel88.clearFiles(); //去掉文件列表
      this.form88.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile88();
      }
    },

    uploadFile88() {
      this.$refs.uploadExcel88.submit();
      this.$refs.uploadExcel88.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form88.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl88.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    //新增
    adddetail() {
      //这里的思路应该是把this.addDetail传给服务端，然后加载列表this.newsList
      //this.newsList.push(this.addDetail)
      this.newsList.push({
        tiwen: this.addDetail.tiwen,
        xueya: this.addDetail.xueya,
        maibo: this.addDetail.maibo,
        niao: this.addDetail.niao,
        yinliu: this.addDetail.yinliu,
        tengtong: this.addDetail.tengtong,

        id: Math.floor(Math.random() * 1000000 + 1),
      });
    },
    //删除
    deletelist(id, i) {
      this.newsList.splice(i, 1);
    },
    //编辑
    edit(item) {
      this.editDetail = {
        tiwen: this.addDetail.tiwen,
        xueya: this.addDetail.xueya,
        maibo: this.addDetail.maibo,
        niao: this.addDetail.niao,
        yinliu: this.addDetail.yinliu,
        tengtong: this.addDetail.tengtong,
        id: item.id,
      };
      this.editlist = true;
      this.editid = item.id;
    },
    //确认更新
    update() {
      //编辑的话，也是传id去服务端
      //axios.get('url',{ID:id}).then((res) =>{
      //			加载列表
      //})
      let _this = this;
      for (let i = 0; i < _this.newsList.length; i++) {
        if (_this.newsList[i].id == this.editid) {
          _this.newsList[i] = {
            tiwen: this.addDetail.tiwen,
            xueya: this.addDetail.xueya,
            maibo: this.addDetail.maibo,
            niao: this.addDetail.niao,
            yinliu: this.addDetail.yinliu,
            tengtong: this.addDetail.tengtong,
            id: this.editid,
          };
          this.editlist = false;
        }
      }
    },
    //新增
    adddetail2() {
      //这里的思路应该是把this.addDetail传给服务端，然后加载列表this.newsList
      //this.newsList.push(this.addDetail)
      this.newsList2.push({
        tiwen: this.addDetail2.tiwen,
        xueya: this.addDetail2.xueya,
        zhuzhi: this.addDetail2.zhuzhi,
        maibo: this.addDetail2.maibo,
        yizhi: this.addDetail2.yizhi,
        niao: this.addDetail2.niao,
        erzhi: this.addDetail2.erzhi,

        id: Math.floor(Math.random() * 1000000 + 1),
      });
    },
    //删除
    deletelist2(id, i) {
      this.newsList2.splice(i, 1);
    },
    //编辑
    edit2(item) {
      this.editDetail2 = {
        tiwen: this.addDetail2.tiwen,
        xueya: this.addDetail2.xueya,
        zhuzhi: this.addDetail2.zhuzhi,
        maibo: this.addDetail2.maibo,
        yizhi: this.addDetail2.yizhi,
        niao: this.addDetail2.niao,
        erzhi: this.addDetail2.erzhi,
        id: item.id,
      };
      this.editlist2 = true;
      this.editid2 = item.id;
    },
    //确认更新
    update2() {
      //编辑的话，也是传id去服务端
      //axios.get('url',{ID:id}).then((res) =>{
      //			加载列表
      //})
      let _this = this;
      for (let i = 0; i < _this.newsList2.length; i++) {
        if (_this.newsList2[i].id == this.editid2) {
          _this.newsList2[i] = {
            tiwen: this.addDetail2.tiwen,
            xueya: this.addDetail2.xueya,
            zhuzhi: this.addDetail2.zhuzhi,
            maibo: this.addDetail2.maibo,
            yizhi: this.addDetail2.yizhi,
            niao: this.addDetail2.niao,
            erzhi: this.addDetail2.erzhi,

            id: this.editid2,
          };
          this.editlist2 = false;
        }
      }
    },
    // 日期格式化
    /* 将时间戳格式化为yyyy-MM-dd hh:mm:ss格式，其它格式可自行更改 */
    formatTimeMills(timeMills) {
      var date = new Date(timeMills);

      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    cancelBtn() {
      this.$router.push("/DocHou");
    },
    saveBtnCon() {
      // 時間1
      var resDate1,
        resDate2,
        resDate3,
        resDate4,
        resDate5,
        resDate6,
        resDate7,
        resDate8,
        resDate9,
        resDate10,
        resDate11;
      if (this.value1) {
        resDate1 = this.formatTimeMills(this.value1); //住院
      }
      if (this.value24) {
        resDate2 = this.formatTimeMills(this.value24); //住院
      }
    

      let selectSexVal = this.$(".userSex").find("option:selected").text();
      let UserBu = this.$("#editable-select").val();
      let UsersFu = this.$(".UsersFu").find("option:selected").text();
      let shanfen = this.$("#shanfen").val();
      let fuyayuan = this.$("#fuyayuan").val();
      let heji = this.$("#heji").val();

      let mazuifang = this.$("#mazuifang").val();

      let shoushufang = this.$("#shoushufang").val(); //注意

      let editabledeil = this.$("#editable-deil").val();
      let arrshoushufang = {
        shoushufang: shoushufang,
        editabledeil: editabledeil,
      };

      let shangyuhge = this.$("#shangyuhge").val(); //注意
      let selectjia = this.$("#editable-selectyuhe").val();
      let arrshang = { shangeyu: shangyuhge, selectjia: selectjia };

      let editableyuval = this.$("#editableyu").val();
      let dengyuheVal = this.$("#dengyuhe").val();
      let dengyuhe = {
        editableyuval: editableyuval,
        dengyuheVal: dengyuheVal,
      };

      let editablesuifang = this.$("#editablesuifang").val();
      let editablesuifa = this.$("#editablesuifa").val();
      //上虞
      let bin = this.$("#bin").val();

      let isShifang = this.$("#isShifang").val();
      let isHave = this.$("#isHave").val();
      let address=this.$("#address").val()
      let listitem = {
        name: this.name,
        age: this.userYear,
        sex: selectSexVal,
        profession: this.userjob,
        country: this.UserGuo,
        nationality: this.UserMin,
        hometown: this.UserJi,
        card: this.UserID,

        phone: this.UserDian,

        tel: this.UserPhone,

        hospital: address,
        number: this.zhuyuanhao,

        department: this.UserKe,

        ssrqi: resDate1,

        fzrq: resDate2,

        discharge: this.zhuyuantian,

        hospital_costs: this.UserFee,

        medicine_fee: this.UserMedicne,

        m_consumables: this.yiliaofee,

        diagnosis: this.UserZhen,

        location: UserBu,

        it_recurred: UsersFu,

        onset_time: resDate3,

        symptoms: this.Userzheng,
        // zhiqiandui

        hernia: this.UserShan,

        hernia_type: shanfen,

        pressure: fuyayuan,

        smoking: this.xiyannian,

        drinking: this.yinjiunian,

        other: this.qitanian,

        menstrual_history: this.UserYue,

        childbirth: this.UserHun,

        body_temperature: this.UserTi,

        blood_pressure: this.UserXue,

        pulse: this.UserMai,

        height: this.UserShen,

        weight: this.UserTizhong,

        bmi_value: this.UserBmi,

        comorbidity: heji,

        // 圖

        electrocardiogram: this.qianxinUrl,

        radiograph: this.qianxinUrl2,

        ultrasound: this.qianxinUrl3,

        ct: this.qianxinUrl4,

        blood_routine: this.Userxue,
        fecal_routine: this.UserFen,
        urine_routine: this.UserNiao,

        blood_sugar: this.UserXuetang,

        liver_function: this.UserGan,
        // 之前对

        kidney_function: this.UserShen2,

        electrolyte: this.UserDianjie,

        hemagglutination: this.UserNing,

        other_results: this.Userqita,

        b_preparation: this.changzhun,

        catheterization: this.daoniaozhun,

        antibiotics: this.kangyu,

        anesthesia: mazuifang,

        surgical_approach: arrshoushufang, //注意

        implant_patch_name: this.zhiname,

        implanted_patch_model: this.zhixing,

        implant_patch_size: this.zhichi,
        implantation_patch_cost: this.zhifee,

        // 引流

        about_drainage: this.UserIn,

        bleeding_volume: this.UserChu,

        operation_time: resDate4,

        surgeon_information: this.newsList2,

        postoperative_information: this.shuhouTime,
        surgery_time: this.newsList,

        urination_time: resDate5,

        defecation_time: resDate6,

        defecation_time_after: resDate7,

        antibacterial_time: resDate8,

        catheter_removal_time: resDate9,

        drainage_tube_removal_time: resDate10,

        postoperative_complications: this.shuhoubing,
        suture_removal_time: resDate11,

        wound_healing: arrshang, //注意

        follow_up_interval: editablesuifang,

        fever: editablesuifa,

        pain_score_vas: this.tengping,

        wound_healing2: dengyuhe,

        complications_2: bin,

        recurrence: isHave,

        // 心电图2
        ecg_2: this.qianxinUrl55,
        x_ray_2: this.qianxinUrl66,
        super_2: this.qianxinUrl77,
        ct2: this.qianxinUrl88,

        blood_routine_2: this.shuhouxue,

        urine_routine1: this.shuhouniao2,

        fecalroutin_2: this.shuhoufen,

        blood_sugar_2: this.shuhoutang,

        liver_function_2: this.shuhougan,

        kidney_function_2: this.shushen,

        electrolyte_2: this.shuhoudian,

        hemagglutination_2: this.shuhouning,

        is_follow_up: isShifang,
      };

      let list = [];
      Object.keys(listitem).forEach((item) => {
        if (listitem[item] == "" || listitem[item] == undefined) {
          delete listitem[item];
        }
      });
      list.push(listitem);

      let baseUrl = "https://api.dahuayoushanyun.com";
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$store.dispatch("setUid");
      let uid = this.$store.getters.getUid;
      list[0].id = Number(uid);
      this.$axios({
        method: "POST",
        url: `${baseUrl}/Api/Applets/save?user_access_token=${token}&type=2`,
        data: {
          list: list,
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.$router.push("/DocHou");
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.three22-colim {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(182);
  left: rem(90);
  display: flex;
  flex-direction: column;
}
.six11-hang{
  font-size rem(20)
}
</style>
