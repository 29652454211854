<template>
  <div class="CheckUser">
    <button class="zhuyuan" @click="zhu">住院病历信息</button>
    <button class="suifang" @click="sui">随访病历信息</button>
    <div class="check-lun">
      <button
        v-if="isShow == 1"
        style="position: absolute; z-index: 100000"
        @click="youchang"
        class="left"
      >
        <span> &lt;</span>
      </button>
      <button
        v-if="isShow == 2"
        style="position: absolute; z-index: 100000"
        @click="youchangtwo"
        class="left"
      >
        <span> &lt;</span>
      </button>
      <button
        v-if="isShow == 1"
        style="position: absolute; z-index: 100000"
        @click="change"
        class="you"
      >
        <span> &gt; </span>
      </button>
      <button
        v-if="isShow == 2"
        style="position: absolute; z-index: 100000"
        @click="changetwo"
        class="you"
      >
        <span> &gt; </span>
      </button>
      <div
        :class="[isShow == 1 ? 'check-box' : 'disnone']"
        :style="{ marginLeft: marginLeft + 'rem' }"
      >
        <div class="firstq-Show" v-if="isShow == 1">
         <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="firstq-deils">
            <div class="firstq-hang">
              <p>
                <span>姓名</span>
                <input type="text" v-model="name" disabled="true" name="" />
              </p>
              <p>
                <span>年龄</span>
                <input type="text" v-model="userYear" disabled="true" name="" />
              </p>
              <p>
                <span>性别</span>
                <input
                  v-model="valuesex"
                  class="userSex"
                  list="options14"
                  disabled="true"
                />
              </p>
              <p>
                <span>籍贯</span>
                <input type="text" disabled="true" v-model="UserJi" name="" />
              </p>
            </div>
            <div class="firstq-hang">
              <p>
                <span>职业</span>
                <input type="text" disabled="true" v-model="userjob" name="" />
              </p>
              <p>
                <span>国籍</span>
                <input type="text" disabled="true" v-model="UserGuo" name="" />
              </p>
              <p>
                <span>民族</span>
                <input type="text" disabled="true" v-model="UserMin" />
              </p>
            </div>
            <div class="thidq-hang">
              <p>
                <span>身份证号</span>
                <input type="text" disabled="true" v-model="UserID" name="" />
              </p>
              <p>
                <span>电话号码</span>
                <input type="text" disabled="true" v-model="UserDian" name="" />
              </p>
            </div>
            <div class="forthq-hang">
              <p>
                <span>手机号码</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="UserPhone"
                  name=""
                />
              </p>
            </div>
            <div class="fifq-hang">
              <p>
                <span>所在医院</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="addressHos"
                  name=""
                  
                />
              </p>
              <p>
                <span>住院号</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="zhuyuanhao"
                  name=""
                />
              </p>
            </div>
            <div class="sixq-hang">
              <p>
                <span>科室</span>
                <input type="text" disabled="true" v-model="UserKe" name="" i />
              </p>
              <p>
                <span class="chutiem">入院日期</span>
                <input type="text" disabled="true" v-model="value1" />
              </p>
            </div>
            <div class="sevenq-hang">
              <p>
                <span>住院天数</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="zhuyuantian"
                  name=""
                /><span>天</span>
              </p>
              <p>
                <span class="chutime">出院日期:</span>
                <input type="text" disabled="true" v-model="value24" />
              </p>
            </div>
          </div>
        </div>
        <div class="Secondq2-Show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="twoq2-deils">
            <div class="twofirstq2-hang">
              <p>
                <span>住院费用</span>
                <input type="text" disabled="true" v-model="UserFee" name="" />元
              </p>
              <p>
                <span>药费</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="UserMedicne"
                  name=""
                />元
              </p>
            </div>
            <div class="twofirstq2-hang">
              <p>
                <span>医疗耗材费</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="yiliaofee"
                  name=""
                />元
              </p>
            </div>
            <div class="thid2q2-hang">
              <p>
                <span>诊断</span>
                <input type="text" disabled="true" v-model="UserZhen" name="" />
              </p>
              <p>
                <span>部位</span>
                <input type="text" disabled="true" v-model="buwei" name="" />
              </p>
            </div>
            <div class="forth2q2-hang">
              <p>
                <span>是否复发</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="valueisFu"
                  name=""
                />
              </p>
              <p>
                <span class="fatime2">发病时间</span>
                <input type="text" disabled="true" v-model="value2" /><span>天</span>
              </p>
            </div>
            <div class="fif2q2-hang">
              <p>
                <span>肿块大小</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="zhongkuai"
                />
              </p>
              <p>
                <span>质地</span>
                <input type="text" disabled="true" v-model="zhidi" name="" />
              </p>
              <p>
                <span>是否进入阴囊</span>
                <input type="text" disabled="true" v-model="yinglang" name="" />
              </p>
              <p>
                <span>能否回纳</span>
                <input type="text" disabled="true" v-model=" huina " name="" />
              </p>
              <p>
                <span>是否疼痛</span>
                <input type="text" disabled="true" v-model="tengtojng" name="" />
              </p>
              <p>
                <span>透光试验</span>
                <input
                  type="text"
                  disabled="true"
                  v-model=" touguang "
                  name=""
                />
              </p>
            </div>
          </div>
        </div>
        <div class="third3-Show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="third3-deils">
            <div class="six2q2-hang">
              <p>
                <span>疝分类</span>
                <input type="text" disabled="true" v-model="UserShan" name="" />
              </p>
              <p>
                <span>疝分型</span>
                <input
                  list="options2"
                  id="shanfen"
                  v-model="shanxing"
                  disabled="true"
                />
              </p>
            </div>
            <div class="seven2q2-hang">
              <p>
                <span>腹压增高因素</span>
                <input
                  list="options3"
                  id="fuyayuan"
                  v-model="fuya"
                  disabled="true"
                />
              </p>
              <p>
                <span>吸烟</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="xiyannian"
                  name=""
                />年
              </p>
            </div>
            <div class="eight2q2-hang">
              <p>
                <span>饮酒</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="yinjiunian"
                  name=""
                />年
              </p>
              <p>
                <span>其他</span>
                <input type="text" disabled="true" v-model="qitanian" name="" />
              </p>
            </div>
            <div class="thirdfirst3-hang">
              <p>
                <span>女性月经史</span>
                <input type="text" disabled="true" v-model="UserYue" name="" />
              </p>
              <p>
                <span>婚育史</span>
                <input type="text" disabled="true" v-model="UserHun" name="" />
              </p>
            </div>
            <div class="thirdfirst3-hang">
              <p>
                <span>体温</span>
                <input type="text" disabled="true" v-model="UserTi" name="" /> ℃
              </p>
              <p>
                <span>血压</span>
                <input type="text" disabled="true" v-model="UserXue" name="" />mmHg
              </p>
            </div>
            <div class="thid3-hang">
              <p>
                <span>脉搏</span>
                <input type="text" disabled="true" v-model="UserMai" name="" /> 次/分
              </p>
              <p>
                <span class="hei">身高</span>
                <input type="text" disabled="true" v-model="UserShen" name="" />cm
              </p>
            </div>
            <div class="forth23-hang">
              <p>
                <span>体重</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="UserTizhong"
                  name=""
                />kg
              </p>
            </div>
            <div class="fif23-hang">
              <p>
                <span>BMI指数</span>
                <input type="text" disabled="true" v-model="UserBmi" name="" />
              </p>
              <p>
                <span>合并疾病</span>
                <input
                  list="options4"
                  id="heji"
                  v-model="heji"
                  disabled="true"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="three3-show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="three-colim">
            <div class="xindian3">
              <span class="user-xin">心电图</span>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimgzhan">
                  <div
                    class="xin1box"
                    v-for="(item, index) in qianxinUrl"
                    :key="index"
                  >
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="xindian3">
              <span class="user-xin">胸片</span>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimgzhan">
                  <div
                    class="xin1box"
                    v-for="(item, index) in qianxinUrl2"
                    :key="index"
                  >
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div></div>
          </div>
        </div>
        <div class="forth4-Show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="forth4-deils">
            <div class="xindian3">
              <span class="user-xin">B超</span>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimgzhan">
                  <div
                    class="xin1box"
                    v-for="(item, index) in qianxinUrl3"
                    :key="index"
                  >
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="forthfirstone4-hang">
              <span class="user2-xin">CT</span>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimgzhan">
                  <div
                    class="xin1box"
                    v-for="(item, index) in qianxinUrl4"
                    :key="index"
                  >
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>
        <div class="fif5-Show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="fif5-deils">
            <div class="forthfirst4-hang">
              <p>
                <span>血常规</span>
                <input type="text" name="" disabled="true" v-model="Userxue" />
              </p>
              <p>
                <span>尿常规</span>
                <input type="text" v-model="UserNiao" disabled="true" name="" />
              </p>
            </div>
            <div class="thid4-hang">
              <p>
                <span>粪常规</span>
                <input type="text" v-model="UserFen" disabled="true" name="" />
              </p>
              <p>
                <span>血糖</span>
                <input
                  type="text"
                  v-model="UserXuetang"
                  disabled="true"
                  name=""
                />
              </p>
            </div>
            <div class="forth24-hang">
              <p>
                <span>肝功能</span>
                <input type="text" v-model="UserGan" disabled="true" name="" />
              </p>
              <p>
                <span>肾功能</span>
                <input
                  type="text"
                  v-model="UserShen2"
                  disabled="true"
                  name=""
                />
              </p>
            </div>
            <div class="fif24-hang">
              <p>
                <span>电解质</span>
                <input
                  type="text"
                  v-model="UserDianjie"
                  disabled="true"
                  name=""
                />
              </p>
              <p>
                <span>血凝</span>
                <input type="text" v-model="UserNing" disabled="true" name="" />
              </p>
            </div>

            <div class="seven24-hang">
              <p>
                <span>其他</span>
                <input type="text" v-model="Userqita" disabled="true" name="" />
              </p>
            </div>
            <div class="fif5-hang">
              <p>
                <span>肠道准备</span>
                <input
                  type="text"
                  v-model="changzhun"
                  disabled="true"
                  name=""
                />
              </p>
              <p>
                <span>导尿</span>
                <input
                  type="text"
                  v-model="daoniaozhun"
                  disabled="true"
                  name=""
                />
              </p>
            </div>
            <div class="fif5-hang">
              <p>
                <span>预防性抗菌素</span>
                <input type="text" v-model="kangyu" disabled="true" name="" />
              </p>
            </div>
          </div>
        </div>
        <div class="fifss-show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="fifssbox">
            <div class="thid5-hang">
              <p>
                <span class="ma">麻醉方式</span>
                <input
                  list="options5"
                  id="mazuifang"
                  v-model="mazui"
                  disabled="true"
                />
              </p>
              <p>
                <span class="shou-fang">手术方式</span>
                <input
                  list="options6"
                  id="shoushufang"
                  v-model="arrshoushufang.shoushufang"
                  disabled="true"
                />

                <input
                  list="optionsdeil"
                  v-model="arrshoushufang.editabledeil"
                  id="editable-deil"
                  disabled="true"
                  style="position:relative;left:0.31rem"
                />
              </p>
            </div>
            <div class="fif5232-hang">
              <p>
                <span >补片名称</span>
                <input type="text"  v-model="zhiname" />
              </p>
               <p>
                <span >补片型号</span>
                <input type="text" v-model="zhixing" />
              </p>
            </div>
            <div class="fif5232-hang">
               <p>
                <span >补片尺寸</span>
                <input type="text" v-model="zhichi" /><span>cm</span>
              </p>
               <p>
                <span >补片费用</span>
                <input type="text"  v-model="zhifee" /><span>元</span>
              </p>
            </div>
            <div class="fif25-hang">
              <p>
                <span>有无引流</span>
                <input type="text" disabled="true" v-model="UserIn" name="" />
              </p>
              <p>
                <span>出血量</span>
                <input type="text" disabled="true" v-model="UserChu" name="" /><span>ml</span>
              </p>
            </div>
            <div class="six25-hang">
              <p>
                <span class="shoutime">手术时间:</span>
                <input v-model="xiaos" disabled="true" type="text" /><span>小时</span>
              </p>
               <p>
                <input v-model="fenz1" disabled="true" type="text" /><span class="fen">分钟</span>
              </p>
            </div>
          </div>
        </div>
        <div class="zengshow" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="zeng-deil">
            <div class="doc-box">
              <div class="shdoc">
                <!-- <span class="docspan ">手术医师信息</span> -->
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div id="table2">
                    <table cellpadding="0" cellspacing="0" class="tableheader2">
                      <thead>
                        <tr>
                          <th>主刀医生姓名</th>
                          <th>主刀职位</th>
                          <th>一助医生姓名</th>
                          <th>一助职位</th>
                          <th>二助医生姓名</th>
                          <th>二助医生职位</th>
                        </tr>
                      </thead>
                      <tbody class="tbodys2">
                        <tr v-for="(item, index) in newsList2" :key="index">
                          <td>{{ item.tiwen }}</td>
                          <td>{{ item.zhuzhi }}</td>
                          <td>{{ item.xueya }}</td>
                          <td>{{ item.yizhi }}</td>
                          <td>{{ item.maibo }}</td>
                          <td>{{ item.erzhi }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </happy-scroll>
              </div>
            </div>

            <div class="shuhou">
              <div class="shouhouti">
                <!-- <span class="shuhouspan">术后第一天生命体征</span> -->
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div id="table">
                    <table cellpadding="0" cellspacing="0" class="tableheader">
                      <thead>
                        <tr>
                          <th>序号</th>
                          <th>体温(℃)</th>
                          <th>血压(mmHg)</th>
                          <th>脉搏(次/分)</th>
                          <th>尿量(ml)</th>
                          <th>引流量(ml)</th>
                          <th>疼痛评分(1-10分)</th>
                        </tr>
                      </thead>
                      <tbody class="tbodys">
                        <tr v-for="(item, index) in newsList" :key="index">
                          <td>术后第{{ index + 1 }}天生命体征:</td>
                          <td>{{ item.tiwen }}</td>
                          <td>{{ item.xueya }}</td>
                          <td>{{ item.maibo }}</td>
                          <td>{{ item.niao }}</td>
                          <td>{{ item.yinliu }}</td>
                          <td>{{ item.tengtong }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </happy-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="Second6-Show" v-if="isShow == 1">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="two6-deils">
            <div class="twofirst6-hang">
              <p>
                <span>术后下床时间</span>
                <input type="text" disabled="true" v-model="value4" /><span class="tian">天</span>
              </p>
              <p>
                <span>术后自行解尿时间</span>
                <input type="text" disabled="true" v-model="value5" /><span class="tian">天</span>
              </p>
            </div>
            <div class="twofirst6-hang">
              <p>
                <span>术后排气排便时间</span>
                <input type="text" disabled="true" v-model="value6" /><span class="tian">天</span>
              </p>
            </div>
            <div class="thid6-hang">
              <p>
                <span>抗菌素使用时间</span>
                <input type="text" disabled="true" v-model="value7" /><span class="tian">天</span>
              </p>
            </div>
            <div class="forth26-hang">
              <p>
                <span>导尿管拔除时间:</span>
                <input type="text" disabled="true" v-model="value8" /><span class="tian">天</span>
              </p>
              <p>
                <span>引流管拔除时间:</span>
                <input type="text" disabled="true" v-model="value9" /><span class="tian">天</span>
              </p>
            </div>
            <div class="fif26-hang">
              <p>
                <span>术后并发症</span>
                <input
                  type="text"
                  v-model="shuhoubing"
                  disabled="true"
                  name=""
                />
              </p>
              <p>
                <span>伤口拆线时间:</span>
                <input type="text" disabled="true" v-model="value10" /><span>天</span>
              </p>
            </div>
            <div class="six26-hang">
              <p>
                <span>伤口愈合分类</span>
                <input
                  list="options7"
                  id="shangyuhge"
                  v-model="shangfen"
                  disabled="true"
                />
              </p>
              <p>
                <span>伤口愈合等级</span>
                <input
                  list="options8"
                  id="editable-selectyuhe"
                  v-model="shangdeng"
                  disabled="true"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="check-boxtwo"
        :class="[isShow == 2 ? 'check-boxtwo' : 'disnone']"
        :style="{ marginLeft: marginLefttwo + 'rem' }"
      >
        <div class="firstq-Show" v-if="isShow == 2"> <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">随访病历查询</span>
          <div class="firstq-deils">
            <div class="firstq-hang">
              <p>
                <span>姓名</span>
                <input type="text" v-model="name" disabled="true" name="" />
              </p>
              <p>
                <span>年龄</span>
                <input type="text" v-model="userYear" disabled="true" name="" />
              </p>
              <p>
                <span>性别</span>
                <input
                  v-model="valuesex"
                  class="userSex"
                  list="options14"
                  disabled="true"
                />
              </p>
              <p>
                <span>籍贯</span>
                <input type="text" disabled="true" v-model="UserJi" name="" />
              </p>
            </div>
            <div class="firstq-hang">
              <p>
                <span>职业</span>
                <input type="text" disabled="true" v-model="userjob" name="" />
              </p>
              <p>
                <span>国籍</span>
                <input type="text" disabled="true" v-model="UserGuo" name="" />
              </p>
              <p>
                <span>民族</span>
                <input type="text" disabled="true" v-model="UserMin" />
              </p>
            </div>
            <div class="thidq-hang">
              <p>
                <span>身份证号</span>
                <input type="text" disabled="true" v-model="UserID" name="" />
              </p>
              <p>
                <span>电话号码</span>
                <input type="text" disabled="true" v-model="UserDian" name="" />
              </p>
            </div>
            <div class="forthq-hang">
              <p>
                <span>手机号码</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="UserPhone"
                  name=""
                />
              </p>
               <p>
                <span>所在医院</span>
                <input
                  type="text"
                  v-model="addressHos"
                  disabled="true"
                />
          
              </p>
            </div>
              <div class="six11-hang">
              <p>
                <span>科室</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="UserKe"
                />
              </p>
              <div class="block1ss">
                <span class="chutiem">手术日期</span>
                <input type="text" disabled="true" v-model="ssrqi">
              </div>
            </div>
             <div class="seven11-hang">
              <p>
                <span>住院天数</span>
                <input type="text" v-model="zhuyuantian" name="" /><span>天</span>
              </p>

              <div class="block4">
                <span class="chutime">复诊日期:</span>
                <input type="text" :disabled="true" v-model="fzrq">
              </div>
            </div>
            <div class="fif11-hang">
              <p>
                <span>随访时间间隔</span>

                <input
                  list="suifang"
                  id="editablesuifang"
                  v-model="suifang"
                  disabled="true"
                />
              </p>
              <p>
                <span>有无发热</span>
                <input
                  list="suifa"
                  id="editablesuifa"
                  v-model="fare"
                  disabled="true"
                />
              </p>
            </div>
            <div class="six11-hang">
              <p>
                <span>疼痛评分VAS</span>
                <input type="text" disabled="true" v-model="tengping" name="" />
              </p>
            </div>
          </div>
        </div>
        <div class="Second7-Show" v-if="isShow == 2">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">随访病历查询</span>
          <div class="two7-deils">
            <div class="twofirst7-hang">
              <p>
                <span>伤口愈合分类</span>
                <input
                  list="optionsyu"
                  id="editableyu"
                  v-model="shangfen2"
                  disabled="true"
                />
              </p>
              <p>
                    <span>伤口愈合等级</span>
                <input
                  list="optionsdfeng"
                  id="dengyuhe"
                  v-model="shangdeng2"
                  disabled="true"
                />
              </p>
            </div>
            <div class="twofirst7-hang">
              <p>
                <span>有无并发症</span>
                <input
                  list="options9"
                  id="bin"
                  v-model="binfa"
                  disabled="true"
                />
              </p>
              <p>
                <span>有无复发</span>
                <input type="text"   disabled="true" v-model="isHave" />
              </p>
            </div>
            <div class="thid7-hang">
              <div class="xindian31">
                <span class="user-xin">心电图</span>
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div class="xinimgzhan">
                    <div
                      class="xin1box"
                      v-for="(item, index) in qianxinUrl55"
                      :key="index"
                    >
                      <img
                        class="xinforimg"
                        :src="baseUrl + item"
                        alt=""
                        v-viewer
                      />
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>
            <div class="forth27-hang">
              <div class="xindian31">
                <span class="user-xin">胸片</span>
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div class="xinimgzhan">
                    <div
                      class="xin1box"
                      v-for="(item, index) in qianxinUrl66"
                      :key="index"
                    >
                      <img
                        class="xinforimg"
                        :src="baseUrl + item"
                        alt=""
                        v-viewer
                      />
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="three32-show" v-if="isShow == 2">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">随访病历查询</span>
          <div class="three2-colim">
            <div class="xindian32">
              <span class="user-xin">B超</span>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimgzhan">
                  <div
                    class="xin1box"
                    v-for="(item, index) in qianxinUrl77"
                    :key="index"
                  >
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div>
              <div class="xindian32">
                <span class="user-xin">CT</span>
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div class="xinimgzhan">
                    <div
                      class="xin1box"
                      v-for="(item, index) in qianxinUrl88"
                      :key="index"
                    >
                      <img
                        class="xinforimg"
                        :src="baseUrl + item"
                        alt=""
                        v-viewer
                      />
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="Second9-Show" v-if="isShow == 2">
           <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">随访病历查询</span>
          <div class="two9-deils">
            <div class="twofirst9-hang">
              <p>
                <span>血常规</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhouxue"
                  name=""
                />
              </p>
              <p>
                <span>尿常规</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhouniao2"
                  name=""
                />
              </p>
            </div>
            <div class="twofirst9-hang">
              <p>
                <span>粪常规</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhoufen"
                  name=""
                />
              </p>
              <p>
                <span>血糖</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhoutang"
                  name=""
                />
              </p>
            </div>
            <div class="thid9-hang">
              <p>
                <span>肝功能</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhougan"
                  name=""
                />
              </p>
              <p>
                <span>肾功能</span>
                <input type="text" disabled="true" v-model="shushen" name="" />
              </p>
            </div>
            <div class="forth29-hang">
              <p>
                <span>电解质</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhoudian"
                  name=""
                />
              </p>
              <p>
                <span>血凝</span>
                <input
                  type="text"
                  disabled="true"
                  v-model="shuhouning"
                  name=""
                />
              </p>
            </div>
            <div class="fif29-hang">
              <p>
                <span>是否失访</span>
                <input
                  list="options10"
                  id="isShifang"
                  v-model="isFang"
                  disabled="true"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="ulbox" v-if="isShow == 1">
        <p
          :class="[index == 0 ? 'liitem2' : 'liitem']"
          @click="changeIndex(0)"
        ></p>
        <p
          :class="[index == 1 ? 'liitem2' : 'liitem']"
          @click="changeIndex(1)"
        ></p>
        <p
          :class="[index == 2 ? 'liitem2' : 'liitem']"
          @click="changeIndex(2)"
        ></p>
        <p
          :class="[index == 3 ? 'liitem2' : 'liitem']"
          @click="changeIndex(3)"
        ></p>
        <p
          :class="[index == 4 ? 'liitem2' : 'liitem']"
          @click="changeIndex(4)"
        ></p>
        <p
          :class="[index == 5 ? 'liitem2' : 'liitem']"
          @click="changeIndex(5)"
        ></p>
        <p
          :class="[index == 6 ? 'liitem2' : 'liitem']"
          @click="changeIndex(6)"
        ></p>
        <p
          :class="[index == 7 ? 'liitem2' : 'liitem']"
          @click="changeIndex(7)"
        ></p>
        <p
          :class="[index == 8 ? 'liitem2' : 'liitem']"
          @click="changeIndex(8)"
        ></p>
      </div>
      <div class="ulbox" v-if="isShow == 2">
        <p
          :class="[indextwo == 0 ? 'liitem2' : 'liitem']"
          @click="changeIndextwo(0)"
        ></p>
        <p
          :class="[indextwo == 1 ? 'liitem2' : 'liitem']"
          @click="changeIndextwo(1)"
        ></p>
        <p
          :class="[indextwo == 2 ? 'liitem2' : 'liitem']"
          @click="changeIndextwo(2)"
        ></p>
        <p
          :class="[indextwo == 3 ? 'liitem2' : 'liitem']"
          @click="changeIndextwo(3)"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyAppFirst",

  data() {
    return {
      marginLeft: 0,
      isShow: 1,
      xiaos:"",
      fenz1:"",
      marginLefttwo: 0,
      index: 0,
      indextwo: 0,
      baseUrl: "https://api.dahuayoushanyun.com",
      value24: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      addDetail: {},
      editlist: false,
      editDetail: {},
      newsList: [],
      editid: "",
      addDetail2: {},
      editlist2: false,
      editDetail2: {},
      newsList2: [],
      editid2: "",
      value1: "",
      value2: "",
      name: "",
      userYear: "",
      userjob: "",
      UserGuo: "",
      UserMin: "",
      UserJi: "",
      UserID: "",
      UserDian: "",
      UserPhone: "",
      addressHos: "",
      zhuyuanhao: "",
      UserKe: "",
      zhuyuantian: "",
      UserFee: "",
      UserMedicne: "",
      yiliaofee: "",
      UserZhen: "",
      Userzheng: "",
      UserShan: "",
      xiyannian: "",
      yinjiunian: "",
      qitanian: "",
      UserYue: "",
      UserHun: "",
      ssrqi:"",
      fzrq:"",
      UserTi: "",
      UserXue: "",
      UserMai: "",
      UserShen: "",
      UserTizhong: "",
      UserBmi: "",
      Userxue: "",
      UserNiao: "",
      UserFen: "",
      UserXuetang: "",
      UserShou1: "",
      UserGan: "",
      UserShen2: "",
      UserDianjie: "",
      UserNing: "",
      Userqita: "",
      UserIn: "",
      UserChu: "",
      zhiname: "",
      zhixing: "",
      zhichi: "",
      zhifee: "",
      zhudao: "",
      shuhouTime: "",
      niaoTime: "",
      shuhouqi: "",
      kangjuntime: "",
      daoniaotime: "",
      yinliubaTime: "",
      shuhoubing: "",
      shangchai: "",
      tengping: "",
      shuhouxue: "",
      shuhouniao2: "",
      shuhoufen: "",
      shuhoutang: "",
      shuhougan: "",
      shushen: "",
      shuhoudian: "",
      shuhouning: "",
      limitNum11: 10,
      limitNum22: 10,
      limitNum33: 10,
      limitNum44: 10,
      limitNum55: 10,
      limitNum66: 10,
      limitNum77: 10,
      limitNum88: 10,
      valueisFu: "",
      valuesex: "",
      arrshoushufang: {},
      changzhun: "",
      daoniaozhun: "",
      kangyu: "",
      isHave: "",
      form: {
        file: "",
        type: 1,
      },
      qianxinUrl: [],
      form2: {
        file2: "",
        type2: 1,
      },
      qianxinUrl2: [],
      form3: {
        file3: "",
        type3: 1,
      },
      qianxinUrl3: [],
      form4: {
        file4: "",
        type4: 1,
      },
      qianxinUrl4: [],

      qianxinUrl55: [],
      form55: {
        file55: "",
        type55: 1,
      },
      qianxinUrl66: [],
      form66: {
        file66: "",
        type66: 1,
      },
      qianxinUrl77: [],
      form77: {
        file77: "",
        type77: 1,
      },
      qianxinUrl88: [],
      form88: {
        file88: "",
        type88: 1,
      },
      buwei: "",
      shanxing: "",
      fuya: "",
      heji: "",
      mazui: "",
      shoushu: "",
      suifang: "",
      fare: "",
      binfa: "",
      isFang: "",
      shangdeng: "",
      shangfen: "",
      shangdeng2: "",
      shangfen2: "",
      zhongkuai: "",
      zhidi: "",
      yinglang: "",
      touguang: "",
      huina: "",
      tengtojng: "",
    };
  },
  components: {},

  mounted() {
    this.getUidData(1);
  },

  methods: {
    zhu() {
      this.isShow = 1;
      this.index = 0;
      this.marginLeft = 0;
      this.getUidData(1);
    },
    sui() {
      this.isShow = 2;
      this.indextwo = 0;
      this.marginLefttwo = 0;
      this.getUidData(2);
    },
    changetwo() {
      this.indextwo++;
      if (this.indextwo > 3) {
        this.indextwo = 3;
      } else {
        this.marginLefttwo = 6.25 * -this.indextwo;
      }

      if (this.marginLefttwo < -62.5) {
        this.marginLefttwo = -62.5;
      }
    },
    change() {
      this.index++;
      if (this.index > 8) {
        this.index = 8;
      } else {
        this.marginLeft = 6.25 * -this.index;
      }

      if (this.marginLeft < -62.5) {
        this.marginLeft = -62.5;
      }
    },
    youchang() {
      this.index--;
      if (this.index < 0) {
        this.index = 0;
      } else {
        this.marginLeft += 6.25;
      }
    },
    youchangtwo() {
      this.indextwo--;
      if (this.indextwo < 0) {
        this.indextwo = 0;
      } else {
        this.marginLefttwo += 6.25;
      }
    },
    changeIndex(index) {
      this.index = index;
      this.marginLeft = -this.index * 6.25;
    },
    changeIndextwo(index) {
      this.indextwo = index;
      this.marginLefttwo = -this.indextwo * 6.25;
    },
    getUidData(type) {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/yOne?user_access_token=${token}&type=${type}`,

        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        let resData = res.data.data;
        if (resData.name) {
          this.name = resData.name;
        }

        if (resData.age) {
          this.userYear = resData.age;
        }

        if (resData.sex) {
          this.valuesex = resData.sex;
        }

        if (resData.profession) {
          this.userjob = resData.profession;
        }

        if (resData.country) {
          this.UserGuo = resData.country;
        }

        if (resData.nationality) {
          this.UserMin = resData.nationality;
        }

        if (resData.hometown) {
          this.UserJi = resData.hometown;
        }

        if (resData.card) {
          this.UserID = resData.card;
        }

        if (resData.phone) {
          this.UserDian = resData.tel;
        }
           if (resData.zhongkuai) {
          this.zhongkuai = resData.zhongkuai;
        }
        if (resData.zhidi) {
          this.zhidi = resData.zhidi;
        }
        if (resData.yinglang) {
          this.yinglang = resData.yinglang;
        }
        if (resData.touguang) {
          this.touguang = resData.touguang;
        }
        if (resData.huina) {
          this.huina = resData.huina;
        }
        if (resData.tengtojng) {
          this.tengtojng = resData.tengtojng;
        }

        if (resData.tel) {
          this.UserPhone = resData.phone;
        }

        if (resData.hospital) {
          this.addressHos = resData.hospital;
        }

        if (resData.number) {
          this.zhuyuanhao = resData.number;
        }

        if (resData.department) {
          this.UserKe = resData.department;
        }

        if (resData.d_date) {
          this.value1 = resData.d_date;
        }

        if (resData.a_date) {
          this.value24 = resData.a_date;
        }
           if (resData.fzrq) {
          this.fzrq = resData.fzrq;
        }
        
        if (resData.ssrqi) {
          this.ssrqi = resData.ssrqi;
        }



        if (resData.discharge) {
          this.zhuyuantian = resData.discharge;
        }

        if (resData.hospital_costs) {
          this.UserFee = resData.hospital_costs;
        }

        if (resData.medicine_fee) {
          this.UserMedicne = resData.medicine_fee;
        }

        if (resData.m_consumables) {
          this.yiliaofee = resData.m_consumables;
        }

        if (resData.diagnosis) {
          this.UserZhen = resData.diagnosis;
        }

        if (resData.location) {
          this.buwei = resData.location;
        }
        if (resData.it_recurred) {
          this.valueisFu = resData.it_recurred;
        }

        if (resData.onset_time) {
          this.value2 = resData.onset_time;
        }
        if (resData.symptoms) {
          this.Userzheng = resData.symptoms;
        }

        if (resData.hernia) {
          this.UserShan = resData.hernia;
        }

        if (resData.hernia_type) {
          this.shanxing = resData.hernia_type;
        }

        if (resData.pressure) {
          this.fuya = resData.pressure;
        }

        if (resData.smoking) {
          this.xiyannian = resData.smoking;
        }

        if (resData.drinking) {
          this.yinjiunian = resData.drinking;
        }

        if (resData.other) {
          this.qitanian = resData.other;
        }

        if (resData.menstrual_history) {
          this.UserYue = resData.menstrual_history;
        }

        if (resData.childbirth) {
          this.UserHun = resData.childbirth;
        }

        if (resData.body_temperature) {
          this.UserTi = resData.body_temperature;
        }

        if (resData.blood_pressure) {
          this.UserXue = resData.blood_pressure;
        }

        if (resData.pulse) {
          this.UserMai = resData.pulse;
        }

        if (resData.height) {
          this.UserShen = resData.height;
        }

        if (resData.weight) {
          this.UserTizhong = resData.weight;
        }

        if (resData.bmi_value) {
          this.UserBmi = resData.bmi_value;
        }

        if (resData.comorbidity) {
          this.heji = resData.comorbidity;
        }

        if (resData.blood_routine) {
          this.Userxue = resData.blood_routine;
        }

        if (resData.fecal_routine) {
          this.UserFen = resData.fecal_routine;
        }

        if (resData.urine_routine) {
          this.UserNiao = resData.urine_routine;
        }

        if (resData.blood_sugar) {
          this.UserXuetang = resData.blood_sugar;
        }

        if (resData.liver_function) {
          this.UserGan = resData.liver_function;
        }

        if (resData.kidney_function) {
          this.UserShen2 = resData.kidney_function;
        }

        if (resData.electrolyte) {
          this.UserDianjie = resData.electrolyte;
        }

        if (resData.hemagglutination) {
          this.UserNing = resData.hemagglutination;
        }

        if (resData.other_results) {
          this.Userqita = resData.other_results;
        }

        if (resData.b_preparation) {
          this.changzhun = resData.b_preparation;
        }

        if (resData.catheterization) {
          this.daoniaozhun = resData.catheterization;
        }

        if (resData.antibiotics) {
          this.kangyu = resData.antibiotics;
        }

        if (resData.anesthesia) {
          this.mazui = resData.anesthesia;
        }

        if (resData.surgical_approach) {
          this.arrshoushufang = resData.surgical_approach;
        }

        if (resData.implant_patch_name) {
          this.zhiname = resData.implant_patch_name;
        }

        if (resData.implanted_patch_model) {
          this.zhixing = resData.implanted_patch_model;
        }

        if (resData.implant_patch_size) {
          this.zhichi = resData.implant_patch_size;
        }
        if (resData.implantation_patch_cost) {
          this.zhifee = resData.implantation_patch_cost;
        }

        if (resData.about_drainage) {
          this.UserIn = resData.about_drainage;
        }

        if (resData.bleeding_volume) {
          this.UserChu = resData.bleeding_volume;
        }

        if (resData.xiaos) {
          this.xiaos = resData.xiaos;
        }
      if (resData.fenz1) {
          this.fenz1 = resData.fenz1;
        }

        if (resData.surgeon_information) {
          this.newsList2 = resData.surgeon_information;
        }

        if (resData.postoperative_information) {
          this.shuhouTime = resData.postoperative_information;
        }

        if (resData.surgery_time) {
          this.newsList = resData.surgery_time;
        }

        if (resData.urination_time) {
          this.value4 = resData.urination_time;
        }

        if (resData.defecation_time) {
          this.value5 = resData.defecation_time;
        }

        if (resData.defecation_time_after) {
          this.value6 = resData.defecation_time_after;
        }

        if (resData.antibacterial_time) {
          this.value7 = resData.antibacterial_time;
        }

        if (resData.catheter_removal_time) {
          this.value8 = resData.catheter_removal_time;
        }
        if (resData.drainage_tube_removal_time) {
          this.value9 = resData.drainage_tube_removal_time;
        }
        if (resData.postoperative_complications) {
          this.shuhoubing = resData.postoperative_complications;
        }
        if (resData.suture_removal_time) {
          this.value10 = resData.suture_removal_time;
        }

        if (resData.wound_healing) {
          this.shangfen = resData.wound_healing.shangeyu;
        }
        if (resData.wound_healing) {
          this.shangdeng = resData.wound_healing.selectjia;
        }
        if (resData.follow_up_interval) {
          this.suifang = resData.follow_up_interval;
        }
        if (resData.fever) {
          this.fare = resData.fever;
        }
        if (resData.pain_score_vas) {
          this.tengping = resData.pain_score_vas;
        }
        if (resData.electrocardiogram) {
          this.qianxinUrl = resData.electrocardiogram;
        }
        if (resData.radiograph) {
          this.qianxinUrl2 = resData.radiograph;
        }

        if (resData.ultrasound) {
          this.qianxinUrl3 = resData.ultrasound;
        }

        if (resData.ct) {
          this.qianxinUrl4 = resData.ct;
        }
        if (resData.ecg_2) {
          this.qianxinUrl55 = resData.ecg_2;
        }
        if (resData.x_ray_2) {
          this.qianxinUrl66 = resData.x_ray_2;
        }
        if (resData.super_2) {
          this.qianxinUrl77 = resData.super_2;
        }
        if (resData.ct2) {
          this.qianxinUrl88 = resData.ct2;
        }

        if (resData.wound_healing2) {
          this.shangdeng2 = resData.wound_healing2.editableyuval;
        }
        if (resData.wound_healing2) {
          this.shangfen2 = resData.wound_healing2.dengyuheVal;
        }
        if (resData.complications_2) {
          this.binfa = resData.complications_2;
        }
        if (resData.recurrence) {
          this.isHave = resData.recurrence;
        }

        if (resData.blood_routine_2) {
          this.shuhouxue = resData.blood_routine_2;
        }
        if (resData.urine_routine1) {
          this.shuhouniao2 = resData.urine_routine1;
        }
        if (resData.fecalroutin_2) {
          this.shuhoufen = resData.fecalroutin_2;
        }

        if (resData.blood_sugar_2) {
          this.shuhoutang = resData.blood_sugar_2;
        }
        if (resData.liver_function_2) {
          this.shuhougan = resData.liver_function_2;
        }
        if (resData.kidney_function_2) {
          this.shushen = resData.kidney_function_2;
        }

        if (resData.electrolyte_2) {
          this.shuhoudian = resData.electrolyte_2;
        }
        if (resData.hemagglutination_2) {
          this.shuhouning = resData.hemagglutination_2;
        }
        if (resData.is_follow_up) {
          this.isFang = resData.is_follow_up;
        }
      });
    },
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.zhuyuan {
  width: rem(250);
  height: rem(60);
  line-height: rem(60);
  font-size: rem(25);
  color: #000000;
  position: absolute;
  background: #ffffff;
  border-radius: rem(5);
  border: 1px solid #ffffff;
  top: rem(20);
  cursor: pointer;
  left: 20%;
}

.suifang {
  width: rem(250);
  height: rem(60);
  line-height: rem(60);
  font-size: rem(25);
  color: #000000;
  position: absolute;
  top: rem(20);
  background: #ffffff;
  border-radius: rem(5);
  border: 1px solid #ffffff;
  left: 40%;
  cursor: pointer;
}

.CheckUser {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('./assets/bju.png') no-repeat center / cover;
}

.check-lun {
  width: rem(1200);
  height: rem(782);
  overflow: hidden;
  background: #FFFFFF;
  border-radius: rem(20);
  position: absolute;
  top: rem(100);
  left: rem(360);
}

.check-box {
  width: rem(10800) !important;
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // border: 1px solid red;
  display: flex;
  justify-content: flex-start;
}

.check-boxtwo {
  width: rem(4800);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // border: 1px solid red;
  display: flex;
  justify-content: flex-start;
}

.ulbox {
  width: rem(700);
  height: rem(40);
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  position: absolute;
  bottom: rem(20);
  left: rem(250);
}

.ulbox .liitem {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  background-color: #E3E3E3;
  margin-left: rem(20);
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  top: rem(10);
}

.ulbox .liitem2 {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  background-color: #0473C2;
  margin-left: rem(20);
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  top: rem(10);
}

.left, .you {
  width: rem(40);
  height: rem(40);
  border-radius: 50%;
  background: #0473C2;
  border: none;
  font-size: rem(22);
  color: #ffffff;
  bottom: rem(20);
  cursor: pointer;
}

.you {
  right: rem(100);
}

.left {
  left: rem(100);
}

p {
  padding: 0;
  margin: 0;
}

.firstq-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
}

.firstq-title {
  font-size: rem(36);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: rem(50);
  position: absolute;
  top: rem(40);
  color: #0473C2;
  left: rem(264);
}

.firstq-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.firstq-hang p {
  width: rem(200);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.firstq-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.firstq-hang p input {
  width: rem(140);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thidq-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thidq-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thidq-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forthq-hang p input, .fifq-hang p input, .sixq-hang p input, .sevenq-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Secondq2-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
}

.disnone {
  display: none;
}

.twoq2-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirstq2-hang, .thid2q2-hang, .eight2q2-hang, .forth2q2-hang, .six2q2-hang, .seven2q2-hang, .eight2-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  position: relative;
  justify-content: space-between;
}

.fif2q2-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  position: relative;
   justify-content: space-between;
  flex-wrap: wrap;
}

.fif2q2-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
  margin-top: rem(20);
}

.twofirstq2-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirstq2-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.fifssbox {
  width: rem(1000);
  display: flex;
  flex-direction: column;
  margin-left: rem(100);
  position relative
  top rem(150)
}

.fifss-show div {
  margin-top: rem(50);
}

.twofirstq2-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid2q2-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid2q2-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid2q2-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth2q2-hang p, .eight2q2-hang p, .six2q2-hang p, .seven2q2-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth2q2-hang p span, .fif2q2-hang p span, .eight2q2-hang p span, .six2q2-hang p span, .seven2q2-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth2q2-hang p input, .fif2q2-hang p input, .eight2q2-hang p input, .six2q2-hang p input, .seven2q2-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.third3-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
}

.thirdfirst3-hang, .thid3-hang, .forth23-hang, .fif23-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.thirdfirst3-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.thirdfirst3-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.thirdfirst3-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid3-hang p {
  width: rem(440);
  display: flex;
  justify-content: space-around;
}



.thid3-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth23-hang p, .fif23-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.user-xin {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: absolute;
  left: rem(20);
  top: rem(0);
}

.user2-xin {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(20);
}

.forth23-hang p span, .fif23-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth23-hang p input, .fif23-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.xindian3 {
  width: 100%;
  height: rem(256);
  margin-top: rem(10);
  position: relative;
}

.xindian31 {
  width: 100%;
  margin-top: rem(10);
  position: relative;
}

.xindian32 {
  width: 100%;
  height: rem(260);
  margin-top: rem(10);
  position: relative;
}

.xinimgzhan {
  width: rem(900);
  height: rem(180);
  overflow: hidden;
  position: absolute;
  top: rem(0);
  left: rem(100);
}

.xin1box {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.viewer-container {
  z-index: rem(12000) !important;
}

.xinforimg {
  width: rem(100);
  height: rem(100);
  margin-left: rem(10);
}

.forth4-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.forth4-deils {
  width: rem(1000);
  height: rem(610);
  position: absolute;
  top: rem(162);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.forthfirstone4-hang {
  width: 100%;
  height: rem(196);
}

.forthfirst4-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.forthfirst4-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid4-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid4-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid4-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth24-hang p, .fif24-hang p, .six24-hang p, .seven24-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth24-hang p span, .fif24-hang p span, .six24-hang p span, .seven24-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth24-hang p input, .fif24-hang p input, .eight4-hang p input, .six24-hang p input, .seven24-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.fif5-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.fif5-hang p span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.fif5-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid5-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid5-hang .ma {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.zengshow {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.zeng-deil {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.doc-box {
  width: 100%;
  height: rem(240);
}

.shuhou {
  width: 100%;
  height: rem(300);
  margin-top: rem(30);
  overflow: hidden;
}

.shdoc, .shuhou {
  width: 100%;
  height: rem(240);
  position: relative;
}

.shdoc .docspan {
  width: rem(120);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
  position: absolute;
  top: rem(100);
  left: rem(0);
}

.shuhou .shuhouspan {
  width: rem(180);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
  position: absolute;
  top: rem(100);
  left: rem(0);
}

#table2 {
  width: rem(1000);
  position: relative;
  top: rem(20);
}

.add2 {
  width: rem(900);
  padding: rem(10);
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.add2 input {
  margin-left: rem(10);
  width: rem(130);
  height: rem(30);
  font-size: rem(14);
  color: #000000;
  border-radius: rem(5);
  margin-top: rem(25);
  border: 1px solid #dcdfe6;
  outline: none;
}

.tbodys2 td, .tbodys td {
  font-size: rem(14);
  width: rem(200);
  height: rem(40);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.delete2 {
  color: red;
}

.edit2 {
  color: #008cd5;
}

#mask2 {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}

.mask2 {
  width: rem(500);
  height: rem(450);
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 47;
  border-radius: 5px;
}

.three3-show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.three-colim {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.xiong span, .bpic span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  top: rem(80);
}

.showpic {
  width: rem(900);
  height: 100%;
}

.title2 {
  width: 100%;
  color: #023DC6;
  font-size: rem(26);
  line-height: rem(30);
}

.content2 {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.title2 span {
  width: rem(30);
  height: rem(30);
  color: red;
  position: absolute;
  right: rem(10);
  cursor: pointer;
}

.content2 input {
  width: rem(300);
  height: rem(30);
  margin-bottom: rem(20);
  margin-left: rem(80);
}

.content button, .content2 button {
  width: rem(200);
  height: rem(60);
  line-height: rem(60);
  text-align: center;
  font-size: rem(22);
  color: #ffffff;
  background: #023DC6;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
  position: absolute;
}

.cal {
  right: rem(60);
  bottom: rem(-60);
  cursor: pointer;
}

.update {
  left: rem(10);
  bottom: rem(-60);
  cursor: pointer;
}

.cal2 {
  right: rem(60);
  bottom: rem(-60);
  cursor: pointer;
}

.update2 {
  left: rem(10);
  bottom: rem(-60);
}

.title span {
  width: rem(30);
  height: rem(30);
  color: red;
  position: absolute;
  right: rem(10);
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

#table {
  width: rem(1000);
  position: relative;
  top: rem(40);
}

.add {
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding: rem(15);
}

.add input {
  margin-left: rem(10);
  width: rem(100);
  height: rem(30);
  font-size: rem(14);
  color: #000000;
  border-radius: rem(5);
  border: 1px solid #dcdfe6;
  outline: none;
  margin-top: rem(25);
}

.add button {
  width: rem(100);
  height: rem(40);
  position: absolute;
  top: rem(0);
  background: #023DC6;
  font-size: rem(16);
  text-align: center;
  line-height: rem(40);
  // box-shadow: 0px 20px 20px #91AAE2;
  opacity: 1;
  color: #ffffff;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
}

.tbodys td {
  font-size: rem(14);
  width: rem(200);
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.delete {
  color: red;
  cursor: pointer;
}

.edit {
  color: #008cd5;
  cursor: pointer;
}

.tableheader2 input, .tableheader input {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 15px;
}

#mask {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}

.mask {
  width: rem(600);
  height: rem(500);
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 47;
  border-radius: 5px;
}

.title {
  font-size: rem(26);
}

.content input {
  width: rem(300);
  height: rem(30);
  margin-bottom: rem(20);
  margin-left: rem(80);
}

.title span {
  float: right;
  cursor: pointer;
}

.Second6-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two6-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}


.twofirst6-hang p span {
  width: rem(180);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst6-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}





.forth26-hang p span, .fif26-hang p span, .eight6-hang p span, .six26-hang p span, .seven26-hang p span {
  width: rem(180);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth26-hang p input, .fif26-hang p input, .eight6-hang p input, .six26-hang p input, .seven26-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Second7-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two7-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst7-hang, .thid7-hang, .forth27-hang, .fif27-hang, .six27-hang, .seven27-hang, .eight7-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.twofirst7-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst7-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid7-hang, .forth27-hang {
  width: 100%;
  height: rem(200);
}

.three32-show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}


.xiong2, .bpic2 {
  width: 100%;
  height: rem(200);
  margin-top: rem(50);
  display: flex;
  justify-content: space-around;
}

.xiong2 span, .bpic2 span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  top: rem(80);
}

.showpic2 {
  width: rem(900);
  height: 100%;
}

.Second9-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two9-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst9-hang, .thid9-hang, .forth29-hang, .fif29-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.twofirst9-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst9-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst9-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid9-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid9-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid9-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth29-hang p, .fif29-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth29-hang p span, .fif29-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth29-hang p input, .fif29-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}
</style>
