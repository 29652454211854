<template>
  <div class="userInfoOne">
    <div class="userCon">
      <p class="userCon-title">完善个人信息</p>
      <div class="user-deil">
        <div class="box">
          <span class="span">真实姓名</span>
          <input
            class="input"
            type="text"
            v-model="UserName"
            placeholder="请输入真实姓名"
          />
        </div>
        <div class="box">
          <span class="span">性别</span>
          <p class="p" @click="changeNan">
            <img :src="[sex == '男' ? nan2 : nan1]" alt="" srcset="" />
            <i>男</i>
          </p>
          <p class="p" @click="changeNV">
            <img :src="[sex == '女' ? nv2 : nv1]" alt="" srcset="" />
            <i>女</i>
          </p>
        </div>
        <div class="box">
          <span class="span">所在地区</span>

          <el-cascader
            class="xuanze"
            style="z-index: 99999; margin-top: -0.16rem"
            size="large"
            :options="options"
            v-model="selectedOptions"
            @change="handleChange"
          >
          </el-cascader>
        </div>
        <div class="box">
          <span class="span">详细地址</span>
          <input
            type="text"
            class="input"
            v-model="deilsdata"
            placeholder="请输入详细地址"
          />
        </div>
        <button class="submit3" @click="Chongxin2">重新提交</button>
        <p class="curse" v-if="b_reason">审核失败:{{ b_reason }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { regionDataPlus, CodeToText } from "element-china-area-data";
export default {
  name: "MyAppFirst",

  data() {
    return {
      baseUrl: "https://api.dahuayoushanyun.com",
      options: regionDataPlus,
      selectedOptions: [],
      dynamic: -1,
      nan1: require("./assets/nan.png"),
      nan2: require("./assets/nan2.png"),
      nv1: require("./assets/nv.png"),
      nv2: require("./assets/nv2.png"),
      dz: "",
      sex: "",
      deilsdata: "",
      UserName: "",
      b_reason: "",
    };
  },
  components: {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    getUserInfo() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "get",
        url: `${this.baseUrl}/Api/User/userOauth1?user_access_token=${token}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        if (res.data.status == 1) {
          let elInput = this.$(".el-input__inner");
          this.sex = res.data.data.sex1;
          elInput[0].value = res.data.data.area;
          this.UserName = res.data.data.name;
          this.deilsdata = res.data.data.address;
          this.b_reason = res.data.data.b_reason;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },

    Chongxin2() {
      //重新提交
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      let elInput = this.$(".el-input__inner");
      let value1 = this.sex;
      let value2 = elInput[0].value;
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/Api/User/addAddress?user_access_token=${token}`,
        data: {
          list: [
            {
              name: this.UserName,
              sex1: value1,
              area: value2,
              address: this.deilsdata,
            },
          ],
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        this.$router.push("/UserInfoShen");
        this.$message({
          message: res.data.msg,
          type: "success",
        });
      });
    },
    changeNan() {
      this.sex = "男";
    },
    changeNV() {
      this.sex = "女";
    },
    handleChange(value) {
      var dz =
        CodeToText[value[0]] + "/" + CodeToText[value[1]] + "/" + CodeToText[value[2]];
      this.dz = dz;
    },
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.userInfoOne {
  width: 100%;
  height: 100vh;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('./assets/bju.png') no-repeat center / cover;
}

.userCon {
  width: rem(700);
  height: rem(600);
  background: #FFFFFF;
  border-radius: rem(20);
  position: absolute;
  top: 20%;
  left: 35%;
}

.userCon-title {
  position: absolute;
  font-size: rem(36);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0473C2;
  line-height: rem(50);
  left: rem(248);
  top: rem(0);
}

.user-deil {
  width: rem(626);
  height: rem(400);
  position: absolute;
  bottom: rem(66);
  left: rem(38);
  display: flex;
  flex-direction: column;
}

.user-deil div.box {
  width: 100%;
  height: rem(50);
  display: flex;
  margin-top: rem(20);
  justify-content: space-around;
}

.user-deil div.box .span {
  width: rem(96);
  font-size: rem(24);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6B6B6B;
  line-height: rem(33);
}

.user-deil div.box .input {
  width: rem(400);
  height: rem(50);
  background: #F2F2F2;
  border-radius: rem(4);
  border: 1px solid #F2F2F2;
  outline: none;
  font-size: rem(18);
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ABABAB;
  line-height: erm(25);
  text-indent: rem(40);
}

.user-deil div.box .p {
  width: rem(100);
  height: rem(50);
  position: relative;
  left: rem(-60);
  border-radius: rem(4);
  margin: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}

.user-deil div.box .p i {
  width: rem(18);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6B6B6B;
  line-height: rem(45);
  font-style: normal;
  margin-left: rem(20);
}

.user-deil div.box .p img {
  width: rem(38);
  height: rem(38);
  position: relative;
  top: rem(5);
}

.submit3 {
  width: rem(526);
  height: rem(60);
  background: #0473C2;
  box-shadow: 1px 1px rem(4) 0px rgba(4, 115, 194, 0.7);
  border-radius: rem(4);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: rem(60);
  border: none;
  margin-top: rem(50);
  margin-left: rem(50);
}

.curse {
  width: 100%;
  text-align: center;
  font-size: rem(14);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF5959;
  line-height: rem(20);
}

.xuanze {
  width: rem(400) !important;
  height: rem(50) !important;
}
</style>
