<template>
  <div class="container">
    <div class="one" v-if="currentPage4 == 1">
      <el-form :model="form">
        <el-form-item label="姓名：">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="年龄：">
          <el-input v-model="form.age" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="籍贯：">
          <el-input v-model="form.hometown" placeholder="请输入籍贯"></el-input>
        </el-form-item>
        <el-form-item label="职业：">
          <el-input
            v-model="form.profession"
            placeholder="请输入职业"
          ></el-input>
        </el-form-item>
        <el-form-item label="国籍：">
          <el-input v-model="form.country" placeholder="请输入国籍"></el-input>
        </el-form-item>
        <el-form-item label="民族：">
          <el-input
            v-model="form.nationality"
            placeholder="请输入民族"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input v-model="form.card" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="电话号码：">
          <el-input
            v-model="form.phone"
            placeholder="请输入电话号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="form.tel" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="所在医院：">
          <el-select v-model="form.hospital" placeholder="请选择所在医院">
            <el-option label="大华医院" value="大华医院"></el-option>
            <el-option label="徐汇中心医院" value="徐汇中心医院"></el-option>
            <el-option label="第八人民医院" value="第八人民医院"></el-option>
            <el-option
              label="社区卫生服务中心"
              value="社区卫生服务中心"
            ></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="住院号：">
          <el-input v-model="form.number" placeholder="请输入住院号"></el-input>
        </el-form-item>
        <el-form-item label="科室：">
          <el-input
            v-model="form.department"
            placeholder="请输入科室"
          ></el-input>
        </el-form-item>
        <el-form-item label="入院日期：">
          <el-date-picker
            align="right"
            v-model="form.d_date"
            type="datetime"
            placeholder="请选择入院日期"
          ></el-date-picker>
        </el-form-item>
        <div class="inhospital">
          <el-form-item label="住院天数：" style="display:flex;">
            <el-input
              v-model="form.discharge"
              placeholder="请输入住院天数"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2.4vw">天</div>
          <el-form-item label="出院日期：">
            <el-date-picker
              v-model="form.a_date"
              type="datetime"
              placeholder="请选择出院日期"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="inhospital">
          <el-form-item label="住院费用：" style="display:flex;">
            <el-input
              v-model="form.hospital_costs"
              placeholder="请输入住院费用"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-1.5vw">元</div>
          <el-form-item label="药费：">
            <el-input
              v-model="form.medicine_fee"
              placeholder="请输入药费"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">元</div>
        </div>
        <div class="inhospital">
          <el-form-item label="医疗耗材费：" style="display:flex;">
            <el-input
              v-model="form.m_consumables"
              placeholder="请输入医疗耗材费"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">元</div>
          <el-form-item label="诊断：" style="display:flex;">
            <el-input
              v-model="form.diagnosis"
              placeholder="请输入诊断"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="two" v-if="currentPage4 == 2">
      <el-form :model="form" ref="form">
        <el-form-item label="部位：">
          <el-select v-model="form.location" placeholder="请选择部位">
            <el-option label="左" value="左"></el-option>
            <el-option label="右" value="右"></el-option>
            <el-option label="双侧" value="双侧"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否复发：">
          <el-select v-model="form.it_recurred" placeholder="请选择是否复发">
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <div class="timeOfOnset">
          <el-form-item label="发病时间：" style="flex:50%">
            <el-input v-model="onset_time_year" style="width:8vw"></el-input>
            <span style="margin-right:0.5vw;margin-left:0.5vw">年</span>
            <el-input v-model="onset_time_month" style="width:8vw"></el-input>
            <span style="margin-right:0.5vw;margin-left:0.5vw">月</span>
            <el-input v-model="onset_time_day" style="width:8vw"></el-input>
            <span style="margin-right:0.5vw;margin-left:0.5vw">日</span>
          </el-form-item>
          <el-form-item label="主要症状：" style="flex:50%">
            <el-select
              v-model="form.zhongkuai"
              placeholder="肿块大小cm"
              style="width:9.6vw;margin-right:0.5vw"
            >
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
            <el-select
              v-model="form.zhidi"
              placeholder="质地"
              style="width:9.6vw;margin-right:0.5vw"
            >
              <el-option label="软" value="软"></el-option>
              <el-option label="硬" value="硬"></el-option>
              <el-option label="中" value="中"></el-option>
            </el-select>
            <el-select
              v-model="form.yinglang"
              placeholder="是否进入阴囊"
              style="width:9.6vw"
            >
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
              <el-option label="无" value="无"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="timeOfOnset">
          <el-form-item label="主要症状2：" style="flex:50%">
            <el-select
              v-model="form.huina"
              placeholder="能否回纳"
              style="width:9.6vw !important;margin-right:0.5vw;"
            >
              <el-option label="能" value="能"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
            <el-select
              v-model="form.tengtojng"
              placeholder="是否疼痛"
              style="width:9.6vw;margin-right:0.5vw"
            >
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
            <el-select
              v-model="form.touguang"
              placeholder="透光实验"
              style="width:9.6vw"
            >
              <el-option label="阴性" value="阴性"></el-option>
              <el-option label="阳性" value="阳性"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="疝分类：">
            <el-select v-model="form.hernia" placeholder="请选择疝分类">
              <el-option label="易复性疝" value="易复性疝"></el-option>
              <el-option label="难复性疝" value="难复性疝"></el-option>
              <el-option label="嵌顿性疝" value="嵌顿性疝"></el-option>
              <el-option label="绞窄性疝" value="绞窄性疝"></el-option>
              <el-option label="Richter疝" value="Richter疝"></el-option>
              <el-option label="Littre疝" value="Littre疝"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="疝分型：">
          <el-select v-model="form.hernia_type" placeholder="请选择疝分型">
            <el-option label="I型" value="I型"></el-option>
            <el-option label="Ⅱ型" value="Ⅱ型"></el-option>
            <el-option label="Ⅲ型" value="Ⅲ型"></el-option>
            <el-option label="Ⅳ型" value="Ⅳ型"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="腹压增高因素：">
          <el-select v-model="form.pressure" placeholder="请选择腹压增高因素">
            <el-option label="重体力活动" value="重体力活动"></el-option>
            <el-option label="慢性咳嗽" value="慢性咳嗽"></el-option>
            <el-option label="腹水" value="腹水"></el-option>
            <el-option label="排尿困难" value="排尿困难"></el-option>
            <el-option label="便秘" value="便秘"></el-option>
            <el-option label="腹部手术" value="腹部手术"></el-option>
          </el-select>
        </el-form-item>
        <div class="inhospital">
          <el-form-item label="吸烟：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.smoking"
              placeholder="请输入吸烟年限"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">年</div>
          <el-form-item label="饮酒：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.drinking"
              placeholder="请输入饮酒年限"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">年</div>
        </div>
        <el-form-item label="其他：" style="display:flex;">
          <el-input
            style="width:29.9vw"
            v-model="form.other"
            placeholder="请输入其他"
          ></el-input>
        </el-form-item>
        <el-form-item label="女性月经史：" style="display:flex;">
          <el-input
            style="width:29.9vw"
            v-model="form.menstrual_history"
            placeholder="请输入女性月经史"
          ></el-input>
        </el-form-item>
        <div class="inhospital">
          <el-form-item
            label="婚育史："
            style="display:flex;width: 40vw !important"
          >
            <el-input
              style="width:29.9vw"
              v-model="form.childbirth"
              placeholder="请输入婚育史"
            ></el-input>
          </el-form-item>
          <el-form-item label="婚育史：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.body_temperature"
              placeholder="请输入婚育史"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2.5vw">℃</div>
        </div>
        <div class="inhospital">
          <el-form-item label="血压：" style="display:flex;margin-left:0.3vw">
            <el-input
              style="width:29.9vw;"
              v-model="form.blood_pressure"
              placeholder="请输入血压"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-0.5vw">mmHg</div>
          <el-form-item label="脉搏：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.pulse"
              placeholder="请输入脉搏"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-1vw">次/分</div>
        </div>
        <div class="inhospital">
          <el-form-item label="身高：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.height"
              placeholder="请输入身高"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">cm</div>
          <el-form-item label="体重：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.weight"
              placeholder="请输入体重"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">kg</div>
        </div>
        <el-form-item label="BMI指数：">
          <el-input
            style="width:29.9vw"
            v-model="form.bmi_value"
            placeholder="请输入BMI指数"
          ></el-input>
        </el-form-item>
        <el-form-item label="合并疾病：">
          <el-select v-model="form.comorbidity" placeholder="请选择合并疾病">
            <el-option label="高血压" value="高血压"></el-option>
            <el-option label="糖尿病" value="糖尿病"></el-option>
            <el-option label="冠心病" value="冠心病"></el-option>
            <el-option label="肺气肿" value="肺气肿"></el-option>
            <el-option label="贫血" value="贫血"></el-option>
            <el-option label="前列腺增生" value="前列腺增生"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="three" v-if="currentPage4 == 3">
      <el-form :model="form">
        <el-form-item label="心电图：" prop="qianxinUrl">
          <div class="pic_box">
            <!-- <img :src="form1.fileUrl" v-if="upload1" alt=""> -->
            <div class="hasUpload_pic" v-if="qianxinUrl.length != 0">
              <img
                v-for="(item, index) in xinList"
                :key="index"
                :src="item"
                alt=""
                @click="deleteXin(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel11"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile11"
              list-type="picture-card"
              :on-remove="handleRemove11"
            >
              <i class="el-icon-plus"></i>
              <span>上传心电图</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="胸片：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl2.length != 0">
              <img
                v-for="(item, index) in xiongList"
                :key="index"
                :src="item"
                @click="deleteXiong(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel22"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile22"
              list-type="picture-card"
              :on-remove="handleRemove22"
            >
              <i class="el-icon-plus"></i>
              <span>上传胸片</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="B超：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl3.length != 0">
              <img
                v-for="(item, index) in bList"
                :key="index"
                :src="item"
                @click="deleteB(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel33"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile33"
              list-type="picture-card"
              :on-remove="handleRemove33"
            >
              <i class="el-icon-plus"></i>
              <span>上传B超</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="CT：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl4.length != 0">
              <img
                v-for="(item, index) in ctList"
                :key="index"
                :src="item"
                @click="deleteCT(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel44"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile44"
              list-type="picture-card"
              :on-remove="handleRemove44"
            >
              <i class="el-icon-plus"></i>
              <span>上传CT</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="血生化：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl5.length != 0">
              <img
                v-for="(item, index) in xshList"
                :key="index"
                :src="item"
                @click="deleteXSH(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel55"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile55"
              list-type="picture-card"
              :on-remove="handleRemove55"
            >
              <i class="el-icon-plus"></i>
              <span>上传血生化</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="其他：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl6.length != 0">
              <img
                v-for="(item, index) in otherList"
                :key="index"
                :src="item"
                @click="deleteOther(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel66"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile66"
              list-type="picture-card"
              :on-remove="handleRemove66"
            >
              <i class="el-icon-plus"></i>
              <span>上传其他</span>
            </el-upload>
          </div>
        </el-form-item>
        <!-- <el-form-item label="血常规：">
          <el-input
            v-model="form.blood_routine"
            placeholder="请输入血常规检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="尿常规：">
          <el-input
            v-model="form.urine_routine"
            placeholder="请输入尿常规检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="粪常规：">
          <el-input
            v-model="form.fecal_routine"
            placeholder="请输入粪常规检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="血糖：">
          <el-input
            v-model="form.blood_sugar"
            placeholder="请输入血糖检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="肝功能：">
          <el-input
            v-model="form.liver_function"
            placeholder="请输入肝功能检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="肾功能：">
          <el-input
            v-model="form.kidney_function"
            placeholder="请输入肾功能检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="four" v-if="currentPage4 == 4">
      <el-form :model="form">
        <!-- <el-form-item label="电解质：">
          <el-input
            v-model="form.electrolyte"
            placeholder="请输入电解质检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="血凝：">
          <el-input
            v-model="form.hemagglutination"
            placeholder="请输入血凝检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="其他：">
          <el-input
            v-model="form.other_results"
            placeholder="请输入其他检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="肠道准备：">
          <el-select v-model="form.b_preparation">
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
            <el-option label="无" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="导尿：">
          <el-select v-model="form.catheterization">
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
            <el-option label="无" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预防性抗菌素：">
          <el-select v-model="form.antibiotics">
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="麻醉方式：">
          <el-select v-model="form.anesthesia" placeholder="请选择麻醉方式">
            <el-option label="全麻" value="全麻"></el-option>
            <el-option label="腰麻" value="腰麻"></el-option>
            <el-option label="连硬" value="连硬"></el-option>
            <el-option label="局麻" value="局麻"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手术方式：" style="flex:50%">
          <el-select
            v-model="shoushufangshi1"
            placeholder="请选择手术方式"
            style="width:13.9vw;margin-right:2vw"
          >
            <el-option label="传统手术" value="传统手术"></el-option>
            <el-option label="无张力修补术" value="无张力修补术"></el-option>
            <el-option label="腔镜手术" value="腔镜手术"></el-option>
          </el-select>
          <el-select
            v-model="shoushufangshi2"
            placeholder="请选择手术方法"
            style="width:13.9vw;margin-right:2vw"
          >
            <el-option label="Bassini法" value="Bassini法"></el-option>
            <el-option label="无张力修补术" value="无张力修补术"></el-option>
            <el-option label="Ferguson法" value="Ferguson法"></el-option>
            <el-option label="Shouldice法" value="Shouldice法"></el-option>
            <el-option label="Halsted法" value="Halsted法"></el-option>
            <el-option
              label="单纯平片修补术 Lichtenstein手术"
              value="单纯平片修补术 Lichtenstein手术"
            ></el-option>
            <el-option
              label="网塞-平片修补术 Rutkow手术"
              value="网塞-平片修补术 Rutkow手术"
            ></el-option>
            <el-option
              label="腹膜前修补术Kugel"
              value="腹膜前修补术Kugel"
            ></el-option>
            <el-option
              label="巨大补片加强内脏囊手术GPRVS"
              value="巨大补片加强内脏囊手术GPRVS"
            ></el-option>
            <el-option label="Gilbert手术" value="Gilbert手术"></el-option>
            <el-option label="Stoppa手术" value="Stoppa手术"></el-option>
            <el-option
              label="全腹膜外修补术TEP"
              value="全腹膜外修补术TEP"
            ></el-option>
            <el-option
              label="经腹腔腹膜前修补术TAPP"
              value="经腹腔腹膜前修补术TAPP"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="植入补片信息：" style="flex:50%">
          <el-input
            v-model="form.implanted_patch_name"
            placeholder="请输入名称"
            style="width:13.9vw;margin-right:2vw"
          ></el-input>
          <el-input
            v-model="form.implanted_patch_model"
            placeholder="请输入型号"
            style="width:13.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="植入补片信息2：" style="flex:50%">
          <el-input
            v-model="form.implant_patch_size"
            placeholder="请输入尺寸单位cm"
            style="width:13.9vw;margin-right:2vw"
          ></el-input>
          <el-input
            v-model="form.implantation_patch_cost"
            placeholder="请输入费用单位"
            style="width:13.9vw"
          ></el-input>
        </el-form-item>
        <div class="inhospital">
          <el-form-item label="有无引流：">
            <el-select
              placeholder="请选择有无引流"
              v-model="form.about_drainage"
            >
              <el-option label="有" value="有"></el-option>
              <el-option label="无" value="无"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出血量：" style="display:flex;">
            <el-input
              style="width:29.9vw"
              v-model="form.bleeding_volume"
              placeholder="请输入出血量"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2.5vw">ml</div>
        </div>
        <el-form-item label="手术时间：" style="flex:50%">
          <el-input
            v-model="form.xiaos"
            placeholder="请输入小时"
            style="width:10.5vw"
          ></el-input>
          <span style="margin-right:4vw;margin-left:1vw">小时</span>
          <el-input
            v-model="form.fenz1"
            placeholder="请输入分钟"
            style="width:10.5vw"
          ></el-input>
          <span style="margin-left:1vw">分钟</span>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
    </div>
    <div class="five" v-if="currentPage4 == 5">
      <el-form :model="form">
        <div class="three_top_box">
          <div class="top_box_title">
            <div style="display:flex;justify-content: space-around;width:100%">
              <div class="top_title_item_">
                主刀医生姓名
              </div>
              <div class="top_title_item_">
                主刀职位
              </div>
              <div class="top_title_item_">
                一助医生姓名
              </div>
              <div class="top_title_item_">
                一助职位
              </div>
              <div class="top_title_item_">
                二助医生姓名
              </div>
              <div class="top_title_item_">
                二助医生职位
              </div>
            </div>
            <div class="top_title_item_" style="width:80px">操作</div>
          </div>
          <!-- <div v-for="(item,index) in list" :key="index" > -->
          <div class="top_box_content">
            <el-form :model="doctorInfo">
              <el-form-item>
                <el-input
                  v-model="doctorInfo.doctorName"
                  placeholder="请输入主刀医生姓名"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="doctorInfo.chiefKnifePosition"
                  placeholder="请输入主刀职位"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="doctorInfo.yizhuName"
                  placeholder="请输入一助医生姓名"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="doctorInfo.yizhuPosition"
                  placeholder="请输入一助职位"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="doctorInfo.erzhuName"
                  placeholder="请输入二助医生姓名"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="doctorInfo.erzhuPosition"
                  placeholder="请输入二助医生职位"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-button type="primary" @click="addNewPosition">新增</el-button>
          </div>
          <!-- </div> -->
          <div>
            <div v-for="(item, index) in list" :key="index">
              <div class="top_box_content">
                <div class="top_item">{{ item.tiwen }}</div>
                <div class="top_item" style="margin-left: 12px">
                  {{ item.zhuzhi }}
                </div>
                <div class="top_item" style="margin-left:15px">
                  {{ item.xueya }}
                </div>
                <div class="top_item" style="margin-left:10px">
                  {{ item.yizhi }}
                </div>
                <div class="top_item" style="margin-left:10px">
                  {{ item.erzhuName }}
                </div>
                <div class="top_item" style="margin-left: 20px">
                  {{ item.erzhuPosition }}
                </div>
                <div class="option_btn">
                  <el-button type="text" @click="deeleteDoctor(index)"
                    >删除</el-button
                  >
                  <el-button type="text" @click="editDialogOne(index)"
                    >编辑</el-button
                  >
                  <el-dialog
                    width="70%"
                    :append-to-body="true"
                    :visible.sync="dialogFormVisible"
                  >
                    <el-form :model="editDoctorList">
                      <el-form-item>
                        <el-input
                          v-model="editDoctorList.doctorName"
                          placeholder="请输入主刀医生姓名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="editDoctorList.chiefKnifePosition"
                          placeholder="请输入主刀职位"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="editDoctorList.yizhuName"
                          placeholder="请输入一助医生姓名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="editDoctorList.yizhuPosition"
                          placeholder="请输入一助职位"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="editDoctorList.erzhuName"
                          placeholder="请输入二助医生姓名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="editDoctorList.erzhuPosition"
                          placeholder="请输入二助医生职位"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisible = false"
                        >取 消</el-button
                      >
                      <el-button
                        type="primary"
                        @click="confirmEditDoctor(index)"
                        >确认</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="three_bottom_box">
          <div class="top_box_title">
            <div class="bottom_title_item_">
              序号
            </div>
            <div class="bottom_title_item_">
              体温
            </div>
            <div class="bottom_title_item_">
              血压
            </div>
            <div class="bottom_title_item_">
              脉搏
            </div>
            <div class="bottom_title_item_">
              尿量
            </div>
            <div class="bottom_title_item_">
              引流量
            </div>
            <div class="bottom_title_item_">
              疼痛评分
            </div>
            <div class="bottom_title_item_">操作</div>
          </div>
          <div class="bottom_box_content">
            <div style="display:flex;justify-content:space-around;width:88%;">
              <el-form :model="patientInfo">
                <div style="width:5vw">
                  {{ bottomList.length + 1 }}
                </div>
                <div class="bottom_form_item">
                  <el-form-item>
                    <el-input
                      v-model="patientInfo.temperature"
                      placeholder="请输入体温"
                    ></el-input>
                  </el-form-item>
                  <div class="sign" style="font-size:12px">℃</div>
                </div>
                <div class="bottom_form_item">
                  <el-form-item>
                    <el-input
                      v-model="patientInfo.bloodPressure"
                      placeholder="请输入血压"
                    ></el-input>
                  </el-form-item>
                  <div class="sign" style="font-size:12px">mmHg</div>
                </div>
                <div class="bottom_form_item">
                  <el-form-item>
                    <el-input
                      v-model="patientInfo.pulse"
                      placeholder="请输入脉搏"
                    ></el-input>
                  </el-form-item>
                  <div class="sign" style="font-size:12px">次/分</div>
                </div>
                <div class="bottom_form_item">
                  <el-form-item>
                    <el-input
                      v-model="patientInfo.urine"
                      placeholder="请输入尿量"
                    ></el-input>
                  </el-form-item>
                  <div class="sign" style="font-size:12px">ml</div>
                </div>
                <div class="bottom_form_item">
                  <el-form-item>
                    <el-input
                      v-model="patientInfo.drainage"
                      placeholder="请输入引流量"
                    ></el-input>
                  </el-form-item>
                  <div class="sign" style="font-size:12px">ml</div>
                </div>
                <div class="bottom_form_item">
                  <el-form-item>
                    <el-input
                      v-model="patientInfo.pain"
                      placeholder="请输入疼痛评分"
                    ></el-input>
                  </el-form-item>
                  <div class="sign" style="width: 3vw;font-size:12px">
                    1-10分
                  </div>
                </div>
              </el-form>
            </div>
            <el-button
              type="primary"
              style="margin-left: -70px"
              @click="addBottom"
              >新增</el-button
            >
          </div>
          <div>
            <div v-for="(item, index) in bottomList" :key="index">
              <div
                class="bottom_box_content"
                style="justify-content:space-around"
              >
                <div class="top_item">{{ index + 1 }}</div>
                <div class="top_item">{{ item.tiwen }}℃</div>
                <div class="top_item">{{ item.xueya }}mmHg</div>
                <div class="top_item">{{ item.maibo }}次/分</div>
                <div class="top_item">{{ item.niao }}ml</div>
                <div class="top_item">{{ item.drainage }}ml</div>
                <div class="top_item" style="margin-right: 60px;">
                  {{ item.tengtong }}分
                </div>
                <div
                  class="option_btn"
                  style="width:12vw;justify-content:flex-start"
                >
                  <el-button type="text" @click="deletePatientIn(index)"
                    >删除</el-button
                  >
                  <el-button type="text" @click="editPatientIn(index)"
                    >编辑</el-button
                  >
                  <el-dialog
                    width="75%"
                    :append-to-body="true"
                    :visible.sync="dialogBottomFormVisible"
                  >
                    <el-form :model="editPatientList">
                      <div style="width:3vw">
                        {{ editPatientList.index }}
                      </div>
                      <div class="bottom_form_item">
                        <el-form-item>
                          <el-input
                            v-model="editPatientList.temperature"
                            placeholder="请输入体温"
                          ></el-input>
                        </el-form-item>
                        <div class="sign" style="font-size:12px">℃</div>
                      </div>
                      <div class="bottom_form_item">
                        <el-form-item>
                          <el-input
                            v-model="editPatientList.bloodPressure"
                            placeholder="请输入血压"
                          ></el-input>
                        </el-form-item>
                        <div
                          class="sign"
                          style="font-size:12px;margin-right:1.5vw"
                        >
                          mmHg
                        </div>
                      </div>
                      <div class="bottom_form_item">
                        <el-form-item>
                          <el-input
                            v-model="editPatientList.pulse"
                            placeholder="请输入脉搏"
                          ></el-input>
                        </el-form-item>
                        <div
                          class="sign"
                          style="font-size:12px;margin-right:1vw"
                        >
                          次/分
                        </div>
                      </div>
                      <div class="bottom_form_item">
                        <el-form-item>
                          <el-input
                            v-model="editPatientList.urine"
                            placeholder="请输入尿量"
                          ></el-input>
                        </el-form-item>
                        <div
                          class="sign"
                          style="font-size:12px;margin-right:1vw"
                        >
                          ml
                        </div>
                      </div>
                      <div class="bottom_form_item">
                        <el-form-item>
                          <el-input
                            v-model="editPatientList.drainage"
                            placeholder="请输入引流量"
                          ></el-input>
                        </el-form-item>
                        <div
                          class="sign"
                          style="font-size:12px;margin-right:1vw"
                        >
                          ml
                        </div>
                      </div>
                      <div class="bottom_form_item">
                        <el-form-item>
                          <el-input
                            v-model="editPatientList.pain"
                            placeholder="请输入疼痛评分"
                          ></el-input>
                        </el-form-item>
                        <div class="sign" style="width: 3vw;font-size:12px">
                          1-10分
                        </div>
                      </div>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisible = false"
                        >取 消</el-button
                      >
                      <el-button
                        type="primary"
                        @click="confirmEditPatient(index)"
                        >确认</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="six" v-if="currentPage4 == 6">
      <el-form :model="form">
        <div class="inhospital">
          <el-form-item label="术后下床时间：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.urination_time"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
          <el-form-item label="术后自行解尿时：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.defecation_time"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
        </div>
        <div class="inhospital">
          <el-form-item label="术后排气排便时间：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.defecation_time_after"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
          <el-form-item label="抗菌素使用时间：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.antibacterial_time"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
        </div>
        <div class="inhospital">
          <el-form-item label="导尿管拔除时间：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.catheter_removal_time"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
          <el-form-item label="引流管拔除时间：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.drainage_tube_removal_time"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
        </div>
        <div class="inhospital">
          <el-form-item label="术后并发症：">
            <el-select
              style="width:27.9vw"
              v-model="form.postoperative_complications"
            >
              <el-option label="无" value="无"></el-option>
              <el-option label="伤口裂开" value="伤口裂开"></el-option>
              <el-option label="伤口积液" value="伤口积液"></el-option>
              <el-option label="阴囊血肿" value="阴囊血肿"></el-option>
              <el-option label="下肢血栓" value="下肢血栓"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="伤口拆线时间：" style="display:flex;">
            <el-input
              style="width:27.9vw"
              v-model="form.suture_removal_time"
            ></el-input>
          </el-form-item>
          <div class="sign" style="margin-left:-2vw">天</div>
        </div>
        <el-form-item label="伤口愈合情况：" style="flex:50%">
          <el-select
            v-model="yuhefenlei"
            placeholder="请选择愈合分类"
            style="width:12.9vw;margin-right:2vw;"
          >
            <el-option label="Ⅰ" value="Ⅰ"></el-option>
            <el-option label="Ⅱ" value="Ⅱ"></el-option>
            <el-option label="Ⅲ" value="Ⅲ"></el-option>
            <el-option label="Ⅳ" value="Ⅳ"></el-option>
          </el-select>
          <el-select
            v-model="yuhedengji"
            placeholder="请选择愈合等级"
            style="width:12.9vw;"
          >
            <el-option label="甲" value="甲"></el-option>
            <el-option label="乙" value="乙"></el-option>
            <el-option label="丙" value="丙"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
    <div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        layout=" prev, pager, next"
        :page-size="1"
        :total="num"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "inpatientOperation",
  props: ["id", "currentWatch"],
  data() {
    return {
      baseUrl: "https://api.dahuayoushanyun.com",
      dialogFormVisible: false,
      form: {
        name: "", // 姓名
        age: "", // 年龄
        sex: "", // 性别
        profession: "", // 职业
        country: "", // 国籍
        nationality: "",
        hometown: "",
        card: "",
        phone: "",
        tel: "",
        hospital: "",
        number: "",
        department: "",
        d_date: "",
        a_date: "",
        discharge: "",
        hospital_costs: "",
        medicine_fee: "",
        m_consumables: "",
        diagnosis: "",
        location: "",
        it_recurred: "",
        onset_time: "", // 发病时间
        zhongkuai: "",
        zhidi: "",
        yinglang: "",
        touguang: "",
        huina: "",
        tengtojng: "",
        hernia: "",
        hernia_type: "",
        pressure: "",
        smoking: "",
        drinking: "",
        other: "",
        menstrual_history: "",
        childbirth: "",
        body_temperature: "",
        blood_pressure: "",
        pulse: "",
        height: "",
        weight: "",
        bmi_value: "",
        fenz1: "",
        xiaos: "",
        comorbidity: "",
        electrocardiogram: "",
        radiograph: "",
        ultrasound: "",
        ct: "",
        // blood_routine: "",
        // fecal_routine: "",
        // urine_routine: "",
        // blood_sugar: "",
        // liver_function: "",
        // kidney_function: "",
        // electrolyte: "",
        // hemagglutination:"",
        hemagglutinatio: "",
        other_results: "",
        b_preparation: "",
        catheterization: "",
        antibiotics: "",
        anesthesia: "",
        surgical_approach: {},
        implant_patch_name: "",
        implanted_patch_model: "",
        implant_patch_size: "",
        implantation_patch_cost: "",
        about_drainage: "",
        bleeding_volume: "",
        surgeon_information: [],
        postoperative_information: "",
        surgery_time: [],
        urination_time: "",
        defecation_time: "",
        defecation_time_after: "",
        antibacterial_time: "",
        catheter_removal_time: "",
        drainage_tube_removal_time: "",
        postoperative_complications: "",
        suture_removal_time: "",
        wound_healing: {},
        follow_up_interval: "",
        upload_blood_biochemistry: "",
        upload_other: "",
      },
      currentPage4: 1,
      num: 6,
      doctorInfo: {
        doctorName: "", // 主刀医生姓名
        chiefKnifePosition: "", // 主刀职位
        yizhuName: "", // 一助医生姓名
        yizhuPosition: "", // 一助职位
        erzhuName: "", // 二助医生姓名
        erzhuPosition: "", // 二助职位
      },
      list: [],
      bottomList: [],
      doctorInfoList: [],
      showDoctorInput: false, // 显示医生相关新增框
      patientInfoList: [],
      patientInfo: {
        index: 1,
        temperature: "", // 体温
        bloodPressure: "", // 血压
        pulse: "", // 脉搏
        urine: "", // 尿量
        drainage: "", // 引流量
        pain: "", // 疼痛评分
      },
      onset_time_year: "", // 发病年
      onset_time_month: "", // 发病月
      onset_time_day: "", // 发病日
      showPatientInput: false, // 显示病人相关新增框
      shoushufangshi1: "", // 手术方式1
      shoushufangshi2: "", // 手术方式2
      yuhefenlei: "", // 愈合分类
      yuhedengji: "", // 愈合等级
      form1: {
        file: "",
        type: 1,
        fileUrl: "",
        uid: "",
      },
      qianxinUrl: [],
      form2: {
        file2: "",
        type2: 1,
      },
      qianxinUrl2: [],
      form3: {
        file3: "",
        type3: 1,
      },
      qianxinUrl3: [],
      form4: {
        file4: "",
        type4: 1,
      },
      qianxinUrl4: [],
      qianxinUrl5: [],
      qianxinUrl6: [],
      doctorInfoform: {},
      editDoctorList: {
        doctorName: "",
        chiefKnifePosition: "",
        yizhuName: "",
        yizhuPosition: "",
        erzhuName: "",
        erzhuPosition: "",
      },
      patientInfoform: {},
      editPatientList: {
        index: "",
        temperature: "", // 体温
        bloodPressure: "", // 血压
        pulse: "", // 脉搏
        urine: "", // 尿量
        drainage: "", // 引流量
        pain: "", // 疼痛评分
      },
      dialogBottomFormVisible: false,
      upload1: false,
      xinList: [],
      ctList: [],
      bList: [],
      xiongList: [],
      otherList: [],
      xshList: [],
    };
  },
  methods: {
    editDialogOne(index) {
      this.dialogFormVisible = true;
      this.editDoctorList.doctorName = this.list[index].tiwen;
      this.editDoctorList.chiefKnifePosition = this.list[index].zhuzhi;
      this.editDoctorList.yizhuName = this.list[index].xueya;
      this.editDoctorList.yizhuPosition = this.list[index].yizhi;
      this.editDoctorList.erzhuName = this.list[index].erzhuName;
      this.editDoctorList.erzhuPosition = this.list[index].erzhuPosition;
    },
    editPatientIn(index) {
      this.dialogBottomFormVisible = true;
      this.editPatientList.index = this.bottomList[index].index;
      this.editPatientList.temperature = this.bottomList[index].tiwen;
      this.editPatientList.bloodPressure = this.bottomList[index].xueya;
      this.editPatientList.pulse = this.bottomList[index].maibo;
      this.editPatientList.urine = this.bottomList[index].niao;
      this.editPatientList.drainage = this.bottomList[index].drainage;
      this.editPatientList.pain = this.bottomList[index].tengtong;
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      let value = "";
      let value2 = "";
      let value3 = "";
      let value4 = "";
      let value5 = "";
      let value6 = "";
      if (this.currentPage4 == 3) {
        console.log(this.qianxinUrl, "是的话会");
        for (let i in this.qianxinUrl) {
          value = this.baseUrl + this.qianxinUrl[i].value;
          this.xinList.push(value);
          this.xinList = [...new Set(this.xinList)];
        }
        for (let i in this.qianxinUrl2) {
          value2 = this.baseUrl + this.qianxinUrl2[i].value;
          this.xiongList.push(value2);
          this.xiongList = [...new Set(this.xiongList)];
        }
        for (let i in this.qianxinUrl3) {
          value3 = this.baseUrl + this.qianxinUrl3[i].value;
          this.bList.push(value3);
          this.bList = [...new Set(this.bList)];
        }
        for (let i in this.qianxinUrl4) {
          value4 = this.baseUrl + this.qianxinUrl4[i].value;
          this.ctList.push(value4);
          this.ctList = [...new Set(this.ctList)];
        }
        for (let i in this.qianxinUrl5) {
          value5 = this.baseUrl + this.qianxinUrl5[i].value;
          this.xshList.push(value5);
          this.xshList = [...new Set(this.xshList)];
        }
        for (let i in this.qianxinUrl6) {
          value6 = this.baseUrl + this.qianxinUrl6[i].value;
          this.xshList.push(value6);
          this.xshList = [...new Set(this.xshList)];
        }
        console.log(this.xinList, "你的撒到iaji");
      }
    },
    // 删除心电图
    deleteXin(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl.splice(index, 1);
        this.xinList.splice(index, 1);
      });
    },
    // 删除胸片
    deleteXiong(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl2.splice(index, 1);
        this.xiongList.splice(index, 1);
      });
    },
    // 删除b超
    deleteb(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl3.splice(index, 1);
        this.bList.splice(index, 1);
      });
    },
    // 删除ct
    deleteCT(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl4.splice(index, 1);
        this.ctList.splice(index, 1);
      });
    },
    // 删除血生化
    deleteXSH(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl5.splice(index, 1);
        this.xshList.splice(index, 1);
      });
    },
    // 删除其他
    deleteOther(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl6.splice(index, 1);
        this.otherList.splice(index, 1);
      });
    },
    // 心电图
    uploadFile11(item) {
      console.log(item, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove11(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl) {
        if (this.qianxinUrl[i].key === file.uid) {
          this.qianxinUrl.splice(i, 1);
        }
        console.log(this.qianxinUrl, "434");
      }
    },
    // 胸片
    uploadFile22(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl2.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl2, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove22(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl2) {
        if (this.qianxinUrl2[i].key === file.uid) {
          this.qianxinUrl2.splice(i, 1);
        }
        console.log(this.qianxinUrl2, "434");
      }
    },
    // B超
    uploadFile33(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl3.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl3, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove33(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl3) {
        if (this.qianxinUrl3[i].key === file.uid) {
          this.qianxinUrl3.splice(i, 1);
        }
        console.log(this.qianxinUrl3, "434");
      }
    },
    // CT
    uploadFile44(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl4.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl4, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove44(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl4) {
        if (this.qianxinUrl4[i].key === file.uid) {
          this.qianxinUrl4.splice(i, 1);
        }
        console.log(this.qianxinUrl4, "434");
      }
    },
    // 血生化
    uploadFile55(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl5.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl5, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove55(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl5) {
        if (this.qianxinUrl5[i].key === file.uid) {
          this.qianxinUrl5.splice(i, 1);
        }
        console.log(this.qianxinUrl5, "434");
      }
    },
    // 其他
    uploadFile66(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl6.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl6, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove66(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl6) {
        if (this.qianxinUrl6[i].key === file.uid) {
          this.qianxinUrl6.splice(i, 1);
        }
        console.log(this.qianxinUrl6, "434");
      }
    },
    formatTimeMills(timeMills) {
      var date = new Date(timeMills);
      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },

    // 提交
    submit() {
      if (this.form.d_date != "") {
        this.form.d_date = this.formatTimeMills(this.form.d_date);
      } else {
        this.form.d_date = "";
      }
      if (this.form.a_date != "") {
        this.form.a_date = this.formatTimeMills(this.form.a_date);
      } else {
        this.form.a_date = "";
      }
      this.form.onset_time =
        this.onset_time_year +
        "-" +
        this.onset_time_month +
        "-" +
        this.onset_time_day;
      this.form.surgical_approach = {
        shoushufangshi1: this.shoushufangshi1,
        shoushufangshi2: this.shoushufangshi2,
      };
      this.form.wound_healing = {
        shangeyu: this.yuhefenlei,
        selectjia: this.yuhedengji,
      };
      this.form.surgeon_information = this.list;
      this.form.surgery_time = this.bottomList;
      this.form.electrocardiogram = this.qianxinUrl;
      this.form.radiograph = this.qianxinUrl2;
      this.form.ultrasound = this.qianxinUrl3;
      this.form.ct = this.qianxinUrl4;
      this.form.upload_blood_biochemistry = this.qianxinUrl5;
      this.form.upload_other = this.qianxinUrl6;
      this.form.surgeon_information = this.list;
      this.form.surgery_time = this.bottomList;
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      if (this.id == "") {
        if (this.form.a_date != "" && this.form.d_date != "") {
          this.$axios({
            method: "POST",
            url: `${this.baseUrl}/Api/Applets/add?user_access_token=${token}&type=1`,
            data: {
              list: this.form,
            },
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }).then((res) => {
            if (res.data.status == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              // this.$router.push("/DocHou");
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else if (this.form.a_date == "") {
          this.$message({
            message: "请输入出院日期",
            type: "error",
          });
        } else {
          this.$message({
            message: "请输入入院日期",
            type: "error",
          });
        }
      } else {
        if (this.form.a_date != "" && this.form.d_date != "") {
          this.$axios({
            method: "POST",
            url: `${this.baseUrl}/Api/Applets/save?user_access_token=${token}&type=1`,
            data: {
              list: this.form,
            },
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }).then((res) => {
            if (res.data.status == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              // this.$router.push("/DocHou");
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else if (this.form.a_date == "") {
          this.$message({
            message: "请输入出院日期",
            type: "error",
          });
        } else {
          this.$message({
            message: "请输入入院日期",
            type: "error",
          });
        }
      }
    },
    getUidData() {
      console.log(2222222222);
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/Api/Applets/ysOne?user_access_token=${token}&type=1`,
        data: {
          list: [
            {
              id: this.id,
            },
          ],
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        this.form = res.data.data;
        if (this.form.electrocardiogram != null) {
          this.qianxinUrl = this.form.electrocardiogram;
        }
        if (this.form.radiograph != null) {
          this.qianxinUrl2 = this.form.radiograph;
        }
        if (this.form.ultrasound != null) {
          this.qianxinUrl3 = this.form.ultrasound;
        }
        if (this.form.ct != null) {
          this.qianxinUrl4 = this.form.ct;
        }
        if (this.form.upload_blood_biochemistry != null) {
          this.qianxinUrl5 = this.form.upload_blood_biochemistry;
        }
        if (this.form.upload_other != null) {
          this.qianxinUrl6 = this.form.upload_other;
        }
        if (this.form.surgeon_information != null) {
          this.list = this.form.surgeon_information;
          console.log(this.list, "多刷点哈斯u等哈说");
        }
        if (this.form.surgery_time != null) {
          this.bottomList = this.form.surgery_time;
        }
        if (this.form.wound_healing != null) {
          this.yuhedengji = this.form.wound_healing.selectjia;
          this.yuhefenlei = this.form.wound_healing.shangeyu;
        }
        if (this.form.surgical_approach != null) {
          this.shoushufangshi1 = this.form.surgical_approach.shoushufang;
          this.shoushufangshi2 = this.form.surgical_approach.editabledeil;
        }
        if (this.form.onset_time != null) {
          let arr = this.form.onset_time.split("-");
          this.onset_time_year = arr[0];
          this.onset_time_month = arr[1];
          this.onset_time_day = arr[2];
        }
        let value = "";
        let value2 = "";
        let value3 = "";
        let value4 = "";
        let value5 = "";
        let value6 = "";
        for (let i in this.qianxinUrl) {
          value = this.baseUrl + this.qianxinUrl[i].value;
          this.xinList.push(value);
          this.xinList = [...new Set(this.xinList)];
        }
        for (let i in this.qianxinUrl2) {
          value2 = this.baseUrl + this.qianxinUrl2[i].value;
          this.xiongList.push(value2);
          this.xiongList = [...new Set(this.xiongList)];
        }
        for (let i in this.qianxinUrl3) {
          value3 = this.baseUrl + this.qianxinUrl3[i].value;
          this.bList.push(value3);
          this.bList = [...new Set(this.bList)];
        }
        for (let i in this.qianxinUrl4) {
          value4 = this.baseUrl + this.qianxinUrl4[i].value;
          this.ctList.push(value4);
          this.ctList = [...new Set(this.ctList)];
        }
        for (let i in this.qianxinUrl5) {
          value5 = this.baseUrl + this.qianxinUrl5[i].value;
          this.xshList.push(value5);
          this.xshList = [...new Set(this.xshList)];
        }
        for (let i in this.qianxinUrl6) {
          value6 = this.baseUrl + this.qianxinUrl6[i].value;
          this.xshList.push(value6);
          this.xshList = [...new Set(this.xshList)];
        }
      });
    },
    addNewPosition() {
      if (
        this.doctorInfo.doctorName != "" &&
        this.doctorInfo.chiefKnifePosition != "" &&
        this.doctorInfo.yizhuName != "" &&
        this.doctorInfo.yizhuPosition != "" &&
        this.doctorInfo.erzhuName != "" &&
        this.doctorInfo.erzhuPosition != ""
      ) {
        this.showDoctorInput = true;
        this.doctorInfoform = {
          tiwen: this.doctorInfo.doctorName,
          zhuzhi: this.doctorInfo.chiefKnifePosition,
          xueya: this.doctorInfo.yizhuName,
          yizhi: this.doctorInfo.yizhuPosition,
          erzhuName: this.doctorInfo.erzhuName,
          erzhuPosition: this.doctorInfo.erzhuPosition,
        };
        console.log(this.doctorInfo, "山东黄金哦i阿娇");
        this.list.push(this.doctorInfoform);
        this.$message({
          message: "新增成功",
          type: "success",
        });
        this.doctorInfo.doctorName = "";
        this.doctorInfo.chiefKnifePosition = "";
        this.doctorInfo.yizhuName = "";
        this.doctorInfo.yizhuPosition = "";
        this.doctorInfo.erzhuName = "";
        this.doctorInfo.erzhuPosition = "";
        console.log(this.list, "是的脚手架哦");
        this.doctorInfoform = {};
      } else {
        this.$message({
          message: "请填写完整",
          type: "error",
        });
      }
    },
    addBottom() {
      if (
        this.patientInfo.temperature != "" &&
        this.patientInfo.bloodPressure != "" &&
        this.patientInfo.pulse != "" &&
        this.patientInfo.urine != "" &&
        this.patientInfo.drainage != "" &&
        this.patientInfo.pain != ""
      ) {
        this.showPatientInput = true;
        this.patientInfoList = {
          index: this.patientInfo.index,
          tiwen: this.patientInfo.temperature,
          xueya: this.patientInfo.bloodPressure,
          maibo: this.patientInfo.pulse,
          niao: this.patientInfo.urine,
          drainage: this.patientInfo.drainage,
          tengtong: this.patientInfo.pain,
        };
        this.bottomList.push(this.patientInfoList);
        this.bottomList = this.bottomList;
        console.log(this.bottomList, "删掉就死哦的");
        this.$message({
          message: "新增成功",
          type: "success",
        });
        this.patientInfo.temperature = "";
        this.patientInfo.bloodPressure = "";
        this.patientInfo.pulse = "";
        this.patientInfo.urine = "";
        this.patientInfo.drainage = "";
        this.patientInfo.pain = "";
        this.patientInfoList = {};
      } else {
        this.$message({
          message: "请填写完整",
          type: "error",
        });
      }
    },
    confirmEditDoctor(index) {
      this.$confirm("是否确认编辑？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
      }).then((_) => {
        this.dialogFormVisible = false;
        this.$message({
          message: "编辑成功",
          type: "success",
        });
        this.list[index] = this.editDoctorList;
      });
    },
    confirmEditPatient(index) {
      this.$confirm("是否确认编辑？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
      }).then((_) => {
        this.dialogBottomFormVisible = false;
        this.$message({
          message: "编辑成功",
          type: "success",
        });
        this.bottomList[index] = this.editPatientList;
      });
    },
    deeleteDoctor(index) {
      console.log(index, "刷水");
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
      }).then((_) => {
        this.dialogFormVisible = false;
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.list.splice(index, 1);
      });
    },
    deletePatientIn(index) {
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
      }).then((_) => {
        this.dialogBottomFormVisible = false;
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.bottomList.splice(index, 1);
      });
    },
  },
  created() {
    // console.log(11111);
    // console.log(this.id, "四大俗");
    if (this.currentWatch != "") {
      this.currentPage4 = this.currentWatch;
    } else {
      this.currentPage4 = 1;
    }
    console.log(this.currentPage4, "的视角的祭祀奥");
    if (this.id != "") {
      this.getUidData();
    } else {
      this.currentPage4 = 1;
      this.form.name = ""; // 姓名
      this.form.age = ""; // 年龄
      this.form.sex = ""; // 性别
      this.form.profession = ""; // 职业
      this.form.country = ""; // 国籍
      this.form.nationality = "";
      this.form.hometown = "";
      this.form.card = "";
      this.form.phone = "";
      this.form.tel = "";
      this.form.hospital = "";
      this.form.number = "";
      this.form.department = "";
      this.form.d_date = "";
      this.form.a_date = "";
      this.form.discharge = "";
      this.form.hospital_costs = "";
      this.form.medicine_fee = "";
      this.form.m_consumables = "";
      this.form.diagnosis = "";
      this.form.location = "";
      this.form.it_recurred = "";
      this.form.onset_time = ""; // 发病时间
      this.form.zhongkuai = "";
      this.form.zhidi = "";
      this.form.hernia = "";
      this.form.ct = "";
      // this.form.blood_routine = "";
      // this.form.fecal_routine = "";
      // this.form.urine_routine = "";
      // this.form.blood_sugar = "";
      // this.form.liver_function = "";
      // this.form.kidney_function = "";
      // this.form.electrolyte = "";
      // this.form.hemagglutination = ""
      this.form.hemagglutinatio = "";
      this.form.other_results = "";
      this.form.b_preparation = "";
      this.form.catheterization = "";
      this.form.antibiotics = "";
      this.form.anesthesia = "";
      this.form.surgical_approach = {};
      this.form.implant_patch_name = "";
      this.form.implanted_patch_model = "";
      this.form.implant_patch_size = "";
      this.form.implantation_patch_cost = "";
      this.form.about_drainage = "";
      this.form.bleeding_volume = "";
      this.form.surgeon_information = [];
      this.form.postoperative_information = "";
      this.form.surgery_time = [];
      this.form.urination_time = "";
      this.form.defecation_time = "";
      this.form.defecation_time_after = "";
      this.form.antibacterial_time = "";
      this.form.catheter_removal_time = "";
      this.form.drainage_tube_removal_time = "";
      this.form.postoperative_complications = "";
      this.form.suture_removal_time = "";
      this.form.wound_healing = {};
      this.form.follow_up_interval = "";
      this.qianxinUrl = [];
      this.qianxinUrl2 = [];
      this.qianxinUrl3 = [];
      this.qianxinUrl4 = [];
      this.qianxinUrl5 = [];
      this.qianxinUrl6 = [];
    }
  },
};
</script>

<style lang="stylus" scoped>
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height 50vh;
    font-size: 16px;
}
.one .el-form,.two .el-form,.three .el-form,.four .el-form,.six .el-form {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px !important;
}
::v-deep .one .el-form-item,::v-deep .two .el-form-item,::v-deep .four .el-form-item,::v-deep .six .el-form-item {
    flex: 50%;
    display: flex;
    height 5vh;
    margin-bottom: 2.5vh;
}
::v-deep .three .el-form-item {
    flex: 50%;
    display: flex;
    margin-bottom: 2vh;
}
::v-deep .one .el-input__inner,::v-deep .two .el-input__inner,::v-deep .three .el-input__inner,::v-deep .four .el-input__inner,::v-deep .six .el-input__inner {
    // width: 29.9vw;
    height: 4vh !important
}
::v-deep .one .el-form-item__content,::v-deep .one .el-select,::v-deep .two .el-select,::v-deep .four .el-select,::v-deep .six .el-select {
    width: 29.9vw;
}
::v-deep .one .el-form-item__label,::v-deep .two .el-form-item__label,::v-deep .four .el-form-item__label {
    width: 25%;
    font-size: 14px;
}
::v-deep .three .el-form-item__label {
    width: 20%;
}
::v-deep .six .el-form-item__label {
    width: 28%;
    font-size: 14px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15px !important;
}
::v-deep.date-picker  .el-input__inner {
  padding: 0 15px; // 这个15是el-input组件默认的值
}
::v-deep .el-input__prefix {
    position: absolute;
    left: 27vw; // left根据实际情况定值
    top: 0;
}
.inhospital {
    width: 100%;
    display:flex;
    justify-content: space-between;
    // align-items:center;
    line-height:5.5vh;
    // position: relative;
    height:5vh;
    margin-bottom: 2.5vh;
}
.sign  {
    width:2.5vw;
    font-size: rem(9);
    // position: absolute;
    // right: 43vw;
    // top: 2.5vh;
}
.el-pagination {
  position:relative;
  margin-left: 60vw;
  margin-bottom:10vh;
}
.top_box_title,.bottom_box_title {
  display: flex;
  justify-content: space-around;
  background: #FAFAFA;
  height: 4.6vh;
  line-height: 4.6vh;
}

.top_title_item_,.bottom_title_item_ {
  font-size: 14px;
  width: 10.4vw;
}

.bottom_title_item_ {
  width: rem(120);
}

.bottom_title_item_:nth-child(8){
  width: rem(30);
}

.three_top_box {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10vh;
}

.three_bottom_box {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom:10vh;
}

// .top_title_item_:nth-child(7){
//   width: 100px;
// }

.top_box_content,.bottom_box_content {
  display: flex;
  justify-content: space-around;
  align-items:center;
  height: 8vh;
}

.top_box_content .el-form,.bottom_box_content .el-form {
  padding: 0;
  width:100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
::v-deep .bottom_form_item .el-form-item .el-input__inner {
    width: 7.5vw;
}
.top_box_content .el-form .el-form-item,.bottom_box_content .el-form .el-form-item {
  margin-bottom: 0;
  display:flex;
}

.bottom_box_content .el-form .el-form-item(1) {
  width: 10.4vw;
}

.top_box_content .el-form .el-form-item .el-input__inner,.bottom_box_content .el-form .el-form-item .el-input__inner {
  height: rem(35);
}

.top_box_content .el-form .el-form-item .el-input__inner:placeholer,.bottom_box_content .el-form .el-form-item .el-input__inner:placeholer {
  font-size: rem(15);
}

.bottom_box_content .el-form .el-form-item .el-input__inner {
  width: rem(130);
}

.bottom_box_content .el-form-item__content {
  width: rem(150);
}

.top_box_content .el-button,.bottom_box_content .el-button {
  width: rem(100);
  height: rem(35);
  font-size: rem(16);
  line-height:0;
}
.bottom_form_item {
  display:flex;
  align-items:center;
  justify-content:space-between;
}

.top_item {
  font-size: 14px;
  width: 18.2vw;
  text-align: center;
}


.option_btn {
  width: rem(150);
  height: rem(40);
  display:flex;
  justify-content:center;
}
.option_btn .el-button {
  width: rem(30);
  font-size: rem(15);
}
.option_btn .el-button:nth-child(1){
  color: red;
}
.timeOfOnset {
    display:flex;
    width: 100%;
    justify-content: space-between;
}
::v-deep .el-input__inner::placeholder {
    font-size: 0.1vw !important;
}
::v-deep .el-upload--picture-card {
    display:flex;
    flex-direction:column;
    justify-content: center;
    line-height:3vh;
    border: 1px solid rgba(0,0,0,0.15);
    width: 10vw;
    height: 13.6vh;
    background: #fff;
}
.six .el-button {
    margin-top:30vh;
    margin-left:74vw;
    margin-bottom: 2vh;
    width: 7vw;
}
::v-deep .el-dialog .el-form {
  display:flex;
  height:20vh;
  align-items:center;
}
::v-deep .el-dialog .el-button+.el-button:hover {
  background-color: #409EFF !important;
}
::v-deep .el-message-box__btns button:nth-child(2):hover {
  background: pink !important;
}
::v-deep .el-dialog__body {
  word-break: normal;
  display:flex;
  height:20vh;
  line-height: 20vh;
}
::v-deep .bottom_box_content .el-dialog .el-form {
  width: 100%;
  height:10vh;
  dispaly:flex;
  justify-content: space-between;
  align-items:center;
}
::v-deep .el-dialog .el-form-item {
  margin-bottom: 0;
}
::v-deep .bottom_box_content .el-dialog .bottom_form_item {
  height:10vh
  line-height:10vh;
}
::v-deep .el-upload-list--picture-card {
  width:33.9vw;
  // height:10vh;
  // overflow-x:hidden;
  display:flex;
  flex-wrap: wrap;
  margin-bottom:0.5vh;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width:10vw;
  height: 10vh;
  display: normal;
}
.hasUpload_pic {
  width:33.9vw;
  // height:10vh;
  display:flex;
  // overflow-x:hidden;
  flex-wrap:wrap;
}
.hasUpload_pic img {
  width:10vw;
  height:10vh;
  margin-right:0.5vw;
}
</style>
<style>
.btnClass {
  background-color: #409eff !important;
}
</style>
