<template>
  <div class="registerbox">
    <div class="registerbox_title">
      上海市徐汇区腹壁疝多中心联合信息注册平台
    </div>
    <div class="main_content">
      <img src="./assets/loginBg.png" />
      <div class="register_form">
        <span>注册</span>
        <el-form :model="form">
          <el-form-item>
            <el-input
              v-model="form.userName"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.confirmPsd"
              placeholder="确认密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button class="cancel" @click="cancel">取消</el-button>
          <el-button class="confirm" @click="confirm">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const baseUrl = "https://api.dahuayoushanyun.com";
export default {
  name: "Register",
  data() {
    return {
      form: {
        userName: "",
        password: "",
        confirmPsd: "",
        phone: "",
      },
    };
  },
  methods: {
    // 取消
    cancel() {
      this.$router.push("/");
    },
    // 确认
    confirm() {
      console.log(1111111);
      if (
        this.form.userName != "" &&
        this.form.password != "" &&
        this.form.confirmPsd != "" &&
        this.form.phone != ""
      ) {
        this.$axios({
          method: "get",
          url: `${baseUrl}/Api/User/userregistered?pass=${this.form.password}&pass1=${this.form.confirmPsd}&phone=${this.form.phone}&name=${this.form.userName}`,
          headers: { "content-type": "application/json;charset=utf8" },
        }).then((res) => {
          console.log(res);
          if (res.status == 1) {
            this.$message({
              showClose: false,
              message: res.msg,
              type: "warning",
            });
            this.$router.push('/')
          } else {
            this.$message({
              showClose: true,
              message: "注册失败",
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          showClose: false,
          message: "请输入完整",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.registerbox {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.registerbox_title {
  width: 100%;
  height: rem(50);
  line-height: rem(50);
  background: #184E77;
  font-size: rem(20);
  text-align: center;
  color: #fff;
}

.main_content {
    width: 100%;
    height: 100%;
    padding: rem(194) rem(430) rem(320) rem(430);
    box-sizing: border-box;
    display: flex
}

.main_content img {
    width: rem(501);
    height: rem(364);
    margin-right: rem(70);
}

.register_form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.register_form span {
    font-size: rem(26);
}

::v-deep .el-form {
  margin-top: rem(20);
}

::v-deep .el-input {
    width: 100%;
}

::v-deep .el-input__inner {
  width: rem(500);
  background: #F4F8FF;
  border-radius: 8px;
  height: rem(50);
  margin-bottom: rem(-10);
  border: none;
}

.btn_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: rem(5);
}

.cancel,.confirm {
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    border-radius: rem(8);
    flex: 50%;
}

.cancel,.cancel:hover,.cancel:focus {
    background: #fff;
}

.confirm,.confirm:hover,.confirm:focus {
    background: #184E77;
    color: #fff;
}
</style>
