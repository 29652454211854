<template>
  <div class="hospitalTable-box">
    <el-aside width="12vw">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @select="selectNav"
      >
        <el-menu-item index="1">
          <span>住院病历信息</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span>随访病例信息</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span>新增录入住院手术病例</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span>新增录入随访病例</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main v-if="defaultActive == '1'">
      <div class="top_form">
        <el-form :model="form">
          <el-form-item>
            <el-input
              v-model="form.age"
              placeholder="请输入年龄进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.sex" placeholder="请选择性别进行搜索">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.hospital"
              placeholder="请选择所在医院进行搜索"
            >
              <el-option label="大华医院" value="大华医院"></el-option>
              <el-option label="徐汇中心医院" value="徐汇中心医院"></el-option>
              <el-option label="第八人民医院" value="第八人民医院"></el-option>
              <el-option label="社区卫生服务中心" value="社区卫生服务中心"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.discharge"
              placeholder="请输入住院天数进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.hospital_costs"
              placeholder="请输入住院总费用进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.diagnosis"
              placeholder="请选择诊断进行搜索"
            >
              <el-option label="斜疝" value="斜疝"></el-option>
              <el-option label="直疝" value="直疝"></el-option>
              <el-option label="股疝" value="股疝"></el-option>
              <el-option label="复合疝" value="复合疝"></el-option>
              <el-option label="脐疝" value="脐疝"></el-option>
              <el-option label="切口疝" value="切口疝"></el-option>
              <el-option label="白线疝" value="白线疝"></el-option>
              <el-option label="半月线疝" value="半月线疝"></el-option>
              <el-option label="腰疝" value="腰疝"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.location" placeholder="请选择部位进行搜索">
              <el-option label="左" value="左"></el-option>
              <el-option label="右" value="右"></el-option>
              <el-option label="双侧" value="双侧"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.hernia" placeholder="请选择疝分类进行搜索">
              <el-option label="易复性疝" value="易复性疝"></el-option>
              <el-option label="难复性疝" value="难复性疝"></el-option>
              <el-option label="嵌顿性疝" value="嵌顿性疝"></el-option>
              <el-option label="绞窄性疝" value="绞窄性疝"></el-option>
              <el-option label="Richter疝" value="Richter疝"></el-option>
              <el-option label="Littre疝" value="Littre疝"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.hernia_type"
              placeholder="请选择疝分型进行搜索"
            >
              <el-option label="I型" value="I型"></el-option>
              <el-option label="Ⅱ型 " value="Ⅱ型 "></el-option>
              <el-option label="Ⅲ型" value="Ⅲ型"></el-option>
              <el-option label="Ⅳ型" value="Ⅳ型"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.surgical_approach"
              placeholder="请选择手术方式进行搜索"
            >
              <el-option label="传统手术" value="传统手术"></el-option>
              <el-option label="无张力修补术" value="无张力修补术"></el-option>
              <el-option label="腔镜手术" value="腔镜手术"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.implant_patch_name"
              placeholder="请输入植入补片名称进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.postoperative_complications"
              placeholder="请输入术后并发症进行搜索"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="top_right">
          <el-button type="info" plain @click="reset">重置</el-button>
          <el-button type="primary" class="query_btn" @click="query"
            >查询</el-button
          >
          <el-button type="primary" class="query_btn" @click="exportZhu"
            >导出</el-button
          >
        </div>
      </div>
      <el-table
        :data="resData"
        style="width: 100%"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column prop="sex" label="性别"> </el-table-column>
        <el-table-column prop="profession" label="职业"> </el-table-column>
        <el-table-column prop="country" label="国籍"> </el-table-column>
        <el-table-column prop="nationality" label="民族"> </el-table-column>
        <el-table-column prop="hometown" label="籍贯"> </el-table-column>
        <el-table-column prop="card" label="身份证号" width="200">
        </el-table-column>
        <el-table-column prop="phone" label="电话号码" width="150">
        </el-table-column>
        <el-table-column prop="tel" label="手机号码" width="150">
        </el-table-column>
        <el-table-column prop="hospital" label="所在医院" width="120">
        </el-table-column>
        <el-table-column prop="number" label="住院号"> </el-table-column>
        <el-table-column prop="department" label="科室"> </el-table-column>
        <el-table-column prop="d_date" label="入院日期" width="130">
        </el-table-column>
        <el-table-column prop="a_date" label="出院日期" width="130">
        </el-table-column>
        <el-table-column prop="discharge" label="住院天数(天)" width="120">
        </el-table-column>
        <el-table-column prop="hospital_costs" label="住院费用" width="130">
        </el-table-column>
        <el-table-column prop="medicine_fee" label="药费"> </el-table-column>
        <el-table-column prop="m_consumables" label="医疗耗材费" width="120">
        </el-table-column>
        <el-table-column prop="diagnosis" label="诊断"> </el-table-column>
        <el-table-column prop="location" label="部位"> </el-table-column>
        <el-table-column prop="it_recurred" label="是否复发"> </el-table-column>
        <el-table-column prop="onset_time" label="发病时间(天)" width="120">
        </el-table-column>
        <el-table-column prop="zhongkuai" label="肿块大小(cm)" width="120">
        </el-table-column>
        <el-table-column prop="zhidi" label="质地"> </el-table-column>
        <el-table-column prop="yinglang" label="是否进入阴囊" width="130">
        </el-table-column>
        <el-table-column prop="tengtojng" label="是否疼痛"> </el-table-column>
        <el-table-column prop="huina" label="能否回纳"> </el-table-column>
        <el-table-column prop="touguang" label="透光试验"> </el-table-column>
        <el-table-column prop="hernia" label="疝分类"> </el-table-column>
        <el-table-column prop="hernia_type" label="疝分型"> </el-table-column>
        <el-table-column prop="pressure" label="腹压增高因素" width="130">
        </el-table-column>
        <el-table-column prop="smoking" label="吸烟"> </el-table-column>
        <el-table-column prop="drinking" label="饮酒"> </el-table-column>
        <el-table-column prop="other" label="其他"> </el-table-column>
        <el-table-column
          prop="menstrual_history"
          label="女性月经史"
          width="120"
        >
        </el-table-column>
        <el-table-column prop="childbirth" label="婚育史"> </el-table-column>
        <el-table-column prop="body_temperature" label="体温(℃)">
        </el-table-column>
        <el-table-column prop="blood_pressure" label="血压(mmHg)" width="120">
        </el-table-column>
        <el-table-column prop="pulse" label="脉搏(次/分)" width="120">
        </el-table-column>
        <el-table-column prop="height" label="身高(cm)"> </el-table-column>
        <el-table-column prop="weight" label="体重(kg)"> </el-table-column>
        <el-table-column prop="bmi_value" label="BMI指数"> </el-table-column>
        <el-table-column prop="comorbidity" label="合并疾病"> </el-table-column>
        <el-table-column label="心电图" width="130">
          <template slot-scope="scope">
            <el-button @click="CheckXin(scope.row.id)">点击查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="胸片" width="120">
          <template slot-scope="scope">
            <el-button @click="CheckXiong(scope.row.id)">点击查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="B超" width="120">
          <template slot-scope="scope">
            <el-button @click="CheckBt(scope.row.id)">点击查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="CT" width="120">
          <template slot-scope="scope">
            <el-button @click="CheckCT(scope.row.id)">点击查看</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="blood_routine" label="血常规"> </el-table-column>
        <el-table-column prop="fecal_routine" label="尿常规"> </el-table-column>
        <el-table-column prop="urine_routine" label="粪常规"> </el-table-column>
        <el-table-column prop="blood_sugar" label="血糖"> </el-table-column>
        <el-table-column prop="liver_function" label="肝功能">
        </el-table-column>
        <el-table-column prop="kidney_function" label="肾功能">
        </el-table-column> -->
        <!-- <el-table-column prop="electrolyte" label="电解质"> </el-table-column> -->
        <el-table-column prop="hemagglutination" label="血凝">
        </el-table-column>
        <el-table-column prop="other_results" label="其他"> </el-table-column>
        <el-table-column prop="b_preparation" label="肠道准备">
        </el-table-column>
        <el-table-column prop="catheterization" label="导尿"> </el-table-column>
        <el-table-column prop="antibiotics" label="预防性抗菌素" width="140">
        </el-table-column>
        <el-table-column prop="anesthesia" label="麻醉方式"> </el-table-column>
        <el-table-column
          prop="surgical_approach.shoushufang"
          label="手术方式"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="implant_patch_name"
          label="植入补片名称"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="implanted_patch_model"
          label="植入补片型号"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="implant_patch_size"
          label="植入补片尺寸(cm)"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="implantation_patch_cost"
          label="植入补片费用(元)"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="about_drainage" label="有无引流">
        </el-table-column>
        <el-table-column prop="bleeding_volume" label="出血量(ml)" width="120">
        </el-table-column>
        <el-table-column label="手术时间" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.xiaos }}小时{{ scope.row.fenz1 }}分钟</span>
          </template>
        </el-table-column>
        <el-table-column prop="surgeon_information[0].tiwen" label="主刀">
        </el-table-column>
        <el-table-column prop="surgeon_information[0].zhuzhi" label="主刀职称">
        </el-table-column>
        <el-table-column prop="surgeon_information[0].xueya" label="一助">
        </el-table-column>
        <el-table-column prop="surgeon_information[0].yizhi" label="一助职称">
        </el-table-column>
        <el-table-column prop="surgeon_information[0].maibo" label="二助">
        </el-table-column>
        <el-table-column prop="surgeon_information[0].erzhi" label="二助职称">
        </el-table-column>
        <el-table-column label="术后生命体征" width="150">
          <template slot-scope="scope">
            <el-button @click="checkDetails(scope.row.id)">查看详情</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="urination_time"
          label="术后下床时间(天)"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="defecation_time"
          label="术后自行解尿时间(天)"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="defecation_time_after"
          label="术后排气排便时间(天)"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="antibacterial_time"
          label="抗菌素使用时间(天)"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="catheter_removal_time"
          label="导尿管拔除时间(天)"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="drainage_tube_removal_time"
          label="引流管拔除时间(天)"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="suture_removal_time"
          label="伤口拆线时间(天)"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="postoperative_complications"
          label="术后并发症"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="wound_healing.shangeyu"
          label="伤口愈合情况"
          width="160"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.wound_healing.shangeyu }}</span>
            <span>{{ scope.row.wound_healing.selectjia }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button @click="editItem(scope.row.id)">住院手术病例</el-button>
            <el-button class="deletebtn" @click="shanItem(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="botton_page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15, 20, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="num"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-main v-if="defaultActive == '2'">
      <div class="top_form">
        <el-form :model="form">
          <el-form-item>
            <el-input
              v-model="form.age"
              placeholder="请输入年龄进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.sex" placeholder="请选择性别进行搜索">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.hospital"
              placeholder="请选择所在医院进行搜索"
            >
              <el-option label="大华医院" value="大华医院"></el-option>
              <el-option label="徐汇中心医院" value="徐汇中心医院"></el-option>
              <el-option label="第八人民医院" value="第八人民医院"></el-option>
              <el-option label="社区卫生服务中心" value="社区卫生服务中心"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.discharge"
              placeholder="请输入住院天数进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.hospital_costs"
              placeholder="请输入住院总费用进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.diagnosis"
              placeholder="请选择诊断进行搜索"
            >
              <el-option label="斜疝" value="斜疝"></el-option>
              <el-option label="直疝" value="直疝"></el-option>
              <el-option label="股疝" value="股疝"></el-option>
              <el-option label="复合疝" value="复合疝"></el-option>
              <el-option label="脐疝" value="脐疝"></el-option>
              <el-option label="切口疝" value="切口疝"></el-option>
              <el-option label="白线疝" value="白线疝"></el-option>
              <el-option label="半月线疝" value="半月线疝"></el-option>
              <el-option label="腰疝" value="腰疝"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.location" placeholder="请选择部位进行搜索">
              <el-option label="左" value="左"></el-option>
              <el-option label="右" value="右"></el-option>
              <el-option label="双侧" value="双侧"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.hernia" placeholder="请选择疝分类进行搜索">
              <el-option label="易复性疝" value="易复性疝"></el-option>
              <el-option label="难复性疝" value="难复性疝"></el-option>
              <el-option label="嵌顿性疝" value="嵌顿性疝"></el-option>
              <el-option label="绞窄性疝" value="绞窄性疝"></el-option>
              <el-option label="Richter疝" value="Richter疝"></el-option>
              <el-option label="Littre疝" value="Littre疝"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.hernia_type"
              placeholder="请选择疝分型进行搜索"
            >
              <el-option label="I型" value="I型"></el-option>
              <el-option label="Ⅱ型 " value="Ⅱ型 "></el-option>
              <el-option label="Ⅲ型" value="Ⅲ型"></el-option>
              <el-option label="Ⅳ型" value="Ⅳ型"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.surgical_approach"
              placeholder="请选择手术方式进行搜索"
            >
              <el-option label="传统手术" value="传统手术"></el-option>
              <el-option label="无张力修补术" value="无张力修补术"></el-option>
              <el-option label="腔镜手术" value="腔镜手术"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.implant_patch_name"
              placeholder="请输入植入补片名称进行搜索"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.postoperative_complications"
              placeholder="请输入术后并发症进行搜索"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="top_right">
          <el-button type="info" plain @click="reset">重置</el-button>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button type="primary" class="query_btn" @click="exportSui"
            >导出</el-button
          >
        </div>
      </div>
      <el-table
        :data="resData"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column prop="sex" label="性别"> </el-table-column>
        <el-table-column prop="profession" label="职业"> </el-table-column>
        <el-table-column prop="country" label="国籍"> </el-table-column>
        <el-table-column prop="nationality" label="民族"> </el-table-column>
        <el-table-column prop="hometown" label="籍贯"> </el-table-column>
        <el-table-column prop="card" label="身份证号" width="200">
        </el-table-column>
        <el-table-column prop="phone" label="电话号码" width="150">
        </el-table-column>
        <el-table-column prop="tel" label="手机号码" width="150">
        </el-table-column>
        <el-table-column prop="hospital" label="所在医院" width="120">
        </el-table-column>
        <el-table-column prop="number" label="住院号"> </el-table-column>
        <el-table-column prop="department" label="科室"> </el-table-column>
        <el-table-column prop="ssrqi" label="手术日期" width="130">
        </el-table-column>
        <el-table-column prop="fzrq" label="复诊日期" width="130">
        </el-table-column>
        <el-table-column prop="discharge" label="住院天数(天)" width="120">
        </el-table-column>
        <el-table-column
          prop="follow_up_interval"
          label="随访时间间隔"
          width="130"
        >
        </el-table-column>
        <el-table-column prop="fever" label="有无发热"> </el-table-column>
        <el-table-column prop="pain_score_vas" label="疼痛评分VAS" width="130">
        </el-table-column>
        <el-table-column label="术后伤口愈合情况" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.wound_healing2.editableyuval }}</span>
            <span>{{ scope.row.wound_healing2.dengyuheVal }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="complications_2" label="术后有无并发症">
        </el-table-column>
        <el-table-column prop="recurrence" label="术后有无复发">
        </el-table-column>
        <!-- <el-table-column prop="blood_routine_2" label="术后血常规" width="120">
        </el-table-column>
        <el-table-column prop="urine_routine1" label="术后尿常规" width="120">
        </el-table-column>
        <el-table-column prop="fecalroutin_2" label="术后粪常规">
        </el-table-column>
        <el-table-column prop="item.blood_sugar_2" label="术后血糖" width="130">
        </el-table-column>
        <el-table-column prop="liver_function_2" label="术后肝功能">
        </el-table-column>
        <el-table-column prop="kidney_function_2 " label="术后肾功能">
        </el-table-column>
        <el-table-column prop="electrolyte_2" label="术后电解质">
        </el-table-column>
        <el-table-column prop="hemagglutination_2" label="术后血凝">
        </el-table-column> -->
        <el-table-column prop="is_follow_up" label="是否失访">
        </el-table-column>
        <el-table-column prop="pressure" label="操作" width="250">
          <template slot-scope="scope">
            <el-button @click="menzhen(scope.row.id)">门诊随访病例</el-button>
            <el-button class="deletebtn" @click="shanItem(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="botton_page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15, 20, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="num"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-main
      v-if="defaultActive == '3'"
      style="line-height:0;padding-left:0;padding-bottom:0;"
    >
      <InpatientOperation :id="id" :currentWatch="currentWatch" />
    </el-main>
    <el-main v-if="defaultActive == '4'" style="line-height:0;padding-left:0">
      <Suifang :suiId="suiId" />
    </el-main>
  </div>
</template>

<script>
import InpatientOperation from "@/components/inpatientOperation";
import Suifang from "@/components/suifang";
export default {
  name: "MyAppDoc",
  components: { InpatientOperation, Suifang },
  data() {
    return {
      id: "", // 住院病例id
      suiId: "", // 随访病例id
      baseUrl: "https://api.dahuayoushanyun.com",
      defaultActive: "1",
      form: {
        age: "", // 年龄
        sex: "", // 性别
        hospital: "", // 年龄
        discharge: "", // 住院天数
        hospital_costs: "", // 费用
        diagnosis: "", // 诊断
        location: "", // 部位
        hernia: "", // 疝分类
        hernia_type: "", // 疝分型
        surgical_approach: "", // 手术方式
        implant_patch_name: "", // 植入补片名称
        postoperative_complications: "", // 术后并发症
      },
      resData: [], // 住院病例信息列表
      pageSize: 10,
      currentPage4: 1,
      num: 0,
      pageTotal: 0,
      pageNumtwo: 1,
      surgery_time: [],
      type: 1,
      defaultActiveIndex: "0",
      currentWatch:0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#FAFAFA;text-align:center";
    },
    rowStyle() {
      return "text-align:center;";
    },
    selectNav(index) {
      this.defaultActive = index;
      console.log(this.defaultActive, "收到是哦");
      if (this.defaultActive == "1") {
        this.type = 1;
        this.getTableData();
        this.id = "";
        this.suiId = "";
        this.form.age = "";
        this.form.sex = "";
        this.form.hospital = "";
        this.form.discharge = "";
        this.form.hospital_costs = "";
        this.form.diagnosis = "";
        this.form.location = "";
        this.form.hernia = "";
        this.form.hernia_type = "";
        this.form.postoperative_complications = "";
        this.form.surgical_approach = "";
        this.form.implant_patch_name = "";
      } else if (this.defaultActive == "2") {
        this.type = 2;
        this.getTableData();
        this.id = "";
        this.suiId = "";
        this.form.age = "";
        this.form.sex = "";
        this.form.hospital = "";
        this.form.discharge = "";
        this.form.hospital_costs = "";
        this.form.diagnosis = "";
        this.form.location = "";
        this.form.hernia = "";
        this.form.hernia_type = "";
        this.form.postoperative_complications = "";
        this.form.surgical_approach = "";
        this.form.implant_patch_name = "";
      } else if (this.defaultActive == "3") {
        this.id = "";
        this.suiId = "";
        console.log(this.defaultActiveIndex, "wwww");
      } else {
        this.id = "";
        this.suiId = "";
      }
    },
    // 住院病历信息
    zhuyuan() {
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if(this.form.age == "" && this.form.sex == "" && this.form.hospital == "" && this.form.discharge == "" &&
      this.form.hospital_costs == "" &&
      this.form.diagnosis == "" &&
      this.form.location == "" &&
      this.form.hernia == "" &&
      this.form.hernia_type == "" &&
      this.form.postoperative_complications == ""&&
      this.form.surgical_approach == "" &&
      this.form.implant_patch_name == ""){
        this.getTableData();
      }else {
        this.query()
      }
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      if(this.form.age == "" && this.form.sex == "" && this.form.hospital == "" && this.form.discharge == "" &&
      this.form.hospital_costs == "" &&
      this.form.diagnosis == "" &&
      this.form.location == "" &&
      this.form.hernia == "" &&
      this.form.hernia_type == "" &&
      this.form.postoperative_complications == ""&&
      this.form.surgical_approach == "" &&
      this.form.implant_patch_name == ""){
        this.getTableData();
      }else {
        this.query()
      }
    },
    getTableData() {
      // this.currentPage4 = 1
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;

      // this.pageNumtwo = i || this.pageNumtwo;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/yList?user_access_token=${token}&page=${this.pageSize}&page_num=${this.currentPage4}&type=${this.type}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        let resData = res.data.data.recruitmentList;
        if (res.data.status == 1) {
          this.surgery_time = resData.surgery_time;
          this.resData = resData;
          this.pageTotal = res.data.data.total_page;
          this.num = Number(res.data.data.num);
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 查询住院病历信息
    query() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      // this.pageNumtwo = i || this.pageNumtwo;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/yList?user_access_token=${token}&age=${this.form.age}&sex=${this.form.sex}&hospital=${this.form.hospital}&discharge=${this.form.discharge}&hospital_costs=${this.form.hospital_costs}&diagnosis=${this.form.diagnosis}&location=${this.form.location}&hernia=${this.form.hernia}&hernia_type=${this.form.hernia_type}&postoperative_complications=${this.form.postoperative_complications}&surgical_approach=${this.form.surgical_approach}&implant_patch_name=${this.form.implant_patch_name}&page=${this.pageSize}&page_num=${this.currentPage4}&type=${this.type}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        let resData = res.data.data.recruitmentList;
        if (res.data.status == 1) {
          this.surgery_time = resData.surgery_time;
          this.resData = resData;
          this.pageTotal = res.data.data.total_page;
          this.num = Number(res.data.data.num);
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 删除
    shanItem(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("setUserToken");
        let token = this.$store.getters.setToken;
        this.$axios({
          method: "POST",
          url: `${this.baseUrl}/Api/Applets/delete?user_access_token=${token}`,
          data: {
            list: [
              {
                id: id,
              },
            ],
          },
          headers: { "content-type": "application/x-www-form-urlencoded" },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
            if (this.defaultActive == "1") {
              this.type = 1;
            } else if (this.defaultActive == "2") {
              this.type == 2;
            }
            this.getTableData();
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
    // 重置
    reset() {
      this.form.age = "";
      this.form.sex = "";
      this.form.hospital = "";
      this.form.discharge = "";
      this.form.hospital_costs = "";
      this.form.diagnosis = "";
      this.form.location = "";
      this.form.hernia = "";
      this.form.hernia_type = "";
      this.form.postoperative_complications = "";
      this.form.surgical_approach = "";
      this.form.implant_patch_name = "";
      if (this.defaultActive == "1") {
        this.type = 1;
      } else if (this.defaultActive == "2") {
        this.type == 2;
      }
      this.getTableData();
    },
    // 查看住院手术病例
    editItem(id) {
      this.defaultActive = "3";
      this.id = id;
      this.currentWatch = 1
    },
    // 查看随访手术病例
    menzhen(id) {
      this.defaultActive = "4";
      this.suiId = id;
    },
    // 查看心电图
    CheckXin(id) {
      this.defaultActive = "3";
      this.id = id;
      this.currentWatch = 3;
    },
    CheckXiong(id) {
      this.defaultActive = "3";
      this.id = id;
      this.currentWatch = 3;
    },
    CheckBt(id) {
      this.defaultActive = "3";
      this.id = id;
      this.currentWatch = 3;
    },
    CheckCT(id) {
      this.defaultActive = "3";
      this.id = id;
      this.currentWatch = 3;
    },
    checkDetails(id) {
      this.defaultActive = "3";
      this.id = id;
      this.currentWatch = 6
    },
    // 导出住院病历
    exportZhu(){
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      window.location.href = `${this.baseUrl}/Api/Applets/downloadForm?user_access_token=${token}&age=${this.form.age}&sex=${this.form.sex}&hospital=${this.form.hospital}&discharge=${this.form.discharge}&hospital_costs=${this.form.hospital_costs}&diagnosis=${this.form.diagnosis}&location=${this.form.location}&hernia=${this.form.hernia}&hernia_type=${this.form.hernia_type}&postoperative_complications=${this.form.postoperative_complications}&surgical_approach=${this.form.surgical_approach}&implant_patch_name=${this.form.implant_patch_name}&type=${this.type}`
      // this.$axios({
      //   method: "GET",
      //   url: `${this.baseUrl}/Api/Applets/downloadForm?user_access_token=${token}&age=${this.form.age}&sex=${this.form.sex}&hospital=${this.form.hospital}&discharge=${this.form.discharge}&hospital_costs=${this.form.hospital_costs}&diagnosis=${this.form.diagnosis}&location=${this.form.location}&hernia=${this.form.hernia}&hernia_type=${this.form.hernia_type}&postoperative_complications=${this.form.postoperative_complications}&surgical_approach=${this.form.surgical_approach}&implant_patch_name=${this.form.implant_patch_name}&type=${this.type}`,
      //   headers: { "content-type": "application/json;charset=utf8" },
      // }).then((res) => {
      //   console.log(res,'但是熬好i')
      //   // let resData = res.data.data.recruitmentList;
      //   // if (res.data.status == 1) {
      //   //   this.$message({
      //   //     showClose: true,
      //   //     message: res.data.msg,
      //   //     type: "success",
      //   //   });
      //   // } else {
      //   //   this.$message({
      //   //     showClose: true,
      //   //     message: res.data.msg,
      //   //     type: "error",
      //   //   });
      //   // }
      // });
    },
    // 导出随访病例
    exportSui(){
       this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      window.location.href = `${this.baseUrl}/Api/Applets/downloadForm?user_access_token=${token}&age=${this.form.age}&sex=${this.form.sex}&hospital=${this.form.hospital}&discharge=${this.form.discharge}&hospital_costs=${this.form.hospital_costs}&diagnosis=${this.form.diagnosis}&location=${this.form.location}&hernia=${this.form.hernia}&hernia_type=${this.form.hernia_type}&postoperative_complications=${this.form.postoperative_complications}&surgical_approach=${this.form.surgical_approach}&implant_patch_name=${this.form.implant_patch_name}&type=${this.type}`
    }
  },
  mounted() {
    this.$emit("handleTab", 0);
    this.getTableData();
  },
};
</script>

<style>
html {
  height: 100%;
  position: fixed;
}
</style>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.hospitalTable-box {
  width: 100%;
  height: 100vh;
  position: sticky;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  // background: url('./assets/bju.png') no-repeat center / cover;
}

.el-menu-item {
  font-size: rem(16) !important;
}

.el-aside {
  height 100%;
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 160px;
}

.top_form {
  width: 100%;
  display: flex;
}

.top_form .el-form {
  display: flex;
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top_form  .el-input__inner {
  width: rem(290) !important;
  height: rem(45) !important;
}

.top_form .el-input__inner::placeholder {
  font-size: rem(15);
}

.top_right {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top_right .el-button {
  width: rem(100);
  margin-bottom: rem(10);
  border: none;
}

.el-button+.el-button {
  margin-left: 0 !important;

}

.el-button+.el-button:hover {
  background: #409EFF;
}

.el-button--info.is-plain:hover,.el-button--info.is-plain:focus {
  background: #f4f4f5 !important;
  color: #909399 !important;
  border: none;
}
.el-table {
  width: 100%;
}

.botton_page {
  max-width: rem(1600);
  position: relative;
}

.el-pagination {
  position: absolute;
  right: 0;
}

.botton_page .el-input__inner(1) {
  width: rem(150) !important;
}

.el-button+.el-button {
  margin-left: rem(10) !important;
}

.el-button+.el-button:hover {
  background: #fff;
}
.el-table th.el-table__cell {
  padding: 0 !important;
}
.top_form .el-button+.el-button:hover {
  background: #409EFF;
}
.el-main {
  height:90vh;
  overflow-x:hidden;
}
</style>
<style>
.btnClass {
  background-color: #409eff !important;
}
</style>
