<template>
  <div class="userInfoOne">
    <div class="userCon">
      <p class="userCon-title">完善个人信息</p>
      <div class="user-deil">
        <div>
          <span>真实姓名</span>
          <input type="text" placeholder="请输入真实姓名" v-model="UserName" />
        </div>
        <div>
          <span>性别</span>
          <p class="p">
            <img :src="[sexValue == '男' ? nan2 : nan1]" alt="" srcset="" />
            <i>男</i>
          </p>
          <p class="p">
            <img :src="[sexValue == '女' ? nv2 : nv1]" alt="" srcset="" />
            <i>女</i>
          </p>
        </div>
        <div>
          <span>所在地区</span>
          <input type="text" placeholder="请选择所在地区" v-model="dz" />
        </div>
        <div>
          <span>详细地址</span>
          <input type="text" placeholder="请输入详细地址" v-model="deilsdata" />
        </div>
        <button class="submit2">审核中</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyAppFirst",
  data() {
    return {
      UserName: "",
      deilsdata: "",
      sexValue: "",
      dz: "",
      dynamic: -1,
      baseUrl: "https://api.dahuayoushanyun.com",
      nan1: require("./assets/nan.png"),
      nan2: require("./assets/nan2.png"),
      nv1: require("./assets/nv.png"),
      nv2: require("./assets/nv2.png"),
    };
  },
  components: {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    getUserInfo() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "get",
        url: `${this.baseUrl}/Api/User/userOauth1?user_access_token=${token}`,

        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.sexValue = res.data.data.sex1;
          this.dz = res.data.data.area;
          this.UserName = res.data.data.name;
          this.deilsdata = res.data.data.address;
          if (res.data.data.status == 1) {
            this.$router.push("/userInfo");
          } else if (res.data.data.status == 3) {
            this.$router.push("/UserInfoBo");
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.userInfoOne {
  width: 100%;
  height: 100vh;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('./assets/bju.png') no-repeat center / cover;
}

.userCon {
  width: rem(700);
  height: rem(600);
  background: #FFFFFF;
  border-radius: rem(20);
  position: absolute;
  top: 20%;
  left: 35%;
}

.userCon-title {
  position: absolute;
  font-size: rem(36);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0473C2;
  line-height: rem(50);
  left: rem(248);
  top: rem(0);
}

.user-deil {
  width: rem(626);
  height: rem(400);
  position: absolute;
  bottom: rem(66);
  left: rem(38);
  display: flex;
  flex-direction: column;
}

.user-deil div {
  width: 100%;
  height: rem(50);
  display: flex;
  margin-top: rem(20);
  justify-content: space-around;
}

.user-deil div span {
  width: rem(96);
  font-size: rem(24);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6B6B6B;
  line-height: rem(33);
}

.user-deil div input {
  width: rem(400);
  height: rem(50);
  background: #F2F2F2;
  border-radius: rem(4);
  border: 1px solid #F2F2F2;
  outline: none;
  font-size: rem(18);
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ABABAB;
  line-height: erm(25);
  text-indent: rem(40);
}

.user-deil div p {
  width: rem(100);
  height: rem(50);
  position: relative;
  left: rem(-60);
  border-radius: rem(4);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.user-deil div p i {
  width: rem(18);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6B6B6B;
  line-height: rem(45);
  font-style: normal;
  margin-left: rem(20);
}

.user-deil div p img {
  width: rem(38);
  height: rem(38);
  position: relative;
  top: rem(5);
}

.submit2 {
  width: rem(526);
  height: rem(60);
  background: #6F92AA;
  box-shadow: 1px 1px rem(4) 0px rgba(4, 115, 194, 0.7);
  border-radius: rem(4);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: rem(60);
  border: none;
  margin-top: rem(50);
  margin-left: rem(50);
}
</style>
