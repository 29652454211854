<template>
  <div class="hospitalTable">
    <el-aside width="12vw">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @select="selectNav"
      >
        <el-menu-item index="1">
          <span>所有</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span>未开始</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span>审核中</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span>驳回</span>
        </el-menu-item>
        <el-menu-item index="5">
          <span>审核成功</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main style="padding: 1vh 2vw">
      <div class="form_top">
        <el-form :model="form">
          <el-form-item label="姓名：">
            <el-input
              v-model="form.name"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="申请状态：">
            <el-select v-model="form.status" placeholder="请选择申请状态">
              <el-option label="未开始" value="未开始"></el-option>
              <el-option label="审核中" value="审核中"></el-option>
              <el-option label="驳回" value="驳回"></el-option>
              <el-option label="审核成功" value="审核成功"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始结束时间：">
            <el-date-picker
              v-model="form.checkvalue"
              format="yyyy-MM-dd HH:mm"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button type="primary" @click="searchDataBtn">查询</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
      <el-table
        :data="tableList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column prop="name" label="申请人"></el-table-column>
        <el-table-column prop="phone" label="联系电话"></el-table-column>
        <el-table-column prop="time" label="申请时间"></el-table-column>
        <el-table-column label="申请状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">审核成功</span>
            <span v-if="scope.row.status == 2">审核中</span>
            <span v-if="scope.row.status == 3">审核失败</span>
            <span v-if="scope.row.status == 0">未开始</span>
          </template>
        </el-table-column>
        <el-table-column prop="b_reason" label="说明"></el-table-column>
        <el-table-column label="操作">
          <template>
            <el-button type="text">暂无操作</el-button>
            <el-button type="text">暂无操作</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="num"
      >
      </el-pagination>
    </el-main>
  </div>
</template>

<script>
export default {
  name: "MyAppShenhe",

  data() {
    return {
      defaultActive: "1",
      value4: "",
      baseUrl: "https://api.dahuayoushanyun.com",
      itemId: "",
      pageNum: "",
      pageSize: 5,
      pagesize: 3,
      pageTotal: 0,
      pageNumtwo: 1,
      num: 0,
      currentPage: 0,
      status: "",
      phone: "",
      form: {
        name: "",
        status: "",
        checkvalue: [],
      },
      tableList: [],
    };
  },

  mounted() {
    this.$emit("handleTab", 1);
    if (this.defaultActive == "2") {
        this.status = '0';
        this.form.status = "未开始";
        this.searchData();
      } else if (this.defaultActive == 3) {
        this.status = '2';
        this.form.status = "审核中";
        this.searchData();
      } else if (this.defaultActive == 4) {
        this.status = '3';
        this.form.status = "驳回";
        this.searchData();
      } else if (this.defaultActive == 5) {
        this.status = '1';
        this.form.status = "审核成功";
        this.searchData();
      } else {
        this.getShenHeTable();
      }
  },

  methods: {
    headerStyle() {
      return "background:#FAFAFA;text-align:center";
    },
    rowStyle() {
      return "text-align:center;";
    },
    selectNav(index) {
      this.defaultActive = index;
      if (this.defaultActive == "2") {
        this.status = '0';
        this.form.status = "未开始";
        this.searchData();
      } else if (this.defaultActive == 3) {
        this.status = '2';
        this.form.status = "审核中";
        this.searchData();
      } else if (this.defaultActive == 4) {
        this.status = '3';
        this.form.status = "驳回";
        this.searchData();
      } else if (this.defaultActive == 5) {
        this.status = '1';
        this.form.status = "审核成功";
        this.searchData(this.pageNum);
      } else {
        this.form.status = ''
        this.getShenHeTable();
      }
    },
    // 日期格式化
    /* 将时间戳格式化为yyyy-MM-dd hh:mm:ss格式，其它格式可自行更改 */
    formatTimeMills(timeMills) {
      var date = new Date(timeMills);
      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    searchDataBtn() {
      let time = ''
      let time1 = ''
      if (this.form.checkvalue.length != 0) {
        time = this.formatTimeMills(this.form.checkvalue[0]);
        time1 = this.formatTimeMills(this.form.checkvalue[1]);
      }

      if (this.form.status == "审核中") {
        this.status = '2';
      } else if (this.form.status == "驳回") {
        this.status = '3';
      } else if (this.form.status == "审核成功") {
        this.status = '1';
      } else if (this.form.status == "未开始") {
        this.status = '0';
      }
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/userList?user_access_token=${token}&page=${this.pageSize}&page_num=${this.pageNumtwo}&status=${this.status}&name=${this.form.name}&time=${time}&time1=${time1}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.pageTotal = res.data.data.total_page;
          this.num = Number(res.data.data.num);
          this.tableList = res.data.data.recruitmentList;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    searchData(i) {
      this.pageNumtwo = i || this.pageNumtwo
      let time = ''
      let time1 = ''
      if (this.form.checkvalue.length != 0) {
        time = this.formatTimeMills(this.form.checkvalue[0]);
        time1 = this.formatTimeMills(this.form.checkvalue[1]);
      }

      if (this.form.status == "审核中") {
        this.status = '2';
      } else if (this.form.status == "驳回") {
        this.status = '3';
      } else if (this.form.status == "审核成功") {
        this.status = '1';
      } else if (this.form.status == "未开始") {
        this.status = '0';
      }
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/userList?user_access_token=${token}&page=${this.pageSize}&page_num=${this.pageNumtwo}&status=${this.status}&name=${this.form.name}&time=${time}&time1=${time1}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.pageTotal = res.data.data.total_page;
          this.num = Number(res.data.data.num);
          this.tableList = res.data.data.recruitmentList;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    reset() {
      this.form.name = "";
      this.form.status = "";
      this.form.checkvalue = "";
      if (this.defaultActive == "2") {
        this.status = '0';
        this.form.status = "未开始";
        this.searchData();
      } else if (this.defaultActive == 3) {
        this.status = '2';
        this.form.status = "审核中";
        this.searchData();
      } else if (this.defaultActive == 4) {
        this.status = '3';
        this.form.status = "驳回";
        this.searchData();
      } else if (this.defaultActive == 5) {
        this.status = '1';
        this.form.status = "审核成功";
        this.searchData();
      } else {
        this.getShenHeTable();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.defaultActive == "2") {
        this.status = '0';
        this.form.status = "未开始";
        this.searchData();
      } else if (this.defaultActive == 3) {
        this.status = '2';
        this.form.status = "审核中";
        this.searchData();
      } else if (this.defaultActive == 4) {
        this.status = '3';
        this.form.status = "驳回";
        this.searchData();
      } else if (this.defaultActive == 5) {
        this.status = '1';
        this.form.status = "审核成功";
        this.searchData();
      } else {
        this.getShenHeTable();
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (this.defaultActive == "2") {
        this.status = '0';
        this.form.status = "未开始";
        this.searchData(this.pageNum);
      } else if (this.defaultActive == 3) {
        this.status = '2';
        this.form.status = "审核中";
        this.searchData(this.pageNum);
      } else if (this.defaultActive == 4) {
        this.status = '3';
        this.form.status = "驳回";
        this.searchData(this.pageNum);
      } else if (this.defaultActive == 5) {
        this.status = '1';
        this.form.status = "审核成功";
        this.searchData(this.pageNum);
      } else {
        this.getShenHeTable(this.pageNum);
      }
    },
    agreePass(id) {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/success1?user_access_token=${token}&id=${id}&status=1`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    getShenHeTable(i) {
      this.pageNumtwo = i || this.pageNumtwo;
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/userList?user_access_token=${token}&&page=${this.pageSize}&page_num=${this.pageNumtwo}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        this.pageTotal = res.data.data.total_page;
        this.num = Number(res.data.data.num);
        this.tableList = res.data.data.recruitmentList;
      });
    },
    goFirst() {
      this.$router.push("/DocHou");
    },
    shenhe() {
      this.$router.push("/ShenHe");
    },
    BoItem(id) {
      let NoContent = document.querySelector("#No-content");
      NoContent.className = "NoAgree";
      this.itemId = id;
    },
    cuowu() {
      let NoContent = document.querySelector("#No-content");
      NoContent.className = "NoAgreeDis";
    },
    subBtn() {
      let NoContent = document.querySelector("#No-content");
      NoContent.className = "NoAgreeDis";
      let bohuiReson = this.$(".bohuiReson").val();
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/Applets/turn?user_access_token=${token}&id=${this.itemId}&status=3&b_reason=${bohuiReson}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    Logout() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/User/signOut?user_access_token=${token}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        let resData = res.data;
        if (resData.status == 1) {
          localStorage.clear();
          this.$router.push("/");
          this.$message({
            showClose: true,
            message: "退出成功",
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.el-menu-item {
  font-size: rem(16) !important;
}
.form_top {
  width: 100%;
  display:flex;
  // border 1px solid red
  margin: 0 0;
  padding: 0 0;
  margin-bottom: 2vh;
}

.form_top .el-form-item {
  display:flex
}

.form_top .el-form,.form_top {
  display: flex;
}
</style>
