import Vue from "vue"
import VueRouter  from "vue-router"
import MyAppFirst from "../First.vue"
import CheckUser from "../CheckUser"
import ShowLu from "../ShowLu"
import DocHou from "../Doc.vue"
import ShenHe from "../Shenhe"
import userInforUp from "../UserInfoOne"
import UserInfoShen from "../UserInfoTwo"
import UserInfoBo from "../UserInfoThree"
import UserInfo from "../UserInfo"
import Menzhen from "../Menzhen"
import Suifang from "../Suifang"
import Register from "../Register"
import Literature from '../Literature'
import Message from '../Message'
Vue.use(VueRouter)

const routes=[
    {
        path:"/",
        name:"MyAppFirst" ,
        component:MyAppFirst
    },
    {
        path:"/CheckUser",
        name:"CheckUser",
        component:CheckUser
    },
      {
        path:"/DocHou",
        name:"DocHou",
        component:DocHou
    },
    {
        path:"/ShenHe",
        name:"ShenHe",
        component:ShenHe
    },
    {
        path:"/ShowLu",
        name:"ShowLu",
        component:ShowLu
    },
    {
        path:"/UserInfo",
        name:"UserInfo",
        component:UserInfo
    },
    {
        path:"/userInforUp",
        name:"userInforUp",
        component:userInforUp
    },
    {
        path:"/Menzhen",
        name:"Menzhen",
        component:Menzhen
    },
    {
        path:"/UserInfoShen",
        name:"UserInfoShen",
        component:UserInfoShen
    },
    {
        path:"/UserInfoBo",
        name:"UserInfoBo",
        component:UserInfoBo
    },
    {
        path:"/Suifang",
        name:"Suifang",
        component:Suifang
    },
    {
        path:"/Register",
        name:"Register",
        component:Register
    },
    {
        path:"/Literature",
        name:"Literature",
        component:Literature
    },
    {
        path:"/Message",
        name:"Message",
        component:Message
    },

]
const router=new VueRouter({
    routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach(function(to, from, next) {

    if (!localStorage.getItem("user_access_token")) {
        if (to.path !== '/') {
            return next('/')
        }
    }
    next()
})
export default router

// 路由拦截




