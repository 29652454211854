<template>
  <div class="CheckUser">
    <div class="check-lun">
      <button
        style="position: absolute; z-index: 100000"
        @click="youchang"
        class="left"
      >
        <span> &lt;</span>
      </button>
      <button
        style="position: absolute; z-index: 100000"
        @click="change"
        class="you"
      >
        <span> &gt; </span>
      </button>
      <div class="check-box" :style="{ marginLeft: marginLeft + 'rem' }">
        <div class="firstq-Show">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="firstq-deils">
            <div class="firstq-hang">
              <p>
                <span>姓名</span>
                <input type="text" v-model="name" name="" />
              </p>
              <p>
                <span>年龄</span>
                <input type="text" v-model="userYear" name="" />
              </p>
              <p>
                <span>性别</span>
                <input
                  v-model="valuesex"
                  id="userSex"
                  list="options14"
                  placeholder="请选择"
                />
                <datalist id="options14">
                  <option value="男">男</option>
                  <option value="女">女</option>
                </datalist>
              </p>
              <p>
                <span>籍贯</span>
                <input type="text" v-model="UserJi" name="" />
              </p>
            </div>
            <div class="firstq-hang">
              <p>
                <span>职业</span>
                <input type="text" v-model="userjob" name="" />
              </p>
              <p>
                <span>国籍</span>
                <input type="text" v-model="UserGuo" name="" />
              </p>
              <p>
                <span>民族</span>
                <input type="text" v-model="UserMin" />
              </p>
            </div>
            <div class="thidq-hang">
              <p>
                <span>身份证号</span>
                <input type="text" v-model="UserID" name="" />
              </p>
              <p>
                <span>电话号码</span>
                <input type="text" v-model="UserDian" name="" />
              </p>
            </div>
            <div class="forthq-hang">
              <p>
                <span>手机号码</span>
                <input type="text" v-model="UserPhone" name="" />
              </p>
            </div>
            <div class="fifq-hang">
              <p>
                <span>所在医院</span>
                <input list="optionsyiyan" id="yiyuan" v-model="addressHos" />
                <datalist id="optionsyiyan">
                  <option value="大华医院">大华医院</option>
                  <option value="徐汇中心医院">徐汇中心医院</option>
                  <option value="第八人民医院">第八人民医院</option>
                </datalist>
              </p>
              <p>
                <span>住院号</span>
                <input type="text" v-model="zhuyuanhao" name="" />
              </p>
            </div>
            <div class="sixq-hang">
              <p>
                <span>科室</span>
                <input type="text" v-model="UserKe" name="" />
              </p>
              <div class="block1">
                <span class="chutiem">入院日期</span>
                <el-date-picker
                  v-model="value1"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="date"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="sevenq-hang">
              <p>
                <span>住院天数</span>
                <input type="text" v-model="zhuyuantian" name="" /><span>天</span>
              </p>
              <div class="block4">
                <span class="chutime">出院日期:</span>
                <el-date-picker v-model="value24" type="date"> </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="Secondq2-Show">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="twoq2-deils">
            <div class="twofirstq2-hang">
              <p>
                <span>住院费用</span>
                <input type="text" v-model="UserFee" name="" />
              </p>
              <p>
                <span>药费</span>
                <input type="text" v-model="UserMedicne" name="" />
              </p>
            </div>
            <div class="twofirstq2-hang">
              <p>
                <span>医疗耗材费</span>
                <input type="text" v-model="yiliaofee" name="" />
              </p>
            </div>
            <div class="thid2q2-hang">
              <p>
                <span>诊断</span>
                <input
                  type="text"
                  v-model="UserZhen"
                  list="zhenduan"
                  id="zhen"
                  placeholder="请输入诊断"
                />
                <datalist id="zhenduan">
                  <option value="斜疝">斜疝</option>
                  <option value="直疝">直疝</option>
                  <option value="股疝">股疝</option>
                  <option value="复合疝">复合疝</option>
                  <option value="脐疝">脐疝</option>
                  <option value="切口疝">切口疝</option>
                  <option value="白线疝">白线疝</option>
                  <option value="半月线疝">半月线疝</option>
                  <option value="腰疝">腰疝</option>
                </datalist>
              </p>
              <p>
                <span>部位</span>
                <input
                  list="options1"
                  v-model="buwei"
                  id="editable-select"
                  placeholder="请选择部位"
                />
                <datalist id="options1">
                  <option value="左">左</option>
                  <option value="右">右</option>
                  <option value="双侧">双侧</option>
                </datalist>
              </p>
            </div>
            <div class="forth2q2-hang">
              <p>
                <span>是否复发</span>
                <input list="optionshi" id="shifou" v-model="valueisFu" />
                <datalist id="optionshi">
                  <option value="是">是</option>
                  <option value="否">否</option>
                </datalist>
              </p>
              <div class="block2">
                <span class="fatime">发病时间:</span>
                <input type="text" v-model="value2"><span class="tian">天</span>
              </div>
            </div>
            <div class="fif222-2hang">
              <p>
                <span>主要症状</span>
                <input
                  type="text"
                  v-model="zhongkuai"
                  name=""
                  placeholder="请输入肿块大小"
                /><span class="tian2">cm</span>
                <input
                  list="options21"
                  id="shanfen1"
                  placeholder="请选择质地"
                  v-model="zhidi"
                />
                <datalist id="options21">
                  <option value="软">软</option>
                  <option value="硬">硬</option>
                  <option value="中">中</option>
                </datalist>
                <input
                  list="options22"
                  id="shanfen2"
                  v-model="yinglang"
                  placeholder="请选择是否进入阴囊"
                />
                <datalist id="options22">
                  <option value="是">是</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
                <input
                  list="options23"
                  v-model="huina"
                  id="shanfen3"
                  placeholder="请选择能否回纳"
                />
                <datalist id="options23">
                  <option value="能">能</option>
                  <option value="否">否</option>
                </datalist>
                  <input
                  list="optionsshan23"
                  v-model="tengtojng"
                  id="shanfen32"
                  placeholder="请选择是否疼痛"
                />
                <datalist id="optionsshan23">
                  <option value="是">是</option>
                  <option value="否">否</option>
                </datalist>
                <input
                  list="options25"
                  v-model="touguang"
                  id="shanfen5"
                  placeholder="请选择透光试验"
                />
                <datalist id="options25">
                  <option value="阳性">阳性</option>
                  <option value="阴性">阴性</option>
                </datalist>
              </p>
            </div>
            <div class="six2q2-hang">
              <p>
                <span>疝分类</span>
                <input
                  type="text"
                  v-model="UserShan"
                  name=""
                  list="options28"
                  id="shanfeng28"
                  placeholder="请输入疝分类"
                />
                <datalist id="options28">
                  <option value="易复性疝">易复性疝</option>
                  <option value="难复性疝">难复性疝</option>
                  <option value="嵌顿性疝">嵌顿性疝</option>
                  <option value="绞窄性疝">绞窄性疝</option>
                  <option value="Richter疝">Richter疝</option>
                  <option value="Littre疝">Littre疝</option>
                </datalist>
              </p>
              <p>
                <span>疝分型</span>
                <input list="options2" id="shanfen" v-model="shanxing" />
                <datalist id="options2">
                  <option value="I型">I型</option>
                  <option value="Ⅱ型">Ⅱ型</option>
                  <option value="Ⅲ型">Ⅲ型</option>
                  <option value="Ⅳ型">Ⅳ型</option>
                </datalist>
              </p>
            </div>
            <div class="seven2q2-hang">
              <p>
                <span>腹压增高因素</span>
                <input list="options3" id="fuyayuan" v-model="fuya" />
                <datalist id="options3">
                  <option value="重体力活动">重体力活动</option>
                  <option value="慢性咳嗽">慢性咳嗽</option>
                  <option value="腹水">腹水</option>
                  <option value="排尿困难">排尿困难</option>
                  <option value="便秘">便秘</option>
                  <option value="腹部手术">腹部手术</option>
                </datalist>
              </p>
              <p>
                <span>吸烟</span>
                <input
                  type="text"
                  v-model="xiyannian"
                  name=""
                  id="xiyang"
                  list="optionss3"
                  placeholder="请输入吸烟年限"
                />
                年
                <datalist id="optionss3">
                  <option value="是">是</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
              </p>
            </div>
            <div class="eight2q2-hang">
              <p>
                <span>饮酒</span>
                <input
                  type="text"
                  list="optionsws3"
                  v-model="yinjiunian"
                  id="yin"
                  placeholder="请输入饮酒年限"
                />
                年
                <datalist id="optionsws3">
                  <option value="是">是</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
              </p>
              <p>
                <span>其他</span>
                <input type="text" v-model="qitanian" name="" />
              </p>
            </div>
          </div>
        </div>
        <div class="third3-Show">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="third3-deils">
            <div class="thirdfirst3-hang">
              <p>
                <span>女性月经史</span>
                <input
                  type="text"
                  v-model="UserYue"
                  name=""
                  list="optionyue"
                  id="yue"
                  placeholder="请输入女性月经史"
                />
                <datalist id="optionyue">
                  <option value="月经规则">月经规则</option>
                </datalist>
              </p>
              <p>
                <span>婚育史</span>
                <input
                  type="text"
                  v-model="UserHun"
                  id="hunyun"
                  list="optionhun"
                  placeholder="请输入婚育史"
                />
                <datalist id="optionhun">
                  <option value="未婚">未婚</option>
                  <option value="未育">未育</option>
                  <option value="已婚">已婚</option>
                  <option value="育1子">育1子</option>
                  <option value="育1女">育1女</option>
                </datalist>
              </p>
            </div>
            <div class="thirdfirst3-hang">
              <p>
                <span>体温</span>
                <input type="text" v-model="UserTi" name="" /> ℃
              </p>
              <p>
                <span>血压</span>
                <input type="text" v-model="UserXue" name="" /> mmHg
              </p>
            </div>
            <div class="thid3-hang">
              <p>
                <span>脉搏</span>
                <input type="text" v-model="UserMai" name="" /> 次/分
              </p>
              <p>
                <span>身高</span>
                <input type="text" v-model="UserShen" name="" />cm
              </p>
            </div>
            <div class="forth23-hang">
              <p>
                <span>体重</span>
                <input type="text" v-model="UserTizhong" name="" />kg
              </p>
            </div>
            <div class="fif23-hang">
              <p>
                <span>BMI指数</span>
                <input type="text" v-model="UserBmi" name="" />
              </p>
              <p>
                <span>合并疾病</span>
                <input list="options4" id="heji" v-model="heji" />
                <datalist id="options4">
                  <option value="高血压">高血压</option>
                  <option value="糖尿病">糖尿病</option>
                  <option value="冠心病">冠心病</option>
                  <option value="肺气肿">肺气肿</option>
                  <option value="贫血">贫血</option>
                  <option value="前列腺增生">前列腺增生</option>
                </datalist>
              </p>
            </div>
            <div class="xin--dian3">
              <div class="first-xin">
                <span class="first-xintxt">心电图</span>
                <img class="xin" src="./assets/jiahao.png" />
                <p class="upxin-txt">上传心电图</p>
                <el-upload
                  class="el--upload11"
                  ref="uploadExcel11"
                  action="#"
                  :limit="limitNum11"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange11"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>
        <div class="three3-show">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="three-colim">
            <div class="xiong">
              <!-- 胸片 -->
              <div class="xinbc">
                <span class="xin2">胸片</span>
                <img class="xinB1" src="./assets/jiahao.png" />
                <p class="upBc1">上传胸片</p>
                <el-upload
                  class="el--upload22"
                  ref="uploadExcel22"
                  action="#"
                  :limit="limitNum22"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange22"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl2"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG2(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="bpic">
              <!-- B超 -->
              <div class="xinbc">
                <span class="xin2">B超</span>
                <img class="xinB1" src="./assets/jiahao.png" />
                <p class="upBc1">上传B超</p>
                <el-upload
                  class="el--upload33"
                  ref="uploadExcel33"
                  action="#"
                  :limit="limitNum33"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange33"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl3"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG3(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>

        <div class="forth4-Show">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="forth4-deils">
            <div class="forthfirstone4-hang">
              <div class="xin--bc">
                <span class="xin2">CT</span>
                <img class="xinB1" src="./assets/jiahao.png" />
                <p class="upBc1">上传CT</p>
                <el-upload
                  class="el--upload44"
                  ref="uploadExcel44"
                  action="#"
                  :limit="limitNum44"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange44"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl4"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG4(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="forthfirst4-hang">
              <p>
                <span>血常规</span>
                <input type="text" name="" v-model="Userxue" />
              </p>
              <p>
                <span>尿常规</span>
                <input type="text" v-model="UserNiao" name="" />
              </p>
            </div>
            <div class="thid4-hang">
              <p>
                <span>粪常规</span>
                <input type="text" v-model="UserFen" name="" />
              </p>
              <p>
                <span>血糖</span>
                <input type="text" v-model="UserXuetang" name="" />
              </p>
            </div>
            <div class="forth24-hang">
              <p>
                <span>肝功能</span>
                <input type="text" v-model="UserGan" name="" />
              </p>
              <p>
                <span>肾功能</span>
                <input type="text" v-model="UserShen2" name="" />
              </p>
            </div>
            <div class="fif24-hang">
              <p>
                <span>电解质</span>
                <input type="text" v-model="UserDianjie" name="" />
              </p>
              <p>
                <span>血凝</span>
                <input type="text" v-model="UserNing" name="" />
              </p>
            </div>

            <div class="seven24-hang">
              <p>
                <span>其他</span>
                <input type="text" v-model="Userqita" name="" />
              </p>
            </div>
          </div>
        </div>
        <div class="fif5-Show">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="fif5-deils">
            <div class="fif5-hang">
              <p>
                <span>肠道准备</span>
                <input type="text" v-model="changzhun" name="" />
              </p>
              <p>
                <span>导尿</span>
                <input type="text" v-model="daoniaozhun" name="" />
              </p>
            </div>
            <div class="fif5-hang">
              <p>
                <span>预防性抗菌素</span>
                <input type="text" v-model="kangyu" name="" />
              </p>
            </div>
            <div class="thid5-hang">
              <p>
                <span class="ma">麻醉方式</span>
                <input list="options5" id="mazuifang" v-model="mazui" />
                <datalist id="options5">
                  <option value="全麻">全麻</option>
                  <option value="腰麻">腰麻</option>
                  <option value="连硬">连硬</option>
                  <option value="局麻">局麻</option>
                </datalist>
              </p>
              <p>
                <span class="shou-fang">手术方式</span>
                <input
                  list="options6"
                  id="shoushufang"
                  v-model="arrshoushufang.shoushufang"
                />
                <datalist id="options6">
                  <option value="传统手术">传统手术</option>
                  <option value="无张力修补术">无张力修补术</option>
                  <option value="腔镜手术">腔镜手术</option>
                </datalist>

                <input
                  list="optionsdeil"
                  v-model="arrshoushufang.editabledeil"
                  id="editable-deil"
                />
                <datalist id="optionsdeil">
                  <option value="Bassini法">Bassini法</option>
                  <option value="无张力修补术">无张力修补术</option>
                  <option value="Ferguson法">Ferguson法</option>
                  <option value="Shouldice法">Shouldice法</option>
                  <option value="Halsted法">Halsted法</option>
                  <option value="单纯平片修补术 Lichtenstein手术">
                    单纯平片修补术 Lichtenstein手术
                  </option>
                  <option value="网塞-平片修补术 Rutkow手术">
                    网塞-平片修补术 Rutkow手术
                  </option>
                  <option value="腹膜前修补术Kugel">腹膜前修补术Kugel</option>
                  <option value="巨大补片加强内脏囊手术GPRVS">
                    巨大补片加强内脏囊手术GPRVS
                  </option>
                  <option value="Gilbert手术">Gilbert手术</option>
                  <option value="Stoppa手术">Stoppa手术</option>
                  <option value="全腹膜外修补术TEP">全腹膜外修补术TEP</option>
                  <option value="经腹腔腹膜前修补术TAPP">
                    经腹腔腹膜前修补术TAPP
                  </option>
                </datalist>
              </p>
            </div>
            <div class="forth5-hang">
              <span class="zhi">植入补片信息</span>
              <div class="zhiinput">
                <input type="text" class="zhiname" v-model="zhiname" />
                <input type="text" class="zhixing" v-model="zhixing" />
                <input type="text" class="zhichi" v-model="zhichi" />
                <input type="text" class="zhifee" v-model="zhifee" />
              </div>
            </div>
            <div class="fif25-hang">
              <p>
                <span>有无引流</span>
                <input
                  list="options225"
                  v-model="UserIn"
                  id="youyin"
                  placeholder="请选择有无引流"
                />
                <datalist id="options225">
                  <option value="有">有</option>
                  <option value="无">无</option>
                </datalist>
              </p>
              <p>
                <span>出血量</span>
                <input type="text" v-model="UserChu" name="" />ml
              </p>
            </div>
            <div class="six255-hang">
              <div class="block3">
                <span class="shoutime">手术时间:</span>

                <input type="text" v-model="xiaos" /><span>小时</span>
                <input type="text" v-model="fenz1" /><span>分钟</span>
              </div>
            </div>
          </div>
        </div>
        <div class="zengshow">
          <p class="firstq-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan2">友“疝” 云</p>
          <span class="zhu-yuan">住院病历查询</span>
          <div class="zeng-deil">
            <div class="doc-box">
              <div class="shdoc">
                <!-- <span class="docspan ">手术医师信息</span> -->
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div id="table2">
                    <div class="add2">
                      <input
                        type="text"
                        v-model="addDetail2.tiwen"
                        name="tiwen"
                        value=""
                        placeholder="请输入主刀医生姓名"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.zhuzhi"
                        name="zhuzhi"
                        value=""
                        placeholder="请输入主刀职位"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.xueya"
                        name="xueya"
                        value=""
                        placeholder="请输入一助医生姓名"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.yizhi"
                        name="yizhi"
                        value=""
                        placeholder="请输入一助职位"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.maibo"
                        name="maibo"
                        value=""
                        placeholder="请输入二助医生姓名"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.erzhi"
                        name="erzhi"
                        value=""
                        placeholder="请输入二助医生职位"
                      />

                      <button @click="adddetail2" style="cursor: pointer">
                        新增
                      </button>
                    </div>
                    <table cellpadding="0" cellspacing="0" class="tableheader2">
                      <thead>
                        <tr>
                          <th>主刀医生姓名</th>
                          <th>主刀职位</th>
                          <th>一助医生姓名</th>
                          <th>一助职位</th>
                          <th>二助医生姓名</th>
                          <th>二助医生职位</th>
                        </tr>
                      </thead>
                      <tbody class="tbodys2">
                        <tr v-for="(item, index) in newsList2" :key="index">
                          <td>{{ item.tiwen }}</td>
                          <td>{{ item.zhuzhi }}</td>
                          <td>{{ item.xueya }}</td>
                          <td>{{ item.yizhi }}</td>
                          <td>{{ item.maibo }}</td>
                          <td>{{ item.erzhi }}</td>
                          <td>
                            <span
                              @click="deletelist2(item.id, index)"
                              class="delete2"
                              style="cursor: pointer"
                              >删除</span
                            ><span
                              class="edit2"
                              @click="edit2(item)"
                              style="cursor: pointer"
                              >编辑</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="mask2" v-if="editlist2">
                      <div class="mask2">
                        <div class="title2">
                          编辑
                          <span @click="editlist2 = false"> X </span>
                        </div>
                        <div class="content2">
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.tiwen"
                            name="tiwen"
                            value=""
                            placeholder="主刀医生姓名"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.zhuzhi"
                            name="zhuzhi"
                            value=""
                            placeholder="主刀职位"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.xueya"
                            name="xueya"
                            value=""
                            placeholder="一助医生姓名"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.yizhi"
                            name="yizhi"
                            value=""
                            placeholder="一助医生职位"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.maibo"
                            name="maibo"
                            value=""
                            placeholder="二助医生姓名"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.erzhi"
                            name="erzhi"
                            value=""
                            placeholder="二助职位"
                          />
                          <button
                            @click="update2"
                            class="update"
                            style="cursor: pointer"
                          >
                            更新
                          </button>
                          <button
                            @click="editlist2 = false"
                            class="cal"
                            style="cursor: pointer"
                          >
                            取消
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>

            <div class="shuhou">
              <div class="shouhouti">
                <!-- <span class="shuhouspan">术后第一天生命体征</span> -->
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div id="table">
                    <div class="add">
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.tiwen"
                        name="tiwen"
                        value=""
                        placeholder="请输入体温"
                      />
                      ℃
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.xueya"
                        name="xueya"
                        value=""
                        placeholder="请输入血压"
                      />
                      mmHg
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.maibo"
                        name="maibo"
                        value=""
                        placeholder="请输入脉搏"
                      />
                      次/分
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.niao"
                        name="niao"
                        value=""
                        placeholder="请输入尿量"
                      />
                      ml
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.yinliu"
                        name="yinliu"
                        value=""
                        placeholder="请输入引流量"
                      />
                      ml
                      <input
                        type="text"
                        style="outline: none"
                        v-model="addDetail.tengtong"
                        name="tengtong"
                        value=""
                        placeholder="请输入疼痛评分"
                      />
                      1-10分
                      <button @click="adddetail" style="cursor: pointer">
                        新增
                      </button>
                    </div>
                    <table cellpadding="0" cellspacing="0" class="tableheader">
                      <thead>
                        <tr>
                          <th>序号</th>
                          <th>体温</th>
                          <th>血压</th>
                          <th>脉搏</th>
                          <th>尿量</th>
                          <th>引流量</th>
                          <th>疼痛评分</th>
                        </tr>
                      </thead>
                      <tbody class="tbodys">
                        <tr v-for="(item, index) in newsList" :key="index">
                          <td>术后第{{ index + 1 }}天生命体征:</td>
                          <td>{{ item.tiwen }}</td>
                          <td>{{ item.xueya }}</td>
                          <td>{{ item.maibo }}</td>
                          <td>{{ item.niao }}</td>
                          <td>{{ item.yinliu }}</td>
                          <td>{{ item.tengtong }}</td>
                          <td>
                            <span
                              @click="deletelist(item.id, index)"
                              class="delete"
                              >删除</span
                            ><span class="edit" @click="edit(item)">编辑</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="mask" v-if="editlist">
                      <div class="mask">
                        <div class="title">
                          编辑
                          <span @click="editlist = false"> X </span>
                        </div>
                        <div class="content">
                          <input
                            type="text"
                            v-model="addDetail.tiwen"
                            name="tiwen"
                            value=""
                            placeholder="请输入体温"
                          />
                          <input
                            type="text"
                            v-model="addDetail.xueya"
                            name="xueya"
                            value=""
                            placeholder="请输入血压"
                          />
                          <input
                            type="text"
                            v-model="addDetail.maibo"
                            name="maibo"
                            value=""
                            placeholder="请输入脉搏"
                          />
                          <input
                            type="text"
                            v-model="addDetail.niao"
                            name="niao"
                            value=""
                            placeholder="请输入尿量"
                          />
                          <input
                            type="text"
                            v-model="addDetail.yinliu"
                            name="yinliu"
                            value=""
                            placeholder="请输入引流量"
                          />
                          <input
                            type="text"
                            v-model="addDetail.tengtong"
                            name="tengtong"
                            value=""
                            placeholder="请输入疼痛评分"
                          />
                          <button @click="update" class="update2">更新</button>
                          <button @click="editlist = false" class="cal2">
                            取消
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="Second77-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">住院病例查询 </span>
          <div class="two77-deils">
            <div class="twofirst77-hang">
              <p class="">
                <span class="shuxia">术后下床时间</span>
                <input type="text" v-model="value4" /><span class="tian"
                  >天</span
                >
              </p>

              <p class="block5">
                <span class="shujie">术后自行解尿时间</span>
                <input type="text" v-model="value5" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="twofirst77-hang">
              <p class="block6">
                <span class="shuqi">术后排气排便时间</span>
                <input type="text" v-model="value6" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="thid77-hang">
              <p class="block7">
                <span class="kang-time">抗菌素使用时间</span>
                <input type="text" v-model="value7" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="forth277-hang">
              <p class="block8">
                <span class="dao-na">导尿管拔除时间:</span>
                <input type="text" v-model="value8" /><span class="tian"
                  >天</span
                >
              </p>
              <p class="block9">
                <span class="yinba-time">引流管拔除时间:</span>
                <input type="text" v-model="value9" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="fif277-hang">
              <p>
                <span>术后并发症</span>
                <input
                 list="bingfa"
                  type="text"
                  v-model="shuhoubing"
                  name=""
                  placeholder="请选择术后并发症"
                />
                <datalist id="options3">
                  <option value="重体力活动">重体力活动</option>
                  <option value="慢性咳嗽">慢性咳嗽</option>
                  <option value="腹水">腹水</option>
                  <option value="排尿困难">排尿困难</option>
                  <option value="便秘">便秘</option>
                  <option value="腹部手术">腹部手术</option>
                </datalist>
              </p>
              <p class="block9">
                <span class="yinba-time">伤口拆线时间:</span>
                <input type="text" v-model="value10" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="six277-hang">
              <p>
                <span>伤口愈合情况</span>
                <input list="options7" id="shangyuhge" v-model="shangfen" placeholder="愈合分类" />
                <datalist id="options7">
                  <option value="Ⅰ">Ⅰ</option>
                  <option value="Ⅱ">Ⅱ</option>
                  <option value="Ⅲ">Ⅲ</option>
                  <option value="Ⅳ">Ⅳ</option>
                </datalist>
              </p>
              <p>
                <!-- <span>疝分型</span> -->
                <input
                  list="options8"
                  id="editable-selectyuhe"
                  placeholder="愈合等级"
                  v-model="shangdeng"

                />
                <datalist id="options8">
                  <option value="甲">甲</option>
                  <option value="乙">乙</option>
                  <option value="丙">丙</option>
                </datalist>
              </p>
            </div>
          </div>
          <button class="btn-sub" @click="saveBtnCon">提交</button>
        </div>
      </div>

      <div class="ulbox">
        <p
          :class="[index == 0 ? 'liitem2' : 'liitem']"
          @click="changeIndex(0)"
        ></p>
        <p
          :class="[index == 1 ? 'liitem2' : 'liitem']"
          @click="changeIndex(1)"
        ></p>
        <p
          :class="[index == 2 ? 'liitem2' : 'liitem']"
          @click="changeIndex(2)"
        ></p>
        <p
          :class="[index == 3 ? 'liitem2' : 'liitem']"
          @click="changeIndex(3)"
        ></p>
        <p
          :class="[index == 4 ? 'liitem2' : 'liitem']"
          @click="changeIndex(4)"
        ></p>
        <p
          :class="[index == 5 ? 'liitem2' : 'liitem']"
          @click="changeIndex(5)"
        ></p>
        <p
          :class="[index == 6 ? 'liitem2' : 'liitem']"
          @click="changeIndex(6)"
        ></p>
        <p
          :class="[index == 7 ? 'liitem2' : 'liitem']"
          @click="changeIndex(7)"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyAppFirst",

  data() {
    return {
      marginLeft: 0,
      index: 0,
      baseUrl: "https://api.dahuayoushanyun.com",
      value24: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      addDetail: {},
      editlist: false,
      editDetail: {},
      newsList: [],
      editid: "",
      addDetail2: {},
      editlist2: false,
      editDetail2: {},
      newsList2: [],
      editid2: "",
      value1: "",
      value2: "",
      name: "",
      userYear: "",
      userjob: "",
      UserGuo: "",
      UserMin: "",
      UserJi: "",
      UserID: "",
      UserDian: "",
      UserPhone: "",
      addressHos: "",
      zhuyuanhao: "",
      UserKe: "",
      zhuyuantian: "",
      UserFee: "",
      UserMedicne: "",
      yiliaofee: "",
      UserZhen: "",
      Userzheng: "",
      UserShan: "",
      xiyannian: "",
      yinjiunian: "",
      qitanian: "",
      UserYue: "",
      UserHun: "",
      UserTi: "",
      UserXue: "",
      UserMai: "",
      UserShen: "",
      UserTizhong: "",
      UserBmi: "",
      Userxue: "",
      UserNiao: "",
      UserFen: "",
      UserXuetang: "",
      UserShou1: "",
      UserGan: "",
      UserShen2: "",
      UserDianjie: "",
      UserNing: "",
      Userqita: "",
      UserIn: "",
      UserChu: "",
      zhiname: "",
      zhixing: "",
      zhichi: "",
      zhifee: "",
      zhudao: "",
      shuhouTime: "",
      niaoTime: "",
      shuhouqi: "",
      kangjuntime: "",
      daoniaotime: "",
      yinliubaTime: "",
      shuhoubing: "",
      shangchai: "",
      tengping: "",
      shuhouxue: "",
      shuhouniao2: "",
      shuhoufen: "",
      shuhoutang: "",
      shuhougan: "",
      shushen: "",
      shuhoudian: "",
      shuhouning: "",
      limitNum11: 10,
      limitNum22: 10,
      limitNum33: 10,
      limitNum44: 10,
      limitNum55: 10,
      limitNum66: 10,
      limitNum77: 10,
      limitNum88: 10,
      valueisFu: "",
      valuesex: "",
      arrshoushufang: {},
      changzhun: "",
      daoniaozhun: "",
      kangyu: "",
      isHave: "",
      form: {
        file: "",
        type: 1,
      },
      qianxinUrl: [],
      form2: {
        file2: "",
        type2: 1,
      },
      qianxinUrl2: [],
      form3: {
        file3: "",
        type3: 1,
      },
      qianxinUrl3: [],
      form4: {
        file4: "",
        type4: 1,
      },
      qianxinUrl4: [],

      qianxinUrl55: [],
      form55: {
        file55: "",
        type55: 1,
      },
      qianxinUrl66: [],
      form66: {
        file66: "",
        type66: 1,
      },
      qianxinUrl77: [],
      form77: {
        file77: "",
        type77: 1,
      },
      xiaos:"",
      fenz1:"",
      qianxinUrl88: [],
      form88: {
        file88: "",
        type88: 1,
      },
      buwei: "",
      shanxing: "",
      fuya: "",
      heji: "",
      mazui: "",
      shoushu: "",
      suifang: "",
      fare: "",
      binfa: "",
      isFang: "",
      shangdeng: "",
      shangfen: "",
      shangdeng2: "",
      shangfen2: "",
      zhongkuai: "",
      zhidi: "",
      yinglang: "",
      touguang: "",
      huina: "",
      tengtojng: "",
    };
  },
  components: {},

  mounted() {
    this.getUidData();
  },

  methods: {
    shanIMG(item) {
      this.qianxinUrl.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl.indexOf(itemcheng);
          this.qianxinUrl.splice(indexwei, 1);
        }
      });
    },
    shanIMG2(item) {
      this.qianxinUrl2.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl2.indexOf(itemcheng);
          this.qianxinUrl2.splice(indexwei, 1);
        }
      });
    },
    shanIMG3(item) {
      this.qianxinUrl3.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl3.indexOf(itemcheng);
          this.qianxinUrl3.splice(indexwei, 1);
        }
      });
    },
    shanIMG4(item) {
      this.qianxinUrl4.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl4.indexOf(itemcheng);
          this.qianxinUrl4.splice(indexwei, 1);
        }
      });
    },
    shanIMG5(item) {
      this.qianxinUrl55.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl55.indexOf(itemcheng);
          this.qianxinUrl55.splice(indexwei, 1);
        }
      });
    },
    shanIMG6(item) {
      this.qianxinUrl66.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl66.indexOf(itemcheng);
          this.qianxinUrl66.splice(indexwei, 1);
        }
      });
    },
    shanIMG7(item) {
      this.qianxinUrl77.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl77.indexOf(itemcheng);
          this.qianxinUrl77.splice(indexwei, 1);
        }
      });
    },
    shanIMG8(item) {
      this.qianxinUrl88.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl88.indexOf(itemcheng);
          this.qianxinUrl88.splice(indexwei, 1);
        }
      });
    },
    change() {
      this.index++;
      if (this.index > 7) {
        this.index = 7;
      } else {
        this.marginLeft = 6.25 * -this.index;
      }

      if (this.marginLeft < -62.5) {
        this.marginLeft = -62.5;
      }
    },
    youchang() {
      this.index--;
      if (this.index < 0) {
        this.index = 0;
      } else {
        this.marginLeft += 6.25;
      }
    },
    changeIndex(index) {
      this.index = index;

      this.marginLeft = -this.index * 6.25;
    },
    getUidData() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$store.dispatch("setUid");
      let uid = this.$store.getters.getUid;
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/Api/Applets/ysOne?user_access_token=${token}&type=1`,
        data: {
          list: [
            {
              id: Number(uid),
            },
          ],
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        let resData = res.data.data;
        if (resData.name) {
          this.name = resData.name;
        }

        if (resData.age) {
          this.userYear = resData.age;
        }

        if (resData.sex) {
          this.valuesex = resData.sex;
        }

        if (resData.profession) {
          this.userjob = resData.profession;
        }

        if (resData.country) {
          this.UserGuo = resData.country;
        }

        if (resData.nationality) {
          this.UserMin = resData.nationality;
        }

        if (resData.hometown) {
          this.UserJi = resData.hometown;
        }

        if (resData.card) {
          this.UserID = resData.card;
        }

        if (resData.phone) {
          this.UserDian = resData.tel;
        }

        if (resData.tel) {
          this.UserPhone = resData.phone;
        }

        if (resData.hospital) {
          this.addressHos = resData.hospital;
        }

        if (resData.number) {
          this.zhuyuanhao = resData.number;
        }

        if (resData.department) {
          this.UserKe = resData.department;
        }

        if (resData.d_date) {
          this.value1 = resData.d_date;
        }

        if (resData.a_date) {
          this.value24 = resData.a_date;
        }

        if (resData.discharge) {
          this.zhuyuantian = resData.discharge;
        }

        if (resData.hospital_costs) {
          this.UserFee = resData.hospital_costs;
        }

        if (resData.medicine_fee) {
          this.UserMedicne = resData.medicine_fee;
        }

        if (resData.m_consumables) {
          this.yiliaofee = resData.m_consumables;
        }

        if (resData.diagnosis) {
          this.UserZhen = resData.diagnosis;
        }

        if (resData.location) {
          this.buwei = resData.location;
        }
        if (resData.it_recurred) {
          this.valueisFu = resData.it_recurred;
        }

        if (resData.onset_time) {
          this.value2 = resData.onset_time;
        }
        if (resData.zhongkuai) {
          this.zhongkuai = resData.zhongkuai;
        }
        if (resData.zhidi) {
          this.zhidi = resData.zhidi;
        }
        if (resData.yinglang) {
          this.yinglang = resData.yinglang;
        }
        if (resData.touguang) {
          this.touguang = resData.touguang;
        }
        if (resData.huina) {
          this.huina = resData.huina;
        }
        if (resData.tengtojng) {
          this.tengtojng = resData.tengtojng;
        }
        if (resData.hernia) {
          this.UserShan = resData.hernia;
        }

        if (resData.hernia_type) {
          this.shanxing = resData.hernia_type;
        }

        if (resData.pressure) {
          this.fuya = resData.pressure;
        }

        if (resData.smoking) {
          this.xiyannian = resData.smoking;
        }

        if (resData.drinking) {
          this.yinjiunian = resData.drinking;
        }

        if (resData.other) {
          this.qitanian = resData.other;
        }

        if (resData.menstrual_history) {
          this.UserYue = resData.menstrual_history;
        }

        if (resData.childbirth) {
          this.UserHun = resData.childbirth;
        }

        if (resData.body_temperature) {
          this.UserTi = resData.body_temperature;
        }

        if (resData.blood_pressure) {
          this.UserXue = resData.blood_pressure;
        }

        if (resData.pulse) {
          this.UserMai = resData.pulse;
        }

        if (resData.height) {
          this.UserShen = resData.height;
        }

        if (resData.weight) {
          this.UserTizhong = resData.weight;
        }

        if (resData.bmi_value) {
          this.UserBmi = resData.bmi_value;
        }

        if (resData.comorbidity) {
          this.heji = resData.comorbidity;
        }

        if (resData.blood_routine) {
          this.Userxue = resData.blood_routine;
        }

        if (resData.fecal_routine) {
          this.UserFen = resData.fecal_routine;
        }

        if (resData.urine_routine) {
          this.UserNiao = resData.urine_routine;
        }

        if (resData.blood_sugar) {
          this.UserXuetang = resData.blood_sugar;
        }

        if (resData.liver_function) {
          this.UserGan = resData.liver_function;
        }

        if (resData.kidney_function) {
          this.UserShen2 = resData.kidney_function;
        }

        if (resData.electrolyte) {
          this.UserDianjie = resData.electrolyte;
        }

        if (resData.hemagglutination) {
          this.UserNing = resData.hemagglutination;
        }

        if (resData.other_results) {
          this.Userqita = resData.other_results;
        }

        if (resData.b_preparation) {
          this.changzhun = resData.b_preparation;
        }

        if (resData.catheterization) {
          this.daoniaozhun = resData.catheterization;
        }

        if (resData.antibiotics) {
          this.kangyu = resData.antibiotics;
        }

        if (resData.anesthesia) {
          this.mazui = resData.anesthesia;
        }

        if (resData.surgical_approach) {
          this.arrshoushufang = resData.surgical_approach;
        }

        if (resData.implant_patch_name) {
          this.zhiname = resData.implant_patch_name;
        }

        if (resData.implanted_patch_model) {
          this.zhixing = resData.implanted_patch_model;
        }

        if (resData.implant_patch_size) {
          this.zhichi = resData.implant_patch_size;
        }
        if (resData.implantation_patch_cost) {
          this.zhifee = resData.implantation_patch_cost;
        }

        if (resData.about_drainage) {
          this.UserIn = resData.about_drainage;
        }

        if (resData.bleeding_volume) {
          this.UserChu = resData.bleeding_volume;
        }
        if(resData.xiaos){
          this.xiaos=resData.xiaos
        }
        if(resData.fenz1){
          this.fenz1=resData.fenz1
        }
        if (resData.operation_time) {
          this.value3 = resData.operation_time;
        }

        if (resData.surgeon_information) {
          this.newsList2 = resData.surgeon_information;
        }

        if (resData.postoperative_information) {
          this.shuhouTime = resData.postoperative_information;
        }

        if (resData.surgery_time) {
          this.newsList = resData.surgery_time;
        }

        if (resData.urination_time) {
          this.value4 = resData.urination_time;
        }

        if (resData.defecation_time) {
          this.value5 = resData.defecation_time;
        }

        if (resData.defecation_time_after) {
          this.value6 = resData.defecation_time_after;
        }

        if (resData.antibacterial_time) {
          this.value7 = resData.antibacterial_time;
        }

        if (resData.catheter_removal_time) {
          this.value8 = resData.catheter_removal_time;
        }
        if (resData.drainage_tube_removal_time) {
          this.value9 = resData.drainage_tube_removal_time;
        }
        if (resData.postoperative_complications) {
          this.shuhoubing = resData.postoperative_complications;
        }
        if (resData.suture_removal_time) {
          this.value10 = resData.suture_removal_time;
        }

        if (resData.wound_healing) {
          this.shangfen = resData.wound_healing.shangeyu;
        }
        if (resData.wound_healing) {
          this.shangdeng = resData.wound_healing.selectjia;
        }
        if (resData.follow_up_interval) {
          this.suifang = resData.follow_up_interval;
        }
        if (resData.fever) {
          this.fare = resData.fever;
        }
        if (resData.pain_score_vas) {
          this.tengping = resData.pain_score_vas;
        }
        if (resData.electrocardiogram) {
          this.qianxinUrl = resData.electrocardiogram;
        }
        if (resData.radiograph) {
          this.qianxinUrl2 = resData.radiograph;
        }

        if (resData.ultrasound) {
          this.qianxinUrl3 = resData.ultrasound;
        }

        if (resData.ct) {
          this.qianxinUrl4 = resData.ct;
        }
        if (resData.ecg_2) {
          this.qianxinUrl55 = resData.ecg_2;
        }
        if (resData.x_ray_2) {
          this.qianxinUrl66 = resData.x_ray_2;
        }
        if (resData.super_2) {
          this.qianxinUrl77 = resData.super_2;
        }
        if (resData.ct2) {
          this.qianxinUrl88 = resData.ct2;
        }

        if (resData.wound_healing2) {
          this.shangdeng2 = resData.wound_healing2.editableyuval;
        }
        if (resData.wound_healing2) {
          this.shangfen2 = resData.wound_healing2.dengyuheVal;
        }
        if (resData.complications_2) {
          this.binfa = resData.complications_2;
        }
        if (resData.recurrence) {
          this.isHave = resData.recurrence;
        }

        if (resData.blood_routine_2) {
          this.shuhouxue = resData.blood_routine_2;
        }
        if (resData.urine_routine1) {
          this.shuhouniao2 = resData.urine_routine1;
        }
        if (resData.fecalroutin_2) {
          this.shuhoufen = resData.fecalroutin_2;
        }

        if (resData.blood_sugar_2) {
          this.shuhoutang = resData.blood_sugar_2;
        }
        if (resData.liver_function_2) {
          this.shuhougan = resData.liver_function_2;
        }
        if (resData.kidney_function_2) {
          this.shushen = resData.kidney_function_2;
        }

        if (resData.electrolyte_2) {
          this.shuhoudian = resData.electrolyte_2;
        }
        if (resData.hemagglutination_2) {
          this.shuhouning = resData.hemagglutination_2;
        }
        if (resData.is_follow_up) {
          this.isFang = resData.is_follow_up;
        }
      });
    },
    //   新店
    // 文件状态改变时的钩子
    fileChange11(file) {
      this.$refs.uploadExcel11.clearFiles(); //去掉文件列表
      this.form.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile11();
      }
    },

    uploadFile11() {
      this.$refs.uploadExcel11.submit();
      this.$refs.uploadExcel11.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange22(file) {
      this.$refs.uploadExcel22.clearFiles(); //去掉文件列表
      this.form2.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile22();
      }
    },

    uploadFile22() {
      this.$refs.uploadExcel22.submit();
      this.$refs.uploadExcel22.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form2.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl2.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange33(file) {
      this.$refs.uploadExcel33.clearFiles(); //去掉文件列表
      this.form3.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile33();
      }
    },

    uploadFile33() {
      this.$refs.uploadExcel33.submit();
      this.$refs.uploadExcel33.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form3.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl3.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange44(file) {
      this.$refs.uploadExcel44.clearFiles(); //去掉文件列表
      this.form4.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile44();
      }
    },

    uploadFile44() {
      this.$refs.uploadExcel44.submit();
      this.$refs.uploadExcel44.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form4.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl4.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    //   新店
    // 文件状态改变时的钩子
    fileChange55(file) {
      this.$refs.uploadExcel55.clearFiles(); //去掉文件列表
      this.form55.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile55();
      }
    },

    uploadFile55() {
      this.$refs.uploadExcel55.submit();
      this.$refs.uploadExcel55.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form55.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl55.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange66(file) {
      this.$refs.uploadExcel66.clearFiles(); //去掉文件列表
      this.form66.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile66();
      }
    },

    uploadFile66() {
      this.$refs.uploadExcel66.submit();
      this.$refs.uploadExcel66.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form66.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl66.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange77(file) {
      this.$refs.uploadExcel77.clearFiles(); //去掉文件列表
      this.form77.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile77();
      }
    },

    uploadFile77() {
      this.$refs.uploadExcel77.submit();
      this.$refs.uploadExcel77.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form77.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl77.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange88(file) {
      this.$refs.uploadExcel88.clearFiles(); //去掉文件列表
      this.form88.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile88();
      }
    },

    uploadFile88() {
      this.$refs.uploadExcel88.submit();
      this.$refs.uploadExcel88.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form88.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl88.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    //新增
    adddetail() {
      //这里的思路应该是把this.addDetail传给服务端，然后加载列表this.newsList
      //this.newsList.push(this.addDetail)
      this.newsList.push({
        tiwen: this.addDetail.tiwen,
        xueya: this.addDetail.xueya,
        maibo: this.addDetail.maibo,
        niao: this.addDetail.niao,
        yinliu: this.addDetail.yinliu,
        tengtong: this.addDetail.tengtong,

        id: Math.floor(Math.random() * 1000000 + 1),
      });
    },
    //删除
    deletelist(id, i) {
      this.newsList.splice(i, 1);
    },
    //编辑
    edit(item) {
      this.editDetail = {
        tiwen: this.addDetail.tiwen,
        xueya: this.addDetail.xueya,
        maibo: this.addDetail.maibo,
        niao: this.addDetail.niao,
        yinliu: this.addDetail.yinliu,
        tengtong: this.addDetail.tengtong,
        id: item.id,
      };
      this.editlist = true;
      this.editid = item.id;
    },
    //确认更新
    update() {
      //编辑的话，也是传id去服务端
      //axios.get('url',{ID:id}).then((res) =>{
      //			加载列表
      //})
      let _this = this;
      for (let i = 0; i < _this.newsList.length; i++) {
        if (_this.newsList[i].id == this.editid) {
          _this.newsList[i] = {
            tiwen: this.addDetail.tiwen,
            xueya: this.addDetail.xueya,
            maibo: this.addDetail.maibo,
            niao: this.addDetail.niao,
            yinliu: this.addDetail.yinliu,
            tengtong: this.addDetail.tengtong,
            id: this.editid,
          };
          this.editlist = false;
        }
      }
    },
    //新增
    adddetail2() {
      //这里的思路应该是把this.addDetail传给服务端，然后加载列表this.newsList
      //this.newsList.push(this.addDetail)
      this.newsList2.push({
        tiwen: this.addDetail2.tiwen,
        xueya: this.addDetail2.xueya,
        zhuzhi: this.addDetail2.zhuzhi,
        maibo: this.addDetail2.maibo,
        yizhi: this.addDetail2.yizhi,
        niao: this.addDetail2.niao,
        erzhi: this.addDetail2.erzhi,

        id: Math.floor(Math.random() * 1000000 + 1),
      });
    },
    //删除
    deletelist2(id, i) {
      this.newsList2.splice(i, 1);
    },
    //编辑
    edit2(item) {
      this.editDetail2 = {
        tiwen: this.addDetail2.tiwen,
        xueya: this.addDetail2.xueya,
        zhuzhi: this.addDetail2.zhuzhi,
        maibo: this.addDetail2.maibo,
        yizhi: this.addDetail2.yizhi,
        niao: this.addDetail2.niao,
        erzhi: this.addDetail2.erzhi,
        id: item.id,
      };
      this.editlist2 = true;
      this.editid2 = item.id;
    },
    //确认更新
    update2() {
      //编辑的话，也是传id去服务端
      //axios.get('url',{ID:id}).then((res) =>{
      //			加载列表
      //})
      let _this = this;
      for (let i = 0; i < _this.newsList2.length; i++) {
        if (_this.newsList2[i].id == this.editid2) {
          _this.newsList2[i] = {
            tiwen: this.addDetail2.tiwen,
            xueya: this.addDetail2.xueya,
            zhuzhi: this.addDetail2.zhuzhi,
            maibo: this.addDetail2.maibo,
            yizhi: this.addDetail2.yizhi,
            niao: this.addDetail2.niao,
            erzhi: this.addDetail2.erzhi,

            id: this.editid2,
          };
          this.editlist2 = false;
        }
      }
    },
    // 日期格式化
    /* 将时间戳格式化为yyyy-MM-dd hh:mm:ss格式，其它格式可自行更改 */
    formatTimeMills(timeMills) {
      var date = new Date(timeMills);

      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    cancelBtn() {
      this.$router.push("/DocHou");
    },
    saveBtnCon() {
      // 時間1
      var resDate1,
        resDate2,
        resDate3,
        resDate4,
        resDate5,
        resDate6,
        resDate7,
        resDate8,
        resDate9,
        resDate10,
        resDate11;
      if (this.value1) {
        resDate1 = this.formatTimeMills(this.value1); //住院
      }
      if (this.value24) {
        resDate2 = this.formatTimeMills(this.value24); //住院
      }
      if (this.value2) {
        resDate3 = this.value2 //住院
      }
      if (this.value3) {
        resDate4 = this.formatTimeMills(this.value3); //住院
      }
      if (this.value4) {
        resDate5 = this.value4; //住院
      }
      if (this.value5) {
        resDate6 = this.value5; //住院
      }
      if (this.value6) {
        resDate7 = this.value6; //住院
      }
      if (this.value7) {
        resDate8 = this.value7; //住院
      }
      if (this.value8) {
        resDate9 = this.value8; //住院
      }
      if (this.value9) {
        resDate10 = this.value9; //住院
      }
      if (this.value10) {
        resDate11 = this.value10; //住院
      }

      let selectSexVal = this.$("#userSex").val();
      let UsersFu = this.$("#shifou").val();
      let shanfen = this.$("#shanfen").val();
      let yiyuan = this.$("#yiyuan").val();
      let fuyayuan = this.$("#fuyayuan").val();
      let heji = this.$("#heji").val();

      let mazuifang = this.$("#mazuifang").val();

      let shoushufang = this.$("#shoushufang").val(); //注意

      let editabledeil = this.$("#editable-deil").val();
      let arrshoushufang = {
        shoushufang: shoushufang,
        editabledeil: editabledeil,
      };

      let shangyuhge = this.$("#shangyuhge").val(); //注意
      let selectjia = this.$("#editable-selectyuhe").val();
      let arrshang = { shangeyu: shangyuhge, selectjia: selectjia };

      let editableyuval = this.$("#editableyu").val();
      let dengyuheVal = this.$("#dengyuhe").val();
      let dengyuhe = {
        editableyuval: editableyuval,
        dengyuheVal: dengyuheVal,
      };

      let editablesuifang = this.$("#editablesuifang").val();
      let editablesuifa = this.$("#editablesuifa").val();
      //上虞
      let bin = this.$("#bin").val();

      let isShifang = this.$("#isShifang").val();
      let shanfeng28 = this.$("#shanfeng28").val();
      let xiyang = this.$("#xiyang").val();
      let yin = this.$("#yin").val();
      let UserYue = this.$("#yue").val();
      let UserHun = this.$("#hunyun").val();
      let youyin = this.$("#youyin").val();
      let buwei = this.$("#editable-select").val();
      let shanfen1 = this.$("#shanfen1").val();
      let shanfen2 = this.$("#shanfen2").val();
      let shanfen3 = this.$("#shanfen3").val();
      let shanfen4 = this.$("#shanfen32").val()
      let shanfen5 = this.$("#shanfen5").val();
      let zhen=this.$("#zhen").val()
      let listitem = {
        name: this.name,
        age: this.userYear,
        sex: selectSexVal,
        profession: this.userjob,
        country: this.UserGuo,
        nationality: this.UserMin,
        hometown: this.UserJi,
        card: this.UserID,

        phone: this.UserDian,

        tel: this.UserPhone,

        hospital: yiyuan,
        number: this.zhuyuanhao,

        department: this.UserKe,

        d_date: resDate1,

        a_date: resDate2,

        discharge: this.zhuyuantian,

        hospital_costs: this.UserFee,

        medicine_fee: this.UserMedicne,

        m_consumables: this.yiliaofee,

        diagnosis: zhen,

        location: buwei,

        it_recurred: UsersFu,

        onset_time: resDate3,

        zhongkuai: this.zhongkuai,
         zhidi: shanfen1,
        yinglang: shanfen2,
        touguang: shanfen5,
        huina: shanfen3,
        tengtojng: shanfen4,

        hernia: shanfeng28,

        hernia_type: shanfen,

        pressure: fuyayuan,

        smoking: xiyang,

        drinking: yin,

        other: this.qitanian,

        menstrual_history: UserYue,

        childbirth: UserHun,

        body_temperature: this.UserTi,

        blood_pressure: this.UserXue,

        pulse: this.UserMai,

        height: this.UserShen,

        weight: this.UserTizhong,

        bmi_value: this.UserBmi,

        comorbidity: heji,

        // 圖

        electrocardiogram: this.qianxinUrl,

        radiograph: this.qianxinUrl2,

        ultrasound: this.qianxinUrl3,

        ct: this.qianxinUrl4,

        blood_routine: this.Userxue,
        fecal_routine: this.UserFen,
        urine_routine: this.UserNiao,

        blood_sugar: this.UserXuetang,

        liver_function: this.UserGan,
        // 之前对

        kidney_function: this.UserShen2,

        electrolyte: this.UserDianjie,

        hemagglutination: this.UserNing,

        other_results: this.Userqita,

        b_preparation: this.changzhun,

        catheterization: this.daoniaozhun,

        antibiotics: this.kangyu,

        anesthesia: mazuifang,

        surgical_approach: arrshoushufang, //注意

        implant_patch_name: this.zhiname,

        implanted_patch_model: this.zhixing,

        implant_patch_size: this.zhichi,
        implantation_patch_cost: this.zhifee,

        // 引流

        about_drainage: youyin,

        bleeding_volume: this.UserChu,
        xiaos:this.xiaos,
       fenz1: this.fenz1,

        operation_time: resDate4,

        surgeon_information: this.newsList2,

        postoperative_information: this.shuhouTime,
        surgery_time: this.newsList,

        urination_time: resDate5,

        defecation_time: resDate6,

        defecation_time_after: resDate7,

        antibacterial_time: resDate8,

        catheter_removal_time: resDate9,

        drainage_tube_removal_time: resDate10,

        postoperative_complications: this.shuhoubing,
        suture_removal_time: resDate11,

        wound_healing: arrshang, //注意

        follow_up_interval: editablesuifang,

        fever: editablesuifa,

        pain_score_vas: this.tengping,

        wound_healing2: dengyuhe,

        complications_2: bin,

        recurrence: this.isHave,

        // 心电图2
        ecg_2: this.qianxinUrl55,
        x_ray_2: this.qianxinUrl66,
        super_2: this.qianxinUrl77,
        ct2: this.qianxinUrl88,

        blood_routine_2: this.shuhouxue,

        urine_routine1: this.shuhouniao2,

        fecalroutin_2: this.shuhoufen,

        blood_sugar_2: this.shuhoutang,

        liver_function_2: this.shuhougan,

        kidney_function_2: this.shushen,

        electrolyte_2: this.shuhoudian,

        hemagglutination_2: this.shuhouning,

        is_follow_up: isShifang,
      };

      let list = [];
      Object.keys(listitem).forEach((item) => {
        if (listitem[item] == "" || listitem[item] == undefined) {
          delete listitem[item];
        }
      });
      list.push(listitem);

      let baseUrl = "https://api.dahuayoushanyun.com";
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$store.dispatch("setUid");
      let uid = this.$store.getters.getUid;
      list[0].id = Number(uid);
      this.$axios({
        method: "POST",
        url: `${baseUrl}/Api/Applets/save?user_access_token=${token}&type=1`,
        data: {
          list: list,
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.$router.push("/DocHou");
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.btn-sub22 {
  width: rem(230);
  height: rem(40);
  background: #0473C2;
  border-radius: rem(4);
  opacity: 0.96;
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  border: none;
  position: absolute;
  left: rem(400);
  bottom: rem(10);
  cursor: pointer;
  line-height: rem(25);
}
.btn-sub222 {
  width: rem(230);
  height: rem(40);
  background: #0473C2;
  border-radius: rem(4);
  opacity: 0.96;
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  border: none;
  position: absolute;
  left: rem(400);
  bottom: rem(10);
  cursor: pointer;
  line-height: rem(25);
}
.check-lun {
  width: rem(1200);
  height: rem(782);
  overflow: hidden;
  background: #FFFFFF;
  border-radius: rem(20);
  position: absolute;
  top: rem(100);
  left: rem(360);
}

.check-box {
  width: rem(13200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  display: flex;
  justify-content: flex-start;
}

.ulbox {
  width: rem(700);
  height: rem(40);
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  position: absolute;
  bottom: rem(20);
  left: rem(250);
}

.ulbox .liitem {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  background-color: #E3E3E3;
  margin-left: rem(20);
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  top: rem(10);
}

.ulbox .liitem2 {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  background-color: #0473C2;
  margin-left: rem(20);
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  top: rem(10);
}

.left, .you {
  width: rem(40);
  height: rem(40);
  border-radius: 50%;
  background: #0473C2;
  border: none;
  font-size: rem(22);
  color: #ffffff;
  bottom: rem(20);
  cursor: pointer;
}

.you {
  right: rem(100);
}

.left {
  left: rem(100);
}

p {
  padding: 0;
  margin: 0;
}

.firstq-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
}

.firstq-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.firstq-hang, .thidq-hang, .forthq-hang, .fifq-hang, .sixq-hang, .sevenq-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(30);
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.firstq-hang p {
  width: rem(200);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.zhu-yuan {
  font-size: rem(36);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0473C2;
  line-height: rem(50);
  position: absolute;
  top: rem(105);
  right: rem(220);
}

.firstq-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.thidq-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thidq-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thidq-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forthq-hang p, .fifq-hang p, .sixq-hang p,{
  width: rem(400);
  display: flex;
  justify-content: space-around;
}
.sevenq-hang p {
  width: rem(440);
  display: flex;
  justify-content: space-around;
}
.forthq-hang p span, .fifq-hang p span, .sixq-hang p span, .sevenq-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forthq-hang p input, .fifq-hang p input, .sixq-hang p input, .sevenq-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Secondq2-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
}

.happy-scroll-strip--horizontal {
  cursor: pointer;
}

.twoq2-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirstq2-hang, .thid2q2-hang, .forth2q2-hang, .fif2q2-hang, .six2q2-hang, .seven2q2-hang, .eight2-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  font-size: rem(20);
  position: relative;
  justify-content: space-between;
}

.twofirstq2-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirstq2-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirstq2-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid2q2-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid2q2-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid2q2-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth2q2-hang p, .fif2q2-hang p, .eight2q2-hang p, .six2q2-hang p, .seven2q2-hang p {
  width: rem(400);
  display: flex;
  height: rem(40);
  line-height: rem(40);
  font-size: rem(20);
  justify-content: space-between;
}

.forth2q2-hang p span, .fif2q2-hang p span, .eight2q2-hang p span, .six2q2-hang p span, .seven2q2-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth2q2-hang p input, .fif2q2-hang p input, .eight2q2-hang p input, .six2q2-hang p input, .seven2q2-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.third3-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
}

.third3-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.thirdfirst3-hang, .thid3-hang, .forth23-hang, .fif23-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  font-size: rem(20);
  display: flex;
  justify-content: space-between;
}

.thirdfirst3-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.thirdfirst3-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.thirdfirst3-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid3-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid3-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth23-hang p, .fif23-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth23-hang p span, .fif23-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth23-hang p input, .fif23-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.xindian3 {
  width: 100%;
  height: rem(256);
  margin-top: rem(10);
}

.forth4-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.forthfirstone4-hang {
  width: 100%;
  height: rem(196);
  display: flex;
  justify-content: space-around;
}

.forthfirst4-hang, .thid4-hang, .forth24-hang, .fif24-hang, .six24-hang, .seven24-hang {
  width: 100%;
  height: rem(40);
  z-index: 9999;
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.forthfirst4-hang p {
  width: rem(360);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.forthfirst4-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.forthfirst4-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid4-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid4-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid4-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth24-hang p, .fif24-hang p, .six24-hang p, .seven24-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth24-hang p span, .fif24-hang p span, .six24-hang p span, .seven24-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth24-hang p input, .fif24-hang p input, .eight4-hang p input, .six24-hang p input, .seven24-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.fif5-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}
.fifss-show{
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.fif5-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.fif5-hang, .thid5-hang, .fif25-hang, .six25-hang, .seven25-hang, .eight5-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  font-size: rem(20);
  display: flex;
  position: relative;
  justify-content: space-between;
}

.forth5-hang,.zhibucu {
  width: 100%;
  height: rem(150);
  margin-top: rem(20);
  display: flex;
  justify-content: space-around;
}
.fif5232-hang{
    width: 100%;
  margin-top: rem(20);
  display: flex;
  justify-content: space-around;
}
.fif5232-hang p,.zhibucu p {
  width: rem(440);
  height: rem(40);
  // margin-left: rem(30);
  display: flex;
  justify-content:flex-start;
}

.fif5232-hang p span,.zhibucu p span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.fif5232-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}
.fif5-hang p {
  width: rem(0);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.fif5-hang p span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.fif5-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid5-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid5-hang .ma {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid5-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.fif25-hang p, .eight5-hang p,  .seven25-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}
.six25-hang p{
   width: rem(550);
  display: flex;
  justify-content: space-between;
}
.forth5-hang .zhiinput {
  width: rem(900);
  height: rem(100);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.forth5-hang .zhiinput input {
  width: rem(240);
  height: rem(40);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  margin-left: rem(30);
}

.fif25-hang p span, .zhi, .eight5-hang p span, .six25-hang p span, .seven25-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.fif25-hang p input, .eight5-hang p input, .six25-hang p input, .seven25-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.zengshow {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.zeng-deil {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.doc-box {
  width: 100%;
  height: rem(240);
}

.shuhou {
  width: 100%;
  height: rem(300);
  margin-top: rem(30);
  overflow: hidden;
}

.shdoc, .shuhou {
  width: 100%;
  height: rem(240);
  position: relative;
}

.shdoc .docspan {
  width: rem(120);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
  position: absolute;
  top: rem(100);
  left: rem(0);
}

.shuhou .shuhouspan {
  width: rem(180);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
  position: absolute;
  top: rem(100);
  left: rem(0);
}

#table2 {
  width: rem(1000);
}

.add2 {
  width: rem(900);
  padding: rem(10);
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.youshan2 {
  font-size: rem(36);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: red;
  line-height: rem(50);
  position: absolute;
  top: rem(105);
  left: rem(380);
}

.add2 input {
  margin-left: rem(10);
  width: rem(130);
  height: rem(30);
  font-size: rem(14);
  color: #000000;
  border-radius: rem(5);
  margin-top: rem(25);
  border: 1px solid #dcdfe6;
  outline: none;
}

.tableheader2 th {
  width: rem(150);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  font-size: rem(14);
  height: rem(30);
  text-align: center;
  border: 1px solid #eee;
}

.tbodys2 td, .tbodys td {
  font-size: rem(14);
  width: rem(200);
  height: rem(40);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.delete2 {
  color: red;
}

.edit2 {
  color: #008cd5;
}

#mask2 {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}

.mask2 {
  width: rem(500);
  height: rem(450);
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 47;
  border-radius: 5px;
}

.three3-show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.xiong, .bpic {
  width: 100%;
  height: rem(200);
  margin-top: rem(50);
  display: flex;
  justify-content: space-around;
}

.xiong span, .bpic span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  top: rem(80);
}

.showpic {
  width: rem(900);
  height: 100%;
}

.title2 {
  width: 100%;
  color: #023DC6;
  font-size: rem(26);
  line-height: rem(30);
}

.content2 {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.title2 span {
  width: rem(30);
  height: rem(30);
  color: red;
  position: absolute;
  right: rem(10);
  cursor: pointer;
}

.content2 input {
  width: rem(300);
  height: rem(30);
  margin-bottom: rem(20);
  margin-left: rem(80);
}

.content button, .content2 button {
  width: rem(200);
  height: rem(60);
  line-height: rem(60);
  text-align: center;
  font-size: rem(22);
  color: #ffffff;
  background: #023DC6;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
  position: absolute;
}

.cal {
  right: rem(60);
  bottom: rem(-60);
  cursor: pointer;
}

.update {
  left: rem(10);
  bottom: rem(-60);
  cursor: pointer;
}

.cal2 {
  right: rem(60);
  bottom: rem(-60);
  cursor: pointer;
}

.update2 {
  left: rem(10);
  bottom: rem(-60);
}

.title span {
  width: rem(30);
  height: rem(30);
  color: red;
  position: absolute;
  right: rem(10);
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

#table {
  width: rem(1000);
}

.add {
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding: rem(15);
}

.add input {
  margin-left: rem(10);
  width: rem(140);
  height: rem(30);
  font-size: rem(14);
  color: #000000;
  border-radius: rem(5);
  border: 1px solid #dcdfe6;
  outline: none;
  margin-top: rem(25);
}

.add button {
  width: rem(100);
  height: rem(40);
  position: absolute;
  top: rem(0);
  background: #023DC6;
  font-size: rem(16);
  text-align: center;
  line-height: rem(40);
  // box-shadow: 0px 20px 20px #91AAE2;
  opacity: 1;
  color: #ffffff;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
}

.tableheader th {
  width: rem(200);
  color: #5f5a5a;
  font-size: rem(14);
  height: rem(30);
  text-align: center;
  border: 1px solid #eee;
}

.tbodys td {
  font-size: rem(14);
  width: rem(200);
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.delete {
  color: red;
  cursor: pointer;
}

.edit {
  color: #008cd5;
  cursor: pointer;
}

.tableheader2 input, .tableheader input {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 15px;
}

#mask {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}

.mask {
  width: rem(600);
  height: rem(500);
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 47;
  border-radius: 5px;
}

.title {
  font-size: rem(26);
}

.content input {
  width: rem(300);
  height: rem(30);
  margin-bottom: rem(20);
  margin-left: rem(80);
}

.title span {
  float: right;
  cursor: pointer;
}

.Second6-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two6-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst6-hang, .thid6-hang, .forth26-hang, .fif26-hang, .six26-hang, .seven26-hang, .eight6-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  position: relative;
  justify-content: space-between;
}

.twofirst6-hang p {
  width: rem(560);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst6-hang p span {
  width: rem(160);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.CheckUser .block1, .CheckUser .block1ss,.CheckUser .block4, .CheckUser .block2, .CheckUser .block3 {
  position: absolute;
  height: rem(60);
  top: rem(0);
  right: rem(50);
  // z-index value
  display: flex;
  justify-content: space-around;
}

.CheckUser .block3 {
  position: relative;
  left: rem(120);
}

.xin--dian3 .happy-scroll-container, .xiong2 .happy-scroll-container, .bpic2 .happy-scroll-container, .forth27-hang .happy-scroll-container, .thid7-hang .happy-scroll-container, .xiong .happy-scroll-container, .bpic .happy-scroll-container, .forthfirstone4-hang .happy-scroll-container {
  width: 2.904166666666667rem !important;
  height: rem(230) !important;
}

.xin--dian3 .happy-scroll-container .happy-scroll-content, .xiong2 .happy-scroll-container .happy-scroll-content, .bpic2 .happy-scroll-container .happy-scroll-content, .forth27-hang .happy-scroll-container .happy-scroll-content, .thid7-hang .happy-scroll-container .happy-scroll-content, .xiong .happy-scroll-container .happy-scroll-content, .bpic .happy-scroll-container .happy-scroll-content, .forthfirstone4-hang .happy-scroll-container .happy-scroll-content {
  position: relative !important;
  top: rem(-160) !important;
}

.twofirst6-hang p input,.block1ss input{
  width: rem(240);
  font-size: rem(18);
  height rem(40)
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}
.block4 input {
  width: rem(280);
  font-size: rem(18);
  height rem(50)
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid6-hang p {
  width: rem(600);
  display: flex;
  justify-content: flex-start;
}

.thid6-hang p span {
  width: rem(200);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid6-hang p input, .block2 input {
  width: rem(240);
  font-size: rem(18);
  height rem(40)
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  margin-left: rem(40);
}

.forth26-hang p, .fif26-hang p, .eight6-hang p, .six26-hang p, .seven26-hang p {
  width: rem(590);
  display: flex;
  justify-content: flex-start;
}

.forth26-hang p span, .fif26-hang p span, .eight6-hang p span, .six26-hang p span, .seven26-hang p span {
  width: rem(60);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth26-hang p input, .fif26-hang p input, .eight6-hang p input, .six26-hang p input, .seven26-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Second7-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two7-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst7-hang, .thid7-hang, .forth27-hang, .fif27-hang, .six27-hang, .seven27-hang, .eight7-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.twofirst7-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst7-hang p span {
  width: rem(120);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst7-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid7-hang, .forth27-hang {
  width: 100%;
  height: rem(200);
}

.three32-show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.three2-colim {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(182);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.xiong2, .bpic2 {
  width: 100%;
  height: rem(200);
  margin-top: rem(50);
  display: flex;
  justify-content: space-around;
}

.xiong2 span, .bpic2 span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  top: rem(80);
}

.showpic2 {
  width: rem(900);
  height: 100%;
}

.Second9-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two9-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst9-hang, .thid9-hang, .forth29-hang, .fif29-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.twofirst9-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst9-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst9-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid9-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid9-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid9-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth29-hang p, .fif29-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth29-hang p span, .fif29-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth29-hang p input, .fif29-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.zengshow .happy-scroll-content {
  position: relative !important;
  top: rem(-150) !important;
}

.xinimg--zhan {
  // width rem(500)
  min-height: rem(180);
  position: relative;
  left: rem(40);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.xin--dian3 {
  width: 100%;
  height: 1.333333333333333rem;
  margin-top: 0.052083333333333rem;
  position: relative;
  display: flex;
  justify-content: space-around;
}

.xin--box {
  width: rem(140);
  height: rem(140);
  position: relative;
}

.chahao {
  width: rem(20);
  height: rem(20);
  position: absolute;
  right: rem(0);
}

.xinforimg {
  width: rem(90);
  height: rem(90);
  position: absolute;
  top: rem(10);
  left: rem(5);
}

.xin--bc {
  width: 5.125rem;
  height: rem(200);
  position: relative;
  display: flex;
  justify-content: space-around;
}
</style>
