<template>
  <div class="loginbox">
    <div class="loginbox_title">
    </div>
    <div class="loginbox_formbox">
      <p class="loginbox_title_content">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
      <p class="loginbox_title_content_small">友“疝” 云</p>
      <img src="../assets/loginBg.png" />
      <div class="formbox_title">
        <div
          v-for="(item, index) in titleList"
          :key="index"
          :class="
            index == titleIndex
              ? 'formbox_title_item active'
              : 'formbox_title_item'
          "
          @click="chooseWay(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <el-form :model="accountform" v-if="titleIndex == 0">
        <el-form-item>
          <el-input
            v-model="accountform.userName"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="accountform.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="phoneform" v-if="titleIndex == 1">
        <el-form-item>
          <el-input v-model="phoneform.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="codebtn">
            <el-input
              v-model="phoneform.code"
              placeholder="验证码"
              style="width:68%"
            ></el-input>
            <el-button :disabled="disabled" @click="sendCode">{{
              btnText
            }}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-button class="loginbtn" v-if="titleIndex == 0" @click="psdLogin"
        >登 录</el-button
      >
      <el-button class="loginbtn" v-if="titleIndex == 1" @click="phoneLogin"
        >登 录</el-button
      >
      <span class="registerbtn" @click="register">注册</span>
    </div>
  </div>
</template>
<script>
const baseUrl = "https://api.dahuayoushanyun.com";
// import YanZheng from "./yanzheng.vue";
export default {
  name: "PhoneLogin",
  components: {
    // YanZheng,
  },
  props: {
    ruleForm: {
      type: Object,
      required: true,
    },
    countDown: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      titleList: [{ name: "账号密码登录" }, { name: "手机验证码登录" }],
      titleIndex: 0,
      accountform: {
        userName: "",
        password: "",
      },
      phoneform: {
        phone: "",
        code: "",
      },
      status: false,
      statusText: 0,
      disabled: false,
      btnText: "点击获取验证码",
      time: 60,
    };
  },
  methods: {
    // 选择登录方式
    chooseWay(index) {
      this.titleIndex = index;
    },
    onMpanelSuccess() {
      this.statusText = 1;
    },
    // 发送验证码
    sendCode() {
      if (this.phoneform.phone != "") {
        var _this = this;
        _this
          .$axios({
            method: "GET",
            url: `${baseUrl}/Api/User/Sms?phone=${this.phoneform.phone}&type=2`,
            headers: { "content-type": "application/json;charset=utf8" },
          })
          .then((res) => {
            let resData = res.data;
            if (resData.status == 1) {
              this.$message({
                showClose: false,
                message: "验证码发送成功",
                type: "success",
              });
            }
          });
        let timer = setInterval(() => {
          this.time--;
          this.btnText = `${this.time}s后重新发送`;
          this.disabled = true;
          if (this.time === 0) {
            this.disabled = false;
            this.btnText = "重新发送";
            this.time = this.countDown;
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.$message({
          showClose: false,
          message: "请输入手机号",
          type: "warning",
        });
      }
    },
    getUserInfo(token) {
      this.$axios({
        method: "get",
        url: `${baseUrl}/Api/User/userOauth1?user_access_token=${token}`,
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // let type = res.data.data.type;
        // let status = res.data.data.status;
        if (token) {
          //医生成功登录
          this.$router.push("/DocHou");
          this.$message({
            showClose: false,
            message: "登录成功",
            type: "success",
          });
          console.log(res.data.data.name,res.data.data.phone,'手段谁')
          if(res.data.data.name != null){
            localStorage.setItem('username',res.data.data.name)
          }else {
            localStorage.setItem('username',res.data.data.phone)
          }
        // } else if (token && type == 2 && status == 0) {
        //   this.$router.push("/userInforUp");
        //   this.$message({
        //     showClose: false,
        //     message: "登录成功",
        //     type: "success",
        //   });
        // } else if (token && type == 2 && status == 2) {
        //   this.$router.push("/UserInfoShen");
        //   this.$message({
        //     showClose: false,
        //     message: "登录成功",
        //     type: "success",
        //   });
        // } else if (token && type == 2 && status == 3) {
        //   this.$router.push("/UserInfoBo");
        //   this.$message({
        //     showClose: false,
        //     message: "登录成功",
        //     type: "success",
        //   });
        // } else if (token && type == 2 && status == 1) {
        //   this.$router.push("/userInfo");
        //   this.$message({
        //     showClose: false,
        //     message: "登录成功",
        //     type: "success",
        //   });
        }
      });
    },
    // 手机号登录
    phoneLogin() {
      if (this.phoneform.phone != "" && this.phoneform.code != "") {
        this.$axios({
          method: "GET",
          url: `${baseUrl}/Api/User/userOauth?phone=${this.phoneform.phone}&code=${this.phoneform.code}`,
          headers: { "content-type": "application/json;charset=utf8" },
        }).then((res) => {
          if (res.data.status == 1) {
            let token = res.data.data.user_access_token;
            localStorage.setItem(
              "user_access_token",
              window.btoa(encodeURIComponent(JSON.stringify(token)))
            );
            this.getUserInfo(token);
          } else {
            this.$message({
              showClose: false,
              message: "登录失败",
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          showClose: false,
          message: "手机号或者验证码不能为空",
          type: "warning",
        });
      }
    },
    // 账号密码登录
    psdLogin() {
      if (this.accountform.userName != "" && this.accountform.password != "") {
        this.$axios({
          method: "GET",
          url: `${baseUrl}/Api/User/userlogon?name=${this.accountform.userName}&pass=${this.accountform.password}`,
          headers: { "content-type": "application/json;charset=utf8" },
        }).then((res) => {
          console.log(res, "刷死");
          if (res.data.status == 1) {
            let token = res.data.data.user_access_token;
            localStorage.setItem(
              "user_access_token",
              window.btoa(encodeURIComponent(JSON.stringify(token)))
            );
            this.getUserInfo(token);
          } else {
            this.$message({
              showClose: true,
              message: "登录失败",
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          showClose: false,
          message: "用户名或者密码不能为空",
          type: "warning",
        });
      }
    },
    // 注册
    register() {
      this.$router.push("/Register");
    },
  },
  mounted() {
    this.time = this.countDown;
  },
};
</script>

<style lang="stylus" scoped>

vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.loginbox {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loginbox_title {
  width: 100%;
  height: rem(50);
  line-height: rem(50);
  background: #184E77;
  font-size: rem(20);
  text-align: center;
  color: #fff;
}

.loginbox_formbox {
  width: rem(500);
  margin: rem(40) auto;
  font-size: rem(20);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.loginbox_title_content {
  font-size: rem(25);
}

.loginbox_title_content_small {
  text-align: center;
  margin-top: rem(5);
}

.loginbox_title img {
  width: rem(346);
  height rem(252);
}

.formbox_title {
  margin-top: rem(45);
  width: 100%;
  height: rem(54);
  display: flex;
  background: #f2f2f2;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.13);
  border-radius: 8px 8px 8px 8px;
}

.formbox_title_item {
  color: #5B5B5B;
  width: 50%;
  text-align: center;
  line-height: rem(54);
  cursor: pointer;
}

.active {
  background: #184E77;
  color: #fff;
}

::v-deep .el-form {
  margin-top: rem(20);
}

::v-deep .loginbox_formbox .el-input__inner {
  background: #F4F8FF;
  border-radius: 8px;
  height: rem(50);
  margin-bottom: rem(-10);
  border: none;
}

.codebtn {
  display: flex;
  justify-content: space-between;
}

.codebtn .el-button {
  background: #184E77;
  color: #fff;
  border-radius: rem(4);
}

.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
  background: #184E77;
}

.loginbtn,.loginbtn:hover,.loginbtn:focus {
  background: #184E77;
  border-radius: rem(8);
  font-size: rem(18);
  color: #fff;
}

.registerbtn {
  text-align: center;
  margin-top: rem(10);
  font-size: rem(16);
  color: #0473C2;
  cursor: pointer;
}
</style>
