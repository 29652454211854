<template>
  <div class="ShowLu">
    <div class="showLun">
      <button
        style="position: absolute; z-index: 100000"
        @click="youchang"
        class="left2"
      >
        <span> &lt;</span>
      </button>
      <button
        style="position: absolute; z-index: 100000"
        @click="change"
        class="you2"
      >
        <span> &gt; </span>
      </button>
      <div class="show-box" :style="{ marginLeft: marginLeft + 'rem' }">
        <div class="first11-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="first11-deils">
            <div class="first11-hang">
              <p>
                <span>姓名</span>
                <input
                  type="text"
                  v-model="name"
                  placeholder="请输入姓名"
                  name=""
                />
              </p>
              <p>
                <span>年龄</span>
                <input
                  type="text"
                  v-model="userYear"
                  placeholder="请输入年龄"
                  name=""
                />
              </p>
              <p>
                <span>性别</span>
                <select class="userSex">
                  <option value=""></option>
                  <option value="">男</option>
                  <option value="">女</option>
                </select>
              </p>
              <p>
                <span>籍贯</span>
                <input
                  type="text"
                  v-model="UserJi"
                  name=""
                  placeholder="请输入籍贯"
                />
              </p>
            </div>
            <div class="first11-hang">
              <p>
                <span>职业</span>
                <input
                  type="text"
                  v-model="userjob"
                  name=""
                  placeholder="请输入职业"
                />
              </p>
              <p>
                <span>国籍</span>
                <input
                  type="text"
                  v-model="UserGuo"
                  name=""
                  placeholder="请输入国籍"
                />
              </p>
              <p>
                <span>民族</span>
                <input type="text" v-model="UserMin" placeholder="请输入民族" />
              </p>
            </div>
            <div class="thid11-hang">
              <p>
                <span>身份证号</span>
                <input
                  type="text"
                  v-model="UserID"
                  name=""
                  placeholder="请输入身份证号"
                />
              </p>
              <p>
                <span>电话号码</span>
                <input
                  type="text"
                  v-model="UserDian"
                  name=""
                  placeholder="请输入电话号码"
                />
              </p>
            </div>
            <div class="forth11-hang">
              <p>
                <span>手机号码</span>
                <input
                  type="text"
                  v-model="UserPhone"
                  name=""
                  placeholder="请输入手机号码"
                />
              </p>
            </div>
            <div class="fif11-hang">
              <p>
                <span>所在医院</span>
                <input
                  type="text"
                  v-model="addressHos"
                  list="suozai"
                  id="address"
                  name=""
                  placeholder="请输入所在医院名称"
                />
                <datalist id="suozai">
                  <option value="大华医院">大华医院</option>
                  <option value="徐汇中心医院">徐汇中心医院</option>
                  <option value="第八人民医院">第八人民医院</option>
                </datalist>
              </p>
              <p>
                <span>住院号</span>
                <input
                  type="text"
                  v-model="zhuyuanhao"
                  name=""
                  placeholder="请输入住院号"
                />
              </p>
            </div>
            <div class="six11-hang">
              <p>
                <span>科室</span>
                <input
                  type="text"
                  v-model="UserKe"
                  name=""
                  placeholder="请输入科室"
                />
              </p>
              <div class="block1">
                <span class="chutiem">入院日期</span>
                <el-date-picker
                  v-model="value1"
                  format="yyyy-MM-dd "
                  type="date"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="seven11-hang">
              <p>
                <span>住院天数</span>
                <input type="text" v-model="zhuyuantian" name="" /><span
                  >天</span
                >
              </p>

              <div class="block4">
                <span class="chutime">出院日期:</span>
                <el-date-picker
                  v-model="value24"
                  type="date"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="Second22-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="two22-deils">
            <div class="twofirst22-hang">
              <p>
                <span>住院费用</span>
                <input
                  type="text"
                  v-model="UserFee"
                  name=""
                  placeholder="请输入住院费用"
                />
                元
              </p>
              <p>
                <span>药费</span>
                <input
                  type="text"
                  v-model="UserMedicne"
                  name=""
                  placeholder="请输入药费"
                />
                元
              </p>
            </div>
            <div class="twofirst22-hang">
              <p>
                <span>医疗耗材费</span>
                <input
                  type="text"
                  v-model="yiliaofee"
                  name=""
                  placeholder="请输入医疗耗材费"
                />
                元
              </p>
            </div>
            <div class="thid22-hang">
              <p>
                <span>诊断</span>
                <input
                  type="text"
                  v-model="UserZhen"
                  list="zhenduan"
                  name=""
                  id="fen"
                  placeholder="请输入诊断"
                />
                <datalist id="zhenduan">
                  <option value="斜疝">斜疝</option>
                  <option value="直疝">直疝</option>
                  <option value="股疝">股疝</option>
                  <option value="复合疝">复合疝</option>
                  <option value="脐疝">脐疝</option>
                  <option value="切口疝">切口疝</option>
                  <option value="白线疝">白线疝</option>
                  <option value="半月线疝">半月线疝</option>
                  <option value="腰疝">腰疝</option>
                </datalist>
              </p>
              <p>
                <span>部位</span>
                <input
                  list="options1"
                  id="editable-select"
                  placeholder="请选择部位"
                />
                <datalist id="options1">
                  <option value="左">左</option>
                  <option value="右">右</option>
                  <option value="双侧">双侧</option>
                </datalist>
              </p>
            </div>
            <div class="forth222-hang">
              <p>
                <span>是否复发</span>
                <select name="" class="UsersFu">
                  <option value=""></option>
                  <option value="">是</option>
                  <option value="">否</option>
                </select>
              </p>
              <div class="block2">
                <span class="fatime">发病时间:</span>
                <input type="text" v-model="value2" /><span class="tian"
                  >天</span
                >
              </div>
            </div>
            <div class="fif222-hang">
              <p>
                <span>主要症状</span>
                <input
                  type="text"
                  v-model="Userzheng"
                  name=""
                  placeholder="请输入肿块大小cm"
                />
                <input
                  list="options21"
                  id="shanfen1"
                  placeholder="请选择质地"
                />
                <datalist id="options21">
                  <option value="软">软</option>
                  <option value="硬">硬</option>
                  <option value="中">中</option>
                </datalist>
                <input
                  list="options22"
                  id="shanfen2"
                  placeholder="请选择是否进入阴囊"
                />
                <datalist id="options22">
                  <option value="是">是</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
                <input
                  list="options23"
                  id="shanfen3"
                  placeholder="请选择能否回纳"
                />
                <datalist id="options23">
                  <option value="能">能</option>
                  <option value="否">否</option>
                </datalist>
                <input
                  list="options24"
                  id="shanfen4"
                  placeholder="请选择是否疼痛"
                />
                <datalist id="options24">
                  <option value="是">是</option>
                  <option value="否">否</option>
                </datalist>
                <input
                  list="options25"
                  id="shanfen5"
                  placeholder="请选择透光试验"
                />
                <datalist id="options25">
                  <option value="阳性">阳性</option>
                  <option value="阴性">阴性</option>
                </datalist>
              </p>
            </div>
            <div class="six222-hang">
              <p>
                <span>疝分类</span>
                <input
                  type="text"
                  v-model="UserShan"
                  name=""
                  list="options28"
                  id="shanfeng28"
                  placeholder="请输入疝分类"
                />
                <datalist id="options28">
                  <option value="易复性疝">易复性疝</option>
                  <option value="难复性疝">难复性疝</option>
                  <option value="嵌顿性疝">嵌顿性疝</option>
                  <option value="绞窄性疝">绞窄性疝</option>
                  <option value="Richter疝">Richter疝</option>
                  <option value="Littre疝">Littre疝</option>
                </datalist>
              </p>
              <p>
                <span>疝分型</span>
                <input
                  list="options2"
                  id="shanfen"
                  placeholder="请选择疝分型"
                />
                <datalist id="options2">
                  <option value="I型">I型</option>
                  <option value="Ⅱ型">Ⅱ型</option>
                  <option value="Ⅲ型">Ⅲ型</option>
                  <option value="Ⅳ型">Ⅳ型</option>
                </datalist>
              </p>
            </div>
            <div class="seven222-hang">
              <p>
                <span>腹压增高因素</span>
                <input
                  list="options3"
                  id="fuyayuan"
                  placeholder="请选择腹压增高因素"
                />
                <datalist id="options3">
                  <option value="重体力活动">重体力活动</option>
                  <option value="慢性咳嗽">慢性咳嗽</option>
                  <option value="腹水">腹水</option>
                  <option value="排尿困难">排尿困难</option>
                  <option value="便秘">便秘</option>
                  <option value="腹部手术">腹部手术</option>
                </datalist>
              </p>
              <p>
                <span>吸烟</span>
                <input
                  type="text"
                  v-model="xiyannian"
                  name=""
                  id="xiyang"
                  list="optionss3"
                  placeholder="请输入吸烟年限"
                />
                年
                <datalist id="optionss3">
                  <option value="是">是</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
              </p>
            </div>
            <div class="eight22-hang">
              <p>
                <span>饮酒</span>
                <input
                  type="text"
                  list="optionsws3"
                  v-model="yinjiunian"
                  id="yin"
                  placeholder="请输入饮酒年限"
                />
                年
                <datalist id="optionsws3">
                  <option value="是">是</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
              </p>
              <p>
                <span>其他</span>
                <input
                  type="text"
                  v-model="qitanian"
                  list="optionswdus3"
                  id="du"
                  placeholder="请输入其他因素"
                />
                <datalist id="optionswdus3">
                  <option value="是">吸毒10年</option>
                  <option value="否">否</option>
                  <option value="无">无</option>
                </datalist>
              </p>
            </div>
          </div>
        </div>
        <div class="third33-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="third33-deils">
            <div class="thirdfirst33-hang">
              <p>
                <span>女性月经史</span>
                <input
                  type="text"
                  v-model="UserYue"
                  name=""
                  list="optionyue"
                  id="yue"
                  placeholder="请输入女性月经史"
                />
                <datalist id="optionyue">
                  <option value="月经规则">月经规则</option>
                </datalist>
              </p>
              <p>
                <span>婚育史</span>
                <input
                  type="text"
                  v-model="UserHun"
                  id="hunyun"
                  list="optionhun"
                  placeholder="请输入婚育史"
                />
                <datalist id="optionhun">
                  <option value="未婚">未婚</option>
                  <option value="未育">未育</option>
                  <option value="已婚">已婚</option>
                  <option value="育1子">育1子</option>
                  <option value="育1女">育1女</option>
                </datalist>
              </p>
            </div>
            <div class="thirdfirst33-hang">
              <p>
                <span>体温</span>
                <input
                  type="text"
                  v-model="UserTi"
                  name=""
                  placeholder="请输入体温"
                />
                ℃
              </p>
              <p>
                <span>血压</span>
                <input
                  type="text"
                  v-model="UserXue"
                  name=""
                  placeholder="请输入血压"
                />
                mmHg
              </p>
            </div>
            <div class="thid33-hang">
              <p>
                <span>脉搏</span>
                <input
                  type="text"
                  v-model="UserMai"
                  name=""
                  placeholder="请输入脉搏"
                />
                次/分
              </p>
              <p>
                <span>身高</span>
                <input
                  type="text"
                  v-model="UserShen"
                  name=""
                  placeholder="请输入身高"
                />
                cm
              </p>
            </div>
            <div class="forth233-hang">
              <p>
                <span>体重</span>
                <input
                  type="text"
                  v-model="UserTizhong"
                  name=""
                  placeholder="请输入体重"
                />
                kg
              </p>
            </div>
            <div class="fif233-hang">
              <p>
                <span>BMI指数</span>
                <input
                  type="text"
                  v-model="UserBmi"
                  name=""
                  placeholder="请输入BMI指数"
                />
              </p>
              <p>
                <span>合并疾病</span>
                <input list="options4" id="heji" placeholder="请输入合并疾病" />
                <datalist id="options4">
                  <option value="高血压">高血压</option>
                  <option value="糖尿病">糖尿病</option>
                  <option value="冠心病">冠心病</option>
                  <option value="肺气肿">肺气肿</option>
                  <option value="贫血">贫血</option>
                  <option value="前列腺增生">前列腺增生</option>
                </datalist>
              </p>
            </div>

            <div class="xin--dian3">
              <div class="first-xin">
                <span class="first-xintxt">心电图</span>
                <img class="xin" src="./assets/jiahao.png" />
                <p class="upxin-txt">上传心电图</p>
                <el-upload
                  class="el--upload11"
                  ref="uploadExcel11"
                  action="#"
                  :limit="limitNum11"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange11"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>
        <div class="three3-show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="three-colim">
            <div class="xiong">
              <!-- 胸片 -->
              <div class="xinbc">
                <span class="xin2">胸片</span>
                <img class="xinB1" src="./assets/jiahao.png" />
                <p class="upBc1">上传胸片</p>
                <el-upload
                  class="el--upload22"
                  ref="uploadExcel22"
                  action="#"
                  :limit="limitNum22"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange22"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl2"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      class="chahao"
                      @click="shanIMG2(item)"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="bpic">
              <!-- B超 -->
              <div class="xinbc">
                <span class="xin2">B超</span>
                <img class="xinB1" src="./assets/jiahao.png" />
                <p class="upBc1">上传B超</p>
                <el-upload
                  class="el--upload33"
                  ref="uploadExcel33"
                  action="#"
                  :limit="limitNum33"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange33"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl3"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      @click="shanIMG3(item)"
                      class="chahao"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
          </div>
        </div>
        <div class="forth44-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="forth44-deils">
            <div class="forthfirstone4-hang">
              <div class="xin--bc">
                <span class="xin2">CT</span>
                <img class="xinB1" src="./assets/jiahao.png" />
                <p class="upBc1">上传CT</p>
                <el-upload
                  class="el--upload44"
                  ref="uploadExcel44"
                  action="#"
                  :limit="limitNum44"
                  :auto-upload="false"
                  :multiple="true"
                  accept=".png,.jpg"
                  name="file"
                  :headers="{
                    Authorization: `Bearer${$store.state.token}`,
                  }"
                  drag
                  :on-change="fileChange44"
                >
                </el-upload>
              </div>
              <happy-scroll
                color="#DCDFE6"
                hide-horizontal
                size="6"
                :min-length-v="3"
                resize
                right
              >
                <div class="xinimg--zhan">
                  <div
                    class="xin--box"
                    v-for="(item, index) in qianxinUrl4"
                    :key="index"
                  >
                    <img
                      src="./assets/cuowu.png"
                      style="cursor: pointer"
                      @click="shanIMG4(item)"
                      class="chahao"
                      alt=""
                    />
                    <img
                      class="xinforimg"
                      :src="baseUrl + item"
                      alt=""
                      v-viewer
                    />
                  </div>
                </div>
              </happy-scroll>
            </div>
            <div class="forthfirst44-hang">
              <p>
                <span>血常规</span>
                <input
                  type="text"
                  v-model="Userxue"
                  placeholder="请输入血常规检测结果"
                  style="z-index: 10000"
                  name=""
                />
              </p>
              <p>
                <span>尿常规</span>
                <input
                  type="text"
                  v-model="UserNiao"
                  name=""
                  placeholder="请输入尿常规检测结果"
                />
              </p>
            </div>
            <div class="thid44-hang">
              <p>
                <span>粪常规</span>
                <input
                  type="text"
                  v-model="UserFen"
                  name=""
                  placeholder="请输入粪常规检测结果"
                />
              </p>
              <p>
                <span>血糖</span>
                <input
                  type="text"
                  v-model="UserXuetang"
                  name=""
                  placeholder="请输入血糖检测结果"
                />
              </p>
            </div>
            <div class="forth244-hang">
              <p>
                <span>肝功能</span>
                <input
                  type="text"
                  v-model="UserGan"
                  name=""
                  placeholder="请输入肝功能检测结果"
                />
              </p>
              <p>
                <span>肾功能</span>
                <input
                  type="text"
                  v-model="UserShen2"
                  name=""
                  placeholder="请输入肾功能检测结果"
                />
              </p>
            </div>
            <div class="fif244-hang">
              <p>
                <span>电解质</span>
                <input
                  type="text"
                  v-model="UserDianjie"
                  name=""
                  placeholder="请输入电解质检测结果"
                />
              </p>
              <p>
                <span>血凝</span>
                <input
                  type="text"
                  v-model="UserNing"
                  name=""
                  placeholder="请输入血凝检测结果"
                />
              </p>
            </div>
            <div class="six244-hang">
              <p>
                <span>其他</span>
                <input
                  type="text"
                  v-model="Userqita"
                  name=""
                  placeholder="请输入其他"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="fif55-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="fif55-deils">
            <div class="fif55-hang">
              <p>
                <span>肠道准备</span>
                <select id="changzhun">
                  <option value=""></option>
                  <option value="2">是</option>
                  <option value="3">否</option>
                  <option value="4">无</option>
                </select>
              </p>
              <p>
                <span>导尿</span>
                <select id="daoniaozhun">
                  <option value=""></option>
                  <option value="2">是</option>
                  <option value="3">否</option>
                  <option value="4">无</option>
                </select>
              </p>
            </div>
            <div class="fif55-hang">
              <p>
                <span>预防性抗菌素</span>
                <select id="kangyu">
                  <option value=""></option>
                  <option value="2">是</option>
                  <option value="3">否</option>
                </select>
              </p>
            </div>
            <div class="thid55-hang">
              <p>
                <span class="ma">麻醉方式</span>
                <input
                  list="options5"
                  id="mazuifang"
                  placeholder="请选择麻醉方式"
                />
                <datalist id="options5">
                  <option value="全麻">全麻</option>
                  <option value="腰麻">腰麻</option>
                  <option value="连硬">连硬</option>
                  <option value="局麻">局麻</option>
                </datalist>
              </p>
              <p>
                <span class="shou-fang">手术方式</span>
                <input
                  list="options6"
                  id="shoushufang"
                  placeholder="请选择手术方式"
                />
                <datalist id="options6">
                  <option value="传统手术">传统手术</option>
                  <option value="无张力修补术">无张力修补术</option>
                  <option value="腔镜手术">腔镜手术</option>
                </datalist>
                <input
                  list="optionsdeil"
                  id="editable-deil"
                  placeholder="请选择手术方法"
                />
                <datalist id="optionsdeil">
                  <option value="Bassini法">Bassini法</option>
                  <option value="无张力修补术">无张力修补术</option>
                  <option value="Ferguson法">Ferguson法</option>
                  <option value="Shouldice法">Shouldice法</option>
                  <option value="Halsted法">Halsted法</option>
                  <option value="单纯平片修补术 Lichtenstein手术">
                    单纯平片修补术 Lichtenstein手术
                  </option>
                  <option value="网塞-平片修补术 Rutkow手术">
                    网塞-平片修补术 Rutkow手术
                  </option>
                  <option value="腹膜前修补术Kugel">腹膜前修补术Kugel</option>
                  <option value="巨大补片加强内脏囊手术GPRVS">
                    巨大补片加强内脏囊手术GPRVS
                  </option>
                  <option value="Gilbert手术">Gilbert手术</option>
                  <option value="Stoppa手术">Stoppa手术</option>
                  <option value="全腹膜外修补术TEP">全腹膜外修补术TEP</option>
                  <option value="经腹腔腹膜前修补术TAPP">
                    经腹腔腹膜前修补术TAPP
                  </option>
                </datalist>
              </p>
            </div>
            <div class="forth555-hang">
              <span class="zhi">植入补片信息</span>
              <div class="zhiinput">
                <input
                  type="text"
                  class="zhiname"
                  v-model="zhiname"
                  placeholder="请输入名称"
                />
                <input
                  type="text"
                  class="zhixing"
                  v-model="zhixing"
                  placeholder="请输入型号"
                />
                <input
                  type="text"
                  class="zhichi"
                  v-model="zhichi"
                  placeholder="请输入尺寸单位cm"
                />
                <input
                  type="text"
                  class="zhifee"
                  v-model="zhifee"
                  placeholder="请输入费用单位元"
                />
              </div>
            </div>
            <div class="fif255-hang">
              <p>
                <span>有无引流</span>
                <input
                  list="options225"
                  v-model="UserIn"
                  id="youyin"
                  placeholder="请选择有无引流"
                />
                <datalist id="options225">
                  <option value="有">有</option>
                  <option value="无">无</option>
                </datalist>
              </p>
              <p>
                <span>出血量</span>
                <input
                  type="text"
                  v-model="UserChu"
                  name=""
                  placeholder="请输入出血量"
                />
                ml
              </p>
            </div>
            <div class="six255-hang">
              <div class="block3">
                <span class="shoutime">手术时间:</span>

                <input type="text" v-model="xiaos" /><span>小时</span>
                <input type="text" v-model="fenz1" /><span>分钟</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <ZengLu /> -->
        <div class="zengshow">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="zeng-deil">
            <div class="doc-box">
              <div class="shdoc">
                <!-- <span class="docspan ">手术医师信息</span> -->
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div id="table2">
                    <div class="add2">
                      <input
                        type="text"
                        v-model="addDetail2.tiwen"
                        name="tiwen"
                        value=""
                        placeholder="请输入主刀医生姓名"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.zhuzhi"
                        name="zhuzhi"
                        value=""
                        placeholder="请输入主刀职位"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.xueya"
                        name="xueya"
                        value=""
                        placeholder="请输入一助医生姓名"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.yizhi"
                        name="yizhi"
                        value=""
                        placeholder="请输入一助职位"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.maibo"
                        name="maibo"
                        value=""
                        placeholder="请输入二助医生姓名"
                      />
                      <input
                        type="text"
                        v-model="addDetail2.erzhi"
                        name="erzhi"
                        value=""
                        placeholder="请输入二助医生职位"
                      />

                      <button @click="adddetail2" style="cursor: pointer">
                        新增
                      </button>
                    </div>
                    <table cellpadding="0" cellspacing="0" class="tableheader2">
                      <thead>
                        <tr>
                          <th>主刀医生姓名</th>
                          <th>主刀职位</th>
                          <th>一助医生姓名</th>
                          <th>一助职位</th>
                          <th>二助医生姓名</th>
                          <th>二助医生职位</th>
                        </tr>
                      </thead>
                      <tbody class="tbodys2">
                        <tr v-for="(item, index) in newsList2" :key="index">
                          <td>{{ item.tiwen }}</td>
                          <td>{{ item.zhuzhi }}</td>
                          <td>{{ item.xueya }}</td>
                          <td>{{ item.yizhi }}</td>
                          <td>{{ item.maibo }}</td>
                          <td>{{ item.erzhi }}</td>
                          <td>
                            <span
                              @click="deletelist2(item.id, index)"
                              class="delete2"
                              style="cursor: pointer"
                              >删除</span
                            ><span
                              class="edit2"
                              @click="edit2(item)"
                              style="cursor: pointer"
                              >编辑</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="mask2" v-if="editlist2">
                      <div class="mask2">
                        <div class="title2">
                          编辑
                          <span @click="editlist2 = false"> X </span>
                        </div>
                        <div class="content2">
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.tiwen"
                            name="tiwen"
                            value=""
                            placeholder="主刀医生姓名"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.zhuzhi"
                            name="zhuzhi"
                            value=""
                            placeholder="主刀职位"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.xueya"
                            name="xueya"
                            value=""
                            placeholder="一助医生姓名"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.yizhi"
                            name="yizhi"
                            value=""
                            placeholder="一助医生职位"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.maibo"
                            name="maibo"
                            value=""
                            placeholder="二助医生姓名"
                          />
                          <input
                            style="outline: none"
                            type="text"
                            v-model="addDetail2.erzhi"
                            name="erzhi"
                            value=""
                            placeholder="二助职位"
                          />
                          <button
                            @click="update2"
                            class="update"
                            style="cursor: pointer"
                          >
                            更新
                          </button>
                          <button
                            @click="editlist2 = false"
                            class="cal"
                            style="cursor: pointer"
                          >
                            取消
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>

            <div class="shuhou">
              <div class="shouhouti">
                <!-- <span class="shuhouspan">术后第一天生命体征</span> -->
                <happy-scroll
                  color="#DCDFE6"
                  hide-horizontal
                  size="6"
                  :min-length-v="3"
                  resize
                  right
                >
                  <div id="table">
                    <div class="add">
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.tiwen"
                        name="tiwen"
                        value=""
                        placeholder="请输入体温"
                      />
                      ℃
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.xueya"
                        name="xueya"
                        value=""
                        placeholder="请输入血压"
                      />
                      mmHg
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.maibo"
                        name="maibo"
                        value=""
                        placeholder="请输入脉搏"
                      />
                      次/分
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.niao"
                        name="niao"
                        value=""
                        placeholder="请输入尿量"
                      />
                      ml
                      <input
                        style="outline: none"
                        type="text"
                        v-model="addDetail.yinliu"
                        name="yinliu"
                        value=""
                        placeholder="请输入引流量"
                      />
                      ml
                      <input
                        type="text"
                        style="outline: none"
                        v-model="addDetail.tengtong"
                        name="tengtong"
                        value=""
                        placeholder="请输入疼痛评分"
                      />
                      1-10分
                      <button @click="adddetail" style="cursor: pointer">
                        新增
                      </button>
                    </div>
                    <table cellpadding="0" cellspacing="0" class="tableheader">
                      <thead>
                        <tr>
                          <th>序号</th>
                          <th>体温</th>
                          <th>血压</th>
                          <th>脉搏</th>
                          <th>尿量</th>
                          <th>引流量</th>
                          <th>疼痛评分</th>
                        </tr>
                      </thead>
                      <tbody class="tbodys">
                        <tr v-for="(item, index) in newsList" :key="index">
                          <td>术后第{{ index + 1 }}天生命体征:</td>
                          <td>{{ item.tiwen }}</td>
                          <td>{{ item.xueya }}</td>
                          <td>{{ item.maibo }}</td>
                          <td>{{ item.niao }}</td>
                          <td>{{ item.yinliu }}</td>
                          <td>{{ item.tengtong }}</td>
                          <td>
                            <span
                              @click="deletelist(item.id, index)"
                              class="delete"
                              >删除</span
                            ><span class="edit" @click="edit(item)">编辑</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="mask" v-if="editlist">
                      <div class="mask">
                        <div class="title">
                          编辑
                          <span @click="editlist = false"> X </span>
                        </div>
                        <div class="content">
                          <input
                            type="text"
                            v-model="addDetail.tiwen"
                            name="tiwen"
                            value=""
                            placeholder="请输入体温"
                          />
                          <input
                            type="text"
                            v-model="addDetail.xueya"
                            name="xueya"
                            value=""
                            placeholder="请输入血压"
                          />
                          <input
                            type="text"
                            v-model="addDetail.maibo"
                            name="maibo"
                            value=""
                            placeholder="请输入脉搏"
                          />
                          <input
                            type="text"
                            v-model="addDetail.niao"
                            name="niao"
                            value=""
                            placeholder="请输入尿量"
                          />
                          <input
                            type="text"
                            v-model="addDetail.yinliu"
                            name="yinliu"
                            value=""
                            placeholder="请输入引流量"
                          />
                          <input
                            type="text"
                            v-model="addDetail.tengtong"
                            name="tengtong"
                            value=""
                            placeholder="请输入疼痛评分"
                          />
                          <button @click="update" class="update2">更新</button>
                          <button @click="editlist = false" class="cal2">
                            取消
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </happy-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="Second77-Show">
          <p class="first11-title">上海市徐汇区腹壁疝多中心联合信息注册平台</p>
          <p class="youshan21">友“疝” 云</p>
          <span class="zhu-yuan1">新增录入住院手术病例 </span>
          <div class="two77-deils">
            <div class="twofirst77-hang">
              <p class="">
                <span class="shuxia">术后下床时间</span>
                <input type="text" v-model="value4" /><span class="tian"
                  >天</span
                >
              </p>

              <p class="block5">
                <span class="shujie">术后自行解尿时间</span>
                <input type="text" v-model="value5" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="twofirst77-hang">
              <p class="block6">
                <span class="shuqi">术后排气排便时间</span>
                <input type="text" v-model="value6" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="thid77-hang">
              <p class="block7">
                <span class="kang-time">抗菌素使用时间</span>
                <input type="text" v-model="value7" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="forth277-hang">
              <p class="block8">
                <span class="dao-na">导尿管拔除时间:</span>
                <input type="text" v-model="value8" /><span class="tian"
                  >天</span
                >
              </p>
              <p class="block9">
                <span class="yinba-time">引流管拔除时间:</span>
                <input type="text" v-model="value9" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="fif277-hang">
              <p>
                <span>术后并发症</span>
                <input
                  type="text"
                  v-model="shuhoubing"
                  name=""
                  placeholder="请选择术后并发症"
                />
              </p>
              <p class="block9">
                <span class="yinba-time">伤口拆线时间:</span>
                <input type="text" v-model="value10" /><span class="tian"
                  >天</span
                >
              </p>
            </div>
            <div class="six277-hang">
              <p>
                <span>伤口愈合情况</span>
                <input list="options7" id="shangyuhge" placeholder="愈合分类" />
                <datalist id="options7">
                  <option value="Ⅰ">Ⅰ</option>
                  <option value="Ⅱ">Ⅱ</option>
                  <option value="Ⅲ">Ⅲ</option>
                  <option value="Ⅳ">Ⅳ</option>
                </datalist>
              </p>
              <p>
                <!-- <span>疝分型</span> -->
                <input
                  list="options8"
                  id="editable-selectyuhe"
                  placeholder="愈合等级"
                />
                <datalist id="options8">
                  <option value="甲">甲</option>
                  <option value="乙">乙</option>
                  <option value="丙">丙</option>
                </datalist>
              </p>
            </div>
          </div>
          <button class="btn-sub" @click="saveBtnCon">提交</button>
        </div>
      </div>
      <div class="ulbox2">
        <p
          :class="[index == 0 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(0)"
        ></p>
        <p
          :class="[index == 1 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(1)"
        ></p>
        <p
          :class="[index == 2 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(2)"
        ></p>
        <p
          :class="[index == 3 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(3)"
        ></p>
        <p
          :class="[index == 4 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(4)"
        ></p>
        <p
          :class="[index == 5 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(5)"
        ></p>
        <p
          :class="[index == 6 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(6)"
        ></p>
        <p
          :class="[index == 7 ? 'liitem22' : 'liitem2']"
          @click="changeIndex(7)"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyAppFirst",

  data() {
    return {
      marginLeft: 0,
      xiaos: "",
      fenz1: "",
      index: 0,
      value24: "",
      baseUrl: "https://api.dahuayoushanyun.com",
      value3: "",
      value4: "",
      value: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      addDetail: {},
      editlist: false,
      editDetail: {},
      newsList: [],
      editid: "",
      addDetail2: {},
      editlist2: false,
      editDetail2: {},
      newsList2: [],
      editid2: "",
      value1: "",
      value2: "",
      name: "",
      userYear: "",
      userjob: "",
      UserGuo: "",
      UserMin: "",
      UserJi: "",
      UserID: "",
      UserDian: "",
      UserPhone: "",
      addressHos: "",
      zhuyuanhao: "",
      UserKe: "",
      zhuyuantian: "",
      UserFee: "",
      UserMedicne: "",
      yiliaofee: "",
      UserZhen: "",
      Userzheng: "",
      UserShan: "",
      xiyannian: "",
      yinjiunian: "",
      qitanian: "",
      UserYue: "",
      UserHun: "",
      UserTi: "",
      UserXue: "",
      UserMai: "",
      UserShen: "",
      UserTizhong: "",
      UserBmi: "",
      Userxue: "",
      UserNiao: "",
      UserFen: "",
      UserXuetang: "",
      UserShou1: "",
      UserGan: "",
      UserShen2: "",
      UserDianjie: "",
      UserNing: "",
      Userqita: "",
      UserIn: "",
      UserChu: "",
      zhiname: "",
      zhixing: "",
      zhichi: "",
      zhifee: "",
      zhudao: "",
      shuhouTime: "",
      niaoTime: "",
      shuhouqi: "",
      kangjuntime: "",
      daoniaotime: "",
      yinliubaTime: "",
      shuhoubing: "",
      shangchai: "",
      tengping: "",
      shuhouxue: "",
      shuhouniao2: "",
      shuhoufen: "",
      shuhoutang: "",
      shuhougan: "",
      shushen: "",
      shuhoudian: "",
      shuhouning: "",
      limitNum11: 10,
      limitNum22: 10,
      limitNum33: 10,
      limitNum44: 10,
      limitNum55: 10,
      limitNum66: 10,
      limitNum77: 10,
      limitNum88: 10,
      form: {
        file: "",
        type: 1,
      },
      qianxinUrl: [],
      form2: {
        file2: "",
        type2: 1,
      },
      qianxinUrl2: [],
      form3: {
        file3: "",
        type3: 1,
      },
      qianxinUrl3: [],
      form4: {
        file4: "",
        type4: 1,
      },
      qianxinUrl4: [],

      qianxinUrl55: [],
      form55: {
        file55: "",
        type55: 1,
      },
      qianxinUrl66: [],
      form66: {
        file66: "",
        type66: 1,
      },
      qianxinUrl77: [],
      form77: {
        file77: "",
        type77: 1,
      },
      qianxinUrl88: [],
      form88: {
        file88: "",
        type88: 1,
      },
    };
  },
  components: {},

  mounted() {},

  methods: {
    shanIMG(item) {
      this.qianxinUrl.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl.indexOf(itemcheng);
          this.qianxinUrl.splice(indexwei, 1);
        }
      });
    },
    shanIMG2(item) {
      this.qianxinUrl2.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl2.indexOf(itemcheng);
          this.qianxinUrl2.splice(indexwei, 1);
        }
      });
    },
    shanIMG3(item) {
      this.qianxinUrl3.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl3.indexOf(itemcheng);
          this.qianxinUrl3.splice(indexwei, 1);
        }
      });
    },
    shanIMG4(item) {
      this.qianxinUrl4.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl4.indexOf(itemcheng);
          this.qianxinUrl4.splice(indexwei, 1);
        }
      });
    },
    shanIMG5(item) {
      this.qianxinUrl55.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl55.indexOf(itemcheng);
          this.qianxinUrl55.splice(indexwei, 1);
        }
      });
    },
    shanIMG6(item) {
      this.qianxinUrl66.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl66.indexOf(itemcheng);
          this.qianxinUrl66.splice(indexwei, 1);
        }
      });
    },
    shanIMG7(item) {
      this.qianxinUrl77.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl77.indexOf(itemcheng);
          this.qianxinUrl77.splice(indexwei, 1);
        }
      });
    },
    shanIMG8(item) {
      this.qianxinUrl88.forEach((itemcheng) => {
        if (itemcheng == item) {
          var indexwei = this.qianxinUrl88.indexOf(itemcheng);
          this.qianxinUrl88.splice(indexwei, 1);
        }
      });
    },
    // 文件状态改变时的钩子
    fileChange11(file) {
      this.$refs.uploadExcel11.clearFiles(); //去掉文件列表
      this.form.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile11();
      }
    },

    uploadFile11() {
      this.$refs.uploadExcel11.submit();
      this.$refs.uploadExcel11.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange22(file) {
      this.$refs.uploadExcel22.clearFiles(); //去掉文件列表
      this.form2.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile22();
      }
    },

    uploadFile22() {
      this.$refs.uploadExcel22.submit();
      this.$refs.uploadExcel22.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form2.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl2.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange33(file) {
      this.$refs.uploadExcel33.clearFiles(); //去掉文件列表
      this.form3.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile33();
      }
    },

    uploadFile33() {
      this.$refs.uploadExcel33.submit();
      this.$refs.uploadExcel33.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form3.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl3.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange44(file) {
      this.$refs.uploadExcel44.clearFiles(); //去掉文件列表
      this.form4.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile44();
      }
    },

    uploadFile44() {
      this.$refs.uploadExcel44.submit();
      this.$refs.uploadExcel44.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form4.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl4.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    //   新店
    // 文件状态改变时的钩子
    fileChange55(file) {
      this.$refs.uploadExcel55.clearFiles(); //去掉文件列表
      this.form55.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile55();
      }
    },

    uploadFile55() {
      this.$refs.uploadExcel55.submit();
      this.$refs.uploadExcel55.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form55.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl55.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange66(file) {
      this.$refs.uploadExcel66.clearFiles(); //去掉文件列表
      this.form66.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile66();
      }
    },

    uploadFile66() {
      this.$refs.uploadExcel66.submit();
      this.$refs.uploadExcel66.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form66.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl66.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange77(file) {
      this.$refs.uploadExcel77.clearFiles(); //去掉文件列表
      this.form77.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile77();
      }
    },

    uploadFile77() {
      this.$refs.uploadExcel77.submit();
      this.$refs.uploadExcel77.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form77.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl77.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    // 文件状态改变时的钩子
    fileChange88(file) {
      this.$refs.uploadExcel88.clearFiles(); //去掉文件列表
      this.form88.file = file.raw;
      let { name } = file;

      let isPass = /\.(png|jpg)/g.test(name);
      if (!isPass) {
        this.$message({
          message: "请选择正确的格式上传",
          type: "error",
        });
      } else {
        this.uploadFile88();
      }
    },

    uploadFile88() {
      this.$refs.uploadExcel88.submit();
      this.$refs.uploadExcel88.clearFiles();
      let formData = new FormData();
      formData.append("files", this.form88.file);
      formData.append("type", [1]);
      this.$axios
        .post("https://api.dahuayoushanyun.com/Api/System/filesUpload", formData)
        .then((res) => {
          if (res.data.status == 1) {
            this.qianxinUrl88.push(res.data.data.path);
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },

    //新增
    adddetail() {
      //这里的思路应该是把this.addDetail传给服务端，然后加载列表this.newsList
      //this.newsList.push(this.addDetail)
      this.newsList.push({
        tiwen: this.addDetail.tiwen,
        xueya: this.addDetail.xueya,
        maibo: this.addDetail.maibo,
        niao: this.addDetail.niao,
        yinliu: this.addDetail.yinliu,
        tengtong: this.addDetail.tengtong,

        id: Math.floor(Math.random() * 1000000 + 1),
      });
    },
    //删除
    deletelist(id, i) {
      this.newsList.splice(i, 1);
    },
    //编辑
    edit(item) {
      this.editDetail = {
        tiwen: this.addDetail.tiwen,
        xueya: this.addDetail.xueya,
        maibo: this.addDetail.maibo,
        niao: this.addDetail.niao,
        yinliu: this.addDetail.yinliu,
        tengtong: this.addDetail.tengtong,
        id: item.id,
      };
      this.editlist = true;
      this.editid = item.id;
    },
    //确认更新
    update() {
      //编辑的话，也是传id去服务端
      //axios.get('url',{ID:id}).then((res) =>{
      //			加载列表
      //})
      let _this = this;
      for (let i = 0; i < _this.newsList.length; i++) {
        if (_this.newsList[i].id == this.editid) {
          _this.newsList[i] = {
            tiwen: this.addDetail.tiwen,
            xueya: this.addDetail.xueya,
            maibo: this.addDetail.maibo,
            niao: this.addDetail.niao,
            yinliu: this.addDetail.yinliu,
            tengtong: this.addDetail.tengtong,
            id: this.editid,
          };
          this.editlist = false;
        }
      }
    },
    //新增
    adddetail2() {
      //这里的思路应该是把this.addDetail传给服务端，然后加载列表this.newsList
      //this.newsList.push(this.addDetail)
      this.newsList2.push({
        tiwen: this.addDetail2.tiwen,
        xueya: this.addDetail2.xueya,
        zhuzhi: this.addDetail2.zhuzhi,
        maibo: this.addDetail2.maibo,
        yizhi: this.addDetail2.yizhi,
        niao: this.addDetail2.niao,
        erzhi: this.addDetail2.erzhi,

        id: Math.floor(Math.random() * 1000000 + 1),
      });
    },
    //删除
    deletelist2(id, i) {
      this.newsList2.splice(i, 1);
    },
    //编辑
    edit2(item) {
      this.editDetail2 = {
        tiwen: this.addDetail2.tiwen,
        xueya: this.addDetail2.xueya,
        zhuzhi: this.addDetail2.zhuzhi,
        maibo: this.addDetail2.maibo,
        yizhi: this.addDetail2.yizhi,
        niao: this.addDetail2.niao,
        erzhi: this.addDetail2.erzhi,
        id: item.id,
      };
      this.editlist2 = true;
      this.editid2 = item.id;
    },
    //确认更新
    update2() {
      //编辑的话，也是传id去服务端
      //axios.get('url',{ID:id}).then((res) =>{
      //			加载列表
      //})
      let _this = this;
      for (let i = 0; i < _this.newsList2.length; i++) {
        if (_this.newsList2[i].id == this.editid2) {
          _this.newsList2[i] = {
            tiwen: this.addDetail2.tiwen,
            xueya: this.addDetail2.xueya,
            zhuzhi: this.addDetail2.zhuzhi,
            maibo: this.addDetail2.maibo,
            yizhi: this.addDetail2.yizhi,
            niao: this.addDetail2.niao,
            erzhi: this.addDetail2.erzhi,

            id: this.editid2,
          };
          this.editlist2 = false;
        }
      }
    },
    change() {
      this.index++;
      if (this.index > 7) {
        this.index = 7;
      } else {
        this.marginLeft = 6.25 * -this.index;
      }

      if (this.marginLeft < -62.5) {
        this.marginLeft = -62.5;
      }
    },
    youchang() {
      this.index--;
      if (this.index < 0) {
        this.index = 0;
      } else {
        this.marginLeft += 6.25;
      }
    },
    changeIndex(index) {
      this.index = index;

      this.marginLeft = -this.index * 6.25;
    },
    formatTimeMills(timeMills) {
      var date = new Date(timeMills);
      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    saveBtnCon() {
      // 時間1
      var resDate1,
        resDate2,
        resDate3,
        resDate5,
        resDate6,
        resDate7,
        resDate8,
        resDate9,
        resDate10,
        resDate11;
      if (this.value1) {
        resDate1 = this.formatTimeMills(this.value1); //住院
      }
      if (this.value24) {
        resDate2 = this.formatTimeMills(this.value24); //住院
      }
      if (this.value2) {
        resDate3 = this.value2; //住院
      }

      if (this.value4) {
        resDate5 = this.value4; //住院
      }
      if (this.value5) {
        resDate6 = this.value5; //住院
      }
      if (this.value6) {
        resDate7 = this.value6; //住院
      }
      if (this.value7) {
        resDate8 = this.value7; //住院
      }
      if (this.value8) {
        resDate9 = this.value8; //住院
      }
      if (this.value9) {
        resDate10 = this.value9; //住院
      }
      if (this.value10) {
        resDate11 = this.value10; //住院
      }

      let selectSexVal = this.$(".userSex").find("option:selected").text();
      let UsersFu = this.$(".UsersFu").find("option:selected").text();
      let shanfen = this.$("#shanfen").val();
      let fuyayuan = this.$("#fuyayuan").val();
      let heji = this.$("#heji").val();
      let changzhun = this.$("#changzhun").find("option:selected").text();
      let daoniaozhun = this.$("#daoniaozhun").find("option:selected").text();
      let kangyu = this.$("#kangyu").find("option:selected").text();
      let mazuifang = this.$("#mazuifang").val();

      let shoushufang = this.$("#shoushufang").val(); //注意
      let editabledeil = this.$("#editable-deil").val();
      let youyin = this.$("#youyin").val();
      let arrshoushufang = {
        shoushufang: shoushufang,
        editabledeil: editabledeil,
      };
      let shanfen1 = this.$("#shanfen1").val();
      let shanfen2 = this.$("#shanfen2").val();
      let shanfen3 = this.$("#shanfen3").val();
      let shanfen4 = this.$("#shanfen4").val();
      let shanfen5 = this.$("#shanfen5").val();
      let shanfeng28 = this.$("#shanfeng28").val();
      let shangyuhge = this.$("#shangyuhge").val(); //注意
      let selectjia = this.$("#editable-selectyuhe").val();
      let arrshang = { shangeyu: shangyuhge, selectjia: selectjia };

      let editableyuval = this.$("#editableyu").val();
      let dengyuheVal = this.$("#dengyuhe").val();
      let dengyuhe = {
        editableyuval: editableyuval,
        dengyuheVal: dengyuheVal,
      };
      let xiyang = this.$("#xiyang").val();
      let yin = this.$("#yin").val();
      let editablesuifang = this.$("#editablesuifang").val();
      let editablesuifa = this.$("#editablesuifa").val();
      //上虞
      let bin = this.$("#bin").val();
      let ishave = this.$("#ishave").find("option:selected").text();
      let isShifang = this.$("#isShifang").val();
      let du = this.$("#du").val();
      let hunyun = this.$("#hunyun").val();
      let yue = this.$("#yue").val();
      let address = this.$("#address").val();
      let buwei = this.$("#editable-select").val();
      let fen = this.$("#fen").val();
      let listitem = {
        name: this.name,
        age: this.userYear,
        sex: selectSexVal,
        profession: this.userjob,
        country: this.UserGuo,
        nationality: this.UserMin,
        hometown: this.UserJi,
        card: this.UserID,

        phone: this.UserPhone,

        tel: this.UserDian,

        hospital: address,
        number: this.zhuyuanhao,

        department: this.UserKe,

        d_date: resDate1, //住院

        a_date: resDate2, //出院

        discharge: this.zhuyuantian,

        hospital_costs: this.UserFee,

        medicine_fee: this.UserMedicne,

        m_consumables: this.yiliaofee,

        diagnosis: fen,

        location: buwei,

        it_recurred: UsersFu,

        onset_time: resDate3, //发病

        zhongkuai: this.Userzheng,
        zhidi: shanfen1,
        yinglang: shanfen2,
        touguang: shanfen5,
        huina: shanfen3,
        tengtojng: shanfen4,

        hernia: shanfeng28,

        hernia_type: shanfen,

        pressure: fuyayuan,

        smoking: xiyang,

        drinking: yin,

        other: du,

        menstrual_history: yue,

        childbirth: hunyun,

        body_temperature: this.UserTi,

        blood_pressure: this.UserXue,
        pulse: this.UserMai,

        height: this.UserShen,

        weight: this.UserTizhong,

        bmi_value: this.UserBmi,
        fenz1: this.fenz1,
        xiaos: this.xiaos,
        comorbidity: heji,

        electrocardiogram: this.qianxinUrl,

        radiograph: this.qianxinUrl2,

        ultrasound: this.qianxinUrl3,

        ct: this.qianxinUrl4,

        blood_routine: this.Userxue,
        fecal_routine: this.UserFen,
        urine_routine: this.UserNiao,

        blood_sugar: this.UserXuetang,

        liver_function: this.UserGan,
        // 之前对

        kidney_function: this.UserShen2,

        electrolyte: this.UserDianjie,

        hemagglutination: this.UserNing,

        other_results: this.Userqita,

        b_preparation: changzhun,

        catheterization: daoniaozhun,

        antibiotics: kangyu,

        anesthesia: mazuifang,

        surgical_approach: arrshoushufang, //注意

        implant_patch_name: this.zhiname,

        implanted_patch_model: this.zhixing,

        implant_patch_size: this.zhichi,
        implantation_patch_cost: this.zhifee,

        // 引流

        about_drainage: youyin,

        bleeding_volume: this.UserChu,

        surgeon_information: this.newsList2,

        postoperative_information: this.shuhouTime,

        surgery_time: this.newsList,

        urination_time: resDate5,

        defecation_time: resDate6,

        defecation_time_after: resDate7,

        antibacterial_time: resDate8,

        catheter_removal_time: resDate9,

        drainage_tube_removal_time: resDate10,

        postoperative_complications: this.shuhoubing,

        suture_removal_time: resDate11,

        wound_healing: arrshang, //注意

        follow_up_interval: editablesuifang,

        fever: editablesuifa,

        pain_score_vas: this.tengping,

        wound_healing2: dengyuhe,

        complications_2: bin, //没有

        recurrence: ishave,

        // 心电图2
        ecg_2: this.qianxinUrl55,
        x_ray_2: this.qianxinUrl66,
        super_2: this.qianxinUrl77,
        ct2: this.qianxinUrl88,

        blood_routine_2: this.shuhouxue,

        urine_routine1: this.shuhouniao2,

        fecalroutin_2: this.shuhoufen,

        blood_sugar_2: this.shuhoutang,

        liver_function_2: this.shuhougan,

        kidney_function_2: this.shushen,

        electrolyte_2: this.shuhoudian,

        hemagglutination_2: this.shuhouning,

        is_follow_up: isShifang,
      };

      let list = [];
      Object.keys(listitem).forEach((item) => {
        if (listitem[item] == "" || listitem[item] == undefined) {
          delete listitem[item];
        }
      });

      list.push(listitem);

      let baseUrl = "https://api.dahuayoushanyun.com";
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "POST",
        url: `${baseUrl}/Api/Applets/add?user_access_token=${token}&type=1`,
        data: {
          list: list,
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.$router.push("/DocHou");
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.upxin-txt {
  font-size: rem(16);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #C4C4C4;
  line-height: rem(22);
  position: absolute;
  left: rem(190);
  top: rem(120);
}

.xin {
  position: absolute;
  width: rem(80);
  height: rem(80);
  opacity: 0.8;
  left: rem(190);
  top: rem(20);
}

.xin2 {
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
}

.first-xin {
  width: rem(400);
  height: rem(200);
  position: relative;
  display: flex;
  justify-content: space-around;
}

.first-xintxt {
  position: absolute;
  // top rem(-100)
}

.el--upload11, .el--upload33, .el--upload55, .el--upload66, .el--upload77, .el--upload88, .el--upload22, .el--upload44 {
  opacity: 0.4;
}

.el-upload-dragger {
  width: rem(300) !important;
  height: rem(200) !important;
  border: 1px solid #DCDFE6 !important;
}

.btn-sub {
  width: rem(230);
  height: rem(40);
  background: #0473C2;
  border-radius: rem(4);
  opacity: 0.96;
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  border: none;
  position: absolute;
  left: rem(480);
  bottom: rem(100);
  cursor: pointer;
  line-height: rem(25);
}

.enter-box {
  width: 100%;
}

.ShowLu .block1, .ShowLu .block4 {
  position: absolute;
  height: rem(60);
  top: rem(0);
  right: rem(330);
  // z-index value
}

.ShowLu .block2 {
  position: absolute;
  height: rem(60);
  top: rem(0);
  right: rem(120);
  // z-index value
}

.ShowLu .block3 {
  position: absolute;
  height: rem(60);
  top: rem(0);
  left: rem(130);
  // z-index value
}

.ShowLu .block412 {
  position: absolute;
  height: rem(60);
  top: rem(0);
  left: rem(200);
  // z-index value
}

.chutiem, .chutime, .shoutime {
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  position: absolute;
  left: rem(-100);
  top: rem(10);
}

.fatime {
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
}

.fatime2 {
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  position relative 
  left rem(-20)!important
}
.fen{
    font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  position relative 
  left rem(-60)!important
}
.ShowLu {
  width: 100%;
  height: 100vh;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: url('./assets/bju.png') no-repeat center / cover;
}

.showLun {
  width: rem(1200);
  height: rem(782);
  overflow: hidden;
  background: #FFFFFF;
  border-radius: rem(20);
  position: absolute;
  top: rem(100);
  left: rem(360);
}

.show-box {
  width: rem(13200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  display: flex;
  justify-content: flex-start;
}

.ulbox2 {
  width: rem(700);
  height: rem(40);
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  position: absolute;
  bottom: rem(20);
  left: rem(250);
}

.ulbox2 .liitem2 {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  background-color: #E3E3E3;
  margin-left: rem(20);
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  top: rem(10);
}

.ulbox2 .liitem22 {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  background-color: #0473C2;
  margin-left: rem(20);
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  top: rem(10);
}

.left2, .you2 {
  width: rem(40);
  height: rem(40);
  border-radius: 50%;
  background: #0473C2;
  border: none;
  font-size: rem(22);
  color: #ffffff;
  bottom: rem(20);
  cursor: pointer;
}

.you2 {
  right: rem(100);
}

.left2 {
  left: rem(100);
}

// 第一页
p {
  padding: 0;
  margin: 0;
}

.first11-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  position: relative;
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
}

.first11-title {
  font-size: rem(36);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0473C2;
  line-height: rem(50);
  position: absolute;
  top: rem(40);
  left: rem(264);
}

.first11-title span {
  font-size: rem(24);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0473C2;
  line-height: rem(33);
  position: relative;
  left: rem(20);
}

.first11-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.ShowLu .el-date-editor {
  position: absolute !important;
  left: rem(0) !important;
  top: rem(0) !important;
}

.first11-hang, .thid11-hang, .forth11-hang, .fif11-hang, .six11-hang, .seven11-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(30);
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.first11-hang p {
  width: rem(240);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.first11-hang p span, .chutime, .entertime {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.first11-hang p input {
  width: rem(140);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid11-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.youshan21 {
  font-size: rem(36);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: red;
  line-height: rem(50);
  position: absolute;
  top: rem(105);
  left: rem(260);
}

.zhu-yuan1 {
  font-size: rem(36);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0473C2;
  line-height: rem(50);
  position: absolute;
  top: rem(105);
  right: rem(220);
}

.thid11-hang p span, .first-xintxt {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.userSex, #ishave {
  width: rem(240);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: rem(22);
  font-size: rem(20);
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid11-hang p input {
  width: rem(240);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: rem(22);
  font-size: rem(20);
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth11-hang p, .fif11-hang p, .seven11-hang p {
  width: rem(420);
  display: flex;
  justify-content: space-around;
}

.six11-hang p {
  width: rem(480);
  display: flex;
  justify-content: space-around;
}

.hei {
  left: rem(60) !important;
}

.forth11-hang p span, .fif11-hang p span, .six11-hang p span, .seven11-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth11-hang p input, .fif11-hang p input, .six11-hang p input, .seven11-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Second22-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two22-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst22-hang, .thid22-hang, .eight22-hang, .forth222-hang, .six222-hang, .seven222-hang, .eight-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  position: relative;
  justify-content: space-between;
  font-size: rem(20);
}

.fif222-hang {
  width: 100%;
  height: rem(100);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.fif222-2hang {
  width: 100%;
  height: rem(140);
  display: flex;
  padding-bottom: rem(20);
  justify-content: flex-start;
  flex-wrap: wrap;
}

.twofirst22-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst22-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst22-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid22-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid22-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid22-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth222-hang p, .eight22-hang p, .six222-hang p, .seven222-hang p {
  width: rem(400);
  display: flex;
  height: rem(50);
  justify-content: space-between;
  margin-top: rem(10);
  line-height: rem(50);
}

.fif222-hang p {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: rem(40);
  line-height: rem(40);
  margin-top: rem(10);
}

.forth222-hang p span, .fif222-hang p span, .eight22-hang p span, .six222-hang p span, .seven222-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth222-hang p input, .eight22-hang p input, .six222-hang p input, .seven222-hang p input {
  width: rem(220);
  font-size: rem(18);
  height: rem(40);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  position: relative;
  left: rem(-10);
}

.fif222-hang p input {
  width: rem(240);
  font-size: rem(18);
  height: rem(40);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  margin-top: rem(10);
  margin-left: rem(20);
}

.fif222-2hang p {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: rem(40);
  line-height: rem(40);
  // margin-top: rem(10);
}

.forth222-2hang p span, .fif222-2hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth222-2hang p input {
  width: rem(220);
  font-size: rem(18);
  height: rem(40);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  position: relative;
  left: rem(-10);
}

.fif222-2hang p input {
  width: rem(240);
  font-size: rem(18);
  height: rem(40);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  margin-top: rem(10);
  margin-left: rem(20);
}

.UsersFu {
  width: rem(240);
  height: rem(40);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.third33-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.third33-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.thirdfirst33-hang, .thid33-hang, .forth233-hang, .fif233-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  font-size: rem(20);
  justify-content: space-between;
}

.thirdfirst33-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.thirdfirst33-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.thirdfirst33-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid33-hang p {
  width: rem(440);
  display: flex;
  height: rem(40);
  line-height: rem(40);
  justify-content: space-around;
}

.thid33-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid33-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth233-hang p, .fif233-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth233-hang p span, .fif233-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth233-hang p input, .fif233-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.xindian33 {
  width: 100%;
  height: rem(256);
  margin-top: rem(10);
  display: flex;
  justify-content: space-around;
}

.forth44-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.forth44-deils {
  width: rem(1000);
  height: rem(610);
  position: absolute;
  top: rem(202);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.forthfirstone44-hang {
  width: 100%;
  height: rem(196);
  display: flex;
  justify-content: space-around;
}

.xinbc {
  width: rem(500);
  height: rem(200);
  position: relative;
  display: flex;
  justify-content: space-around;
}

.upBc {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #C4C4C4;
  line-height: 22px;
  font-size: rem(20);
  position: absolute;
  bottom: rem(30);
  left: rem(270);
}

.upBc1 {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #C4C4C4;
  line-height: 22px;
  font-size: rem(20);
  position: absolute;
  bottom: rem(30);
  left: rem(240);
}

.up--Bc1 {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #C4C4C4;
  line-height: 22px;
  font-size: rem(20);
  position: absolute;
  bottom: rem(30);
  left: rem(265);
}

.xinB {
  width: rem(80);
  height: rem(80);
  position: absolute;
  top: rem(30);
  left: rem(260);
}

.xinB1 {
  width: rem(80);
  height: rem(80);
  position: absolute;
  top: rem(30);
  left: rem(240);
}

.xin--B1 {
  width: rem(80);
  height: rem(80);
  position: absolute;
  top: rem(30);
  left: rem(270);
}

.forthfirst44-hang, .thid44-hang, .forth244-hang, .fif244-hang, .six244-hang, .seven244-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.forthfirst44-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.forthfirst44-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.forthfirst44-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid44-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid44-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid44-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

#changzhun, #daoniaozhun, #kangyu {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth244-hang p, .fif244-hang p, .six244-hang p, .seven244-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth244-hang p span, .fif244-hang p span, .six244-hang p span, .seven244-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth244-hang p input, .fif244-hang p input, .eight44-hang p input, .six244-hang p input, .seven244-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.fif55-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.fif55-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.fif55-hang, .thid55-hang, .fif255-hang, .six255-hang, .seven255-hang, .eight55-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  font-size: rem(20);
  line-height: rem(40);
  display: flex;
  position: relative;
  justify-content: space-between;
}

.forth555-hang {
  width: 100%;
  height: rem(140);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.forth555-hang p {
  width: 100%;
  height: rem(140);
  margin-top: rem(20);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.fif55-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.fif55-hang p span {
  width: rem(130);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.fif-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid55-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid55-hang p span.ma {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.shou-fang {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: absolute;
  right: rem(358);
}

.thid55-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.fif255-hang p, .eight55-hang p, .six255-hang p, .seven255-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth555-hang .zhi {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.tian {
  font-size: rem(20);
  position: relative;
  left: rem(0) !important;
}

.forth555-hang .zhiinput {
  width: rem(900);
  height: rem(100);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.forth555-hang .zhiinput input {
  width: rem(240);
  height: rem(40);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  margin-left: rem(30);
}

.fif255-hang p span, .eight55-hang p span, .six255-hang p span, .seven255-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.fif255-hang p input, .eight55-hang p input, .six255-hang p input, .six255-hang input, .seven255-hang p input {
  width: rem(200);
  font-size: rem(18);
  height: rem(40);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Second77-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two77-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst77-hang, .thid77-hang, .forth277-hang, .fif277-hang, .six277-hang, .seven277-hang, .eight77-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  position: relative;
  justify-content: space-between;
}

.twofirst77-hang p {
  width: rem(580);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: flex-start;
}

.twofirst77-hang p span {
  width: rem(160);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst77-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid77-hang p {
  width: rem(600);
  display: flex;
  justify-content: space-around;
}

.thid77-hang p span {
  width: rem(200);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid77-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
  margin-left: rem(40);
}

.forth277-hang p, .fif277-hang p, .eight77-hang p, .seven277-hang p {
  width: rem(640);
  display: flex;
  justify-content: space-between;
}

.six277-hang p {
  width: rem(540);
  display: flex;
  justify-content: flex-start;
}

.six277-hang p input {
  margin-left: rem(20);
}

.forth277-hang p span, .fif277-hang p span, .eight77-hang p span, .six277-hang p span, .seven277-hang p span {
  width: rem(200);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth277-hang p input, .fif277-hang p input, .eight77-hang p input, .six277-hang p input, .seven277-hang p input {
  width: rem(200);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.Second88-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two88-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst88-hang, .fif288-hang, .six288-hang, .seven288-hang, .eight88-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.twofirst88-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst88-hang p span {
  width: rem(120);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst88-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid88-hang, .forth288-hang {
  width: 100%;
  height: rem(200);
  display: flex;
  justify-content: space-around;
}

.forth288-hang {
  margin-top: rem(10);
}

.Second89-Show {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.two89-deils {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.twofirst89-hang, .thid89-hang, .forth289-hang, .fif289-hang {
  width: 100%;
  height: rem(40);
  margin-top: rem(20);
  display: flex;
  justify-content: space-between;
}

.twofirst89-hang p {
  width: rem(380);
  height: rem(40);
  margin-left: rem(30);
  display: flex;
  justify-content: space-between;
}

.twofirst89-hang p span {
  width: rem(100);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
}

.twofirst89-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.thid89-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-around;
}

.thid89-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.thid89-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0ppx #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.forth289-hang p, .fif289-hang p {
  width: rem(400);
  display: flex;
  justify-content: space-between;
}

.forth289-hang p span, .fif289-hang p span {
  width: rem(140);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(42);
  position: relative;
  left: rem(28);
}

.forth289-hang p input, .fif289-hang p input {
  width: rem(240);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  background: #FFFFFF;
  box-shadow: rem(1) rem(1) rem(3) 0px #F2F2F2;
  border-radius: rem(4);
  border: rem(1) solid #DCDFE6;
  text-indent: rem(10);
  outline: none;
}

.zengshow {
  width: rem(1200);
  height: rem(782);
  background: #FFFFFF;
  border-radius: rem(20);
  // position: absolute;
  // top: rem(100);
  // left: rem(360);
  position: relative;
}

.zeng-deil {
  width: rem(1000);
  height: rem(510);
  position: absolute;
  top: rem(152);
  left: rem(90);
  display: flex;
  flex-direction: column;
}

.shuhou {
  width: 100%;
  height: rem(300);
  margin-top: rem(30);
  overflow: hidden;
}

.shdoc, .shuhou {
  width: 100%;
  height: rem(240);
  position: relative;
}

.shdoc .docspan {
  width: rem(120);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
  position: absolute;
  top: rem(100);
  left: rem(0);
}

.shuhou .shuhouspan {
  width: rem(180);
  font-size: rem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  line-height: rem(28);
  position: absolute;
  top: rem(100);
  left: rem(0);
}

#table2 {
  width: rem(1000);
}

.add2 {
  width: rem(900);
  padding: rem(10);
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.add2 input {
  margin-left: rem(10);
  width: rem(130);
  height: rem(30);
  font-size: rem(14);
  color: #000000;
  border-radius: rem(5);
  margin-top: rem(25);
  border: 1px solid #dcdfe6;
  outline: none;
}

.add2 button {
  width: rem(100);
  height: rem(40);
  position: absolute;
  top: rem(0);
  background: #023DC6;
  font-size: rem(16);
  text-align: center;
  line-height: rem(40);
  // box-shadow: 0px 20px 20px #91AAE2;
  opacity: 1;
  color: #ffffff;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
}

.tableheader2 th {
  width: rem(150);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  font-size: rem(14);
  height: rem(30);
  text-align: center;
  border: 1px solid #eee;
}

.tbodys2 td, .tbodys td {
  font-size: rem(14);
  width: rem(200);
  height: rem(40);
  font-size: rem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #484848;
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.delete2 {
  color: red;
}

.edit2 {
  color: #008cd5;
}

#mask2 {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}

.mask2 {
  width: rem(500);
  height: rem(450);
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 47;
  border-radius: 5px;
}

.title2 {
  width: 100%;
  color: #023DC6;
  font-size: rem(26);
  line-height: rem(30);
}

.content2 {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.title2 span {
  width: rem(30);
  height: rem(30);
  color: red;
  position: absolute;
  right: rem(10);
  cursor: pointer;
}

.content2 input {
  width: rem(300);
  height: rem(30);
  margin-bottom: rem(20);
  margin-left: rem(80);
}

.content button, .content2 button {
  width: rem(200);
  height: rem(60);
  line-height: rem(60);
  text-align: center;
  font-size: rem(22);
  color: #ffffff;
  background: #023DC6;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
  position: absolute;
}

.cal {
  right: rem(60);
  bottom: rem(-60);
  cursor: pointer;
}

.update {
  left: rem(10);
  bottom: rem(-60);
  cursor: pointer;
}

.cal2 {
  right: rem(60);
  bottom: rem(-60);
  cursor: pointer;
}

.update2 {
  left: rem(10);
  bottom: rem(-60);
}

.ShowLu .happy-scroll-content {
  position: relative !important;
  top: rem(-150) !important;
}

.title span {
  width: rem(30);
  height: rem(30);
  color: red;
  position: absolute;
  right: rem(10);
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

#table {
  width: rem(1000);
}

.add {
  display: flex;
  width: 100%;
  height: rem(50);
  justify-content: flex-start;
  position: relative;
  padding: rem(15);
  font-size: rem(20);
  line-height: rem(80);
}

.add input {
  margin-left: rem(10);
  width: rem(60);
  height: rem(30);
  font-size: rem(14);
  color: #000000;
  border-radius: rem(5);
  border: 1px solid #dcdfe6;
  outline: none;
  margin-top: rem(25);
}

.add button {
  width: rem(100);
  height: rem(40);
  position: absolute;
  top: rem(0);
  background: #023DC6;
  font-size: rem(16);
  text-align: center;
  line-height: rem(40);
  // box-shadow: 0px 20px 20px #91AAE2;
  opacity: 1;
  color: #ffffff;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
}

.tbodys td {
  font-size: rem(14);
  width: rem(200);
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.delete {
  color: red;
  cursor: pointer;
}

.edit {
  color: #008cd5;
  cursor: pointer;
}

.tableheader2 input, .tableheader input {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 15px;
}

#mask {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
}

.mask {
  width: rem(600);
  height: rem(500);
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 47;
  border-radius: 5px;
}

.title {
  font-size: rem(26);
}

.content input {
  width: rem(300);
  height: rem(30);
  margin-bottom: rem(20);
  margin-left: rem(80);
}

.title span {
  float: right;
  cursor: pointer;
}
</style>
