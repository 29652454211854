<template>
  <div class="container">
    <div class="one" v-if="currentPage4 == 1">
      <el-form>
        <el-form-item label="姓名：">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="年龄：">
          <el-input v-model="form.age" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="籍贯：">
          <el-input v-model="form.hometown" placeholder="请输入籍贯"></el-input>
        </el-form-item>
        <el-form-item label="职业：">
          <el-input
            v-model="form.profession"
            placeholder="请输入职业"
          ></el-input>
        </el-form-item>
        <el-form-item label="国籍：">
          <el-input v-model="form.country" placeholder="请输入国籍"></el-input>
        </el-form-item>
        <el-form-item label="民族：">
          <el-input
            v-model="form.nationality"
            placeholder="请输入民族"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input v-model="form.card" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="电话号码：">
          <el-input
            v-model="form.phone"
            placeholder="请输入电话号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="form.tel" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="所在医院：">
          <el-select v-model="form.hospital" placeholder="请选择所在医院">
            <el-option label="大华医院" value="大华医院"></el-option>
            <el-option label="徐汇中心医院" value="徐汇中心医院"></el-option>
            <el-option label="第八人民医院" value="第八人民医院"></el-option>
            <el-option
              label="社区卫生服务中心"
              value="社区卫生服务中心"
            ></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科室：">
          <el-input
            v-model="form.department"
            placeholder="请输入科室"
          ></el-input>
        </el-form-item>
        <el-form-item label="手术日期：">
          <el-date-picker
            align="right"
            v-model="form.d_date"
            type="datetime"
            placeholder="请选择手术日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="复诊日期：">
          <el-date-picker
            align="right"
            v-model="form.a_date"
            type="datetime"
            placeholder="请选择复诊日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="随访时间间隔：" style="display:flex;">
          <el-select
            v-model="form.follow_up_interval"
            placeholder="请选择随访时间间隔"
          >
            <el-option
              label="手术后1月内每周一次"
              value="手术后1月内每周一次"
            ></el-option>
            <el-option
              label="术后1月-6月内每月一次"
              value="术后1月-6月内每月一次"
            ></el-option>
            <el-option
              label="术后6月后每年一次"
              value="术后6月后每年一次"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有无发热：">
          <el-select v-model="form.fever">
            <el-option label="有" value="有"></el-option>
            <el-option label="无" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="疼痛评分VAS：">
          <el-input
            v-model="form.pain_score_vas"
            placeholder="请输入疼痛评分"
          ></el-input>
        </el-form-item>
        <el-form-item label="伤口愈合情况：" style="flex:50%">
          <el-select
            v-model="yuhefenlei"
            placeholder="请选择愈合分类"
            style="width:14.9vw;margin-right:2vw;"
          >
            <el-option label="Ⅰ" value="Ⅰ"></el-option>
            <el-option label="Ⅱ" value="Ⅱ"></el-option>
            <el-option label="Ⅲ" value="Ⅲ"></el-option>
            <el-option label="Ⅳ" value="Ⅳ"></el-option>
          </el-select>
          <el-select
            v-model="yuhedengji"
            placeholder="请选择愈合等级"
            style="width:12.9vw;"
          >
            <el-option label="甲" value="甲"></el-option>
            <el-option label="乙" value="乙"></el-option>
            <el-option label="丙" value="丙"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有无并发症：" style="flex:50%">
          <el-select v-model="form.complications_2">
            <el-option label="无" value="无"></el-option>
            <el-option label="伤口积液" value="伤口积液"></el-option>
            <el-option label="伤口裂开" value="伤口裂开"></el-option>
            <el-option label="阴囊水肿" value="阴囊水肿"></el-option>
            <el-option label="下肢血栓" value="下肢血栓"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有无复发：" style="flex:50%">
          <el-select v-model="form.recurrence">
            <el-option label="有" value="有"></el-option>
            <el-option label="无" value="无"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="two" v-if="currentPage4 == 2">
      <el-form :model="form">
        <el-form-item label="心电图：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl55.length != 0">
              <img
                v-for="(item, index) in xinList"
                :key="index"
                :src="item"
                alt=""
                @click="deleteXin(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel55"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile55"
              list-type="picture-card"
              :on-remove="handleRemove55"
            >
              <i class="el-icon-plus"></i>
              <span>上传心电图</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="胸片：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl66.length != 0">
              <img
                v-for="(item, index) in xiongList"
                :key="index"
                :src="item"
                @click="deleteXiong(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel66"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile66"
              list-type="picture-card"
              :on-remove="handleRemove66"
            >
              <i class="el-icon-plus"></i>
              <span>上传胸片</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="B超：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl77.length != 0">
              <img
                v-for="(item, index) in bList"
                :key="index"
                :src="item"
                @click="deleteB(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel77"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile77"
              list-type="picture-card"
              :on-remove="handleRemove77"
            >
              <i class="el-icon-plus"></i>
              <span>上传B超</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="CT：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl88.length != 0">
              <img
                v-for="(item, index) in ctList"
                :key="index"
                :src="item"
                @click="deleteCT(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel88"
              action="#"
              limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile88"
              list-type="picture-card"
              :on-remove="handleRemove88"
            >
              <i class="el-icon-plus"></i>
              <span>上传CT</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="血生化：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl99.length != 0">
              <img
                v-for="(item, index) in xshList"
                :key="index"
                :src="item"
                @click="deleteXSH(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel99"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile99"
              list-type="picture-card"
              :on-remove="handleRemove99"
            >
              <i class="el-icon-plus"></i>
              <span>上传血生化</span>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="其他：">
          <div class="pic_box">
            <div class="hasUpload_pic" v-if="qianxinUrl10.length != 0">
              <img
                v-for="(item, index) in otherList"
                :key="index"
                :src="item"
                @click="deleteOther(index)"
              />
            </div>
            <el-upload
              ref="uploadExcel10"
              action="#"
              :limit="10"
              :multiple="true"
              accept=".png,.jpg"
              name="file"
              :headers="{
                Authorization: `Bearer${$store.state.token}`,
              }"
              :http-request="uploadFile10"
              list-type="picture-card"
              :on-remove="handleRemove10"
            >
              <i class="el-icon-plus"></i>
              <span>上传其他</span>
            </el-upload>
          </div>
        </el-form-item>
        <!-- <el-form-item label="血常规：">
          <el-input
            v-model="form.blood_routine_2"
            placeholder="请输入血常规检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="尿常规：">
          <el-input
            v-model="form.urine_routine1"
            placeholder="请输入尿常规检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="粪常规：">
          <el-input
            v-model="form.fecalroutin_2"
            placeholder="请输入粪常规检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="血糖：">
          <el-input
            v-model="form.blood_sugar_2"
            placeholder="请输入血糖检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="肝功能：">
          <el-input
            v-model="form.liver_function_2"
            placeholder="请输入肝功能检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item>
        <el-form-item label="肾功能：">
          <el-input
            v-model="form.kidney_function_2"
            placeholder="请输入肾功能检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="three" v-if="currentPage4 == 3">
      <el-form :model="form">
        <!-- <el-form-item label="电解质：">
          <el-input
            v-model="form.electrolyte_2"
            placeholder="请输入电解质检测结果"
            style="width:29.9vw"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="血凝：">
          <el-input
            v-model="form.hemagglutination_2"
            placeholder="请输入血凝"
            style="width:29.9vw"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="是否失访：">
          <el-select v-model="form.is_follow_up">
            <el-option label="死亡" value="死亡"></el-option>
            <el-option label="搬离" value="搬离"></el-option>
            <el-option label="拒绝" value="拒绝"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
    <div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        layout=" prev, pager, next"
        :page-size="1"
        :total="num"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "suifang",
  props: ["suiId"],
  data() {
    return {
      baseUrl: "https://api.dahuayoushanyun.com",
      currentPage4: 1,
      num: 3,
      form: {
        name: "", // 姓名
        age: "", // 年龄
        sex: "", // 性别
        profession: "", // 职业
        country: "", // 国籍
        nationality: "",
        hometown: "",
        card: "",
        phone: "",
        tel: "",
        hospital: "",
        department: "",
        d_date: "",
        a_date: "",
        follow_up_interval: "",
        fever: "",
        pain_score_vas: "",
        wound_healing2: "",
        complications_2: "",
        recurrence: "",
        ecg_2: "",
        x_ray_2: "",
        super_2: "",
        ct2: "",
        // blood_routine_2: "",
        // urine_routine1: "",
        // fecalroutin_2: "",
        // blood_sugar_2: "",
        // liver_function_2: "",
        // kidney_function_2: "",
        // electrolyte_2: "",
        // hemagglutination_2: "",
        is_follow_up: "",
        upload_blood_biochemistry: "",
        upload_other: "",
      },
      yuhefenlei: "", // 愈合分类
      yuhedengji: "", // 愈合等级
      qianxinUrl55: [],
      qianxinUrl66: [],
      qianxinUrl77: [],
      qianxinUrl88: [],
      qianxinUrl99: [],
      qianxinUrl10: [],
      xinList: [],
      ctList: [],
      bList: [],
      xiongList: [],
      xshList: [],
      otherList: [],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage4 = val;
      let value = "";
      let value2 = "";
      let value3 = "";
      let value4 = "";
      let value5 = "";
      let value6 = "";
      if (this.currentPage4 == 2) {
        console.log(this.qianxinUrl55, "是的话会");
        for (let i in this.qianxinUrl55) {
          value = this.baseUrl + this.qianxinUrl55[i].value;
          this.xinList.push(value);
          this.xinList = [...new Set(this.xinList)];
        }
        for (let i in this.qianxinUrl66) {
          value2 = this.baseUrl + this.qianxinUrl66[i].value;
          this.xiongList.push(value2);
          this.xiongList = [...new Set(this.xiongList)];
        }
        for (let i in this.qianxinUrl77) {
          value3 = this.baseUrl + this.qianxinUrl77[i].value;
          this.bList.push(value3);
          this.bList = [...new Set(this.bList)];
        }
        for (let i in this.qianxinUrl88) {
          value4 = this.baseUrl + this.qianxinUrl88[i].value;
          this.ctList.push(value4);
          this.ctList = [...new Set(this.ctList)];
        }
        for (let i in this.qianxinUrl88) {
          value4 = this.baseUrl + this.qianxinUrl88[i].value;
          this.ctList.push(value4);
          this.ctList = [...new Set(this.ctList)];
        }
        for (let i in this.qianxinUrl99) {
          value5 = this.baseUrl + this.qianxinUrl99[i].value;
          this.xshList.push(value5);
          this.xshList = [...new Set(this.xshList)];
        }
        for (let i in this.qianxinUrl10) {
          value6 = this.baseUrl + this.qianxinUrl10[i].value;
          this.otherList.push(value6);
          this.otherList = [...new Set(this.otherList)];
        }
      }
    },
    // 删除心电图
    deleteXin(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl55.splice(index, 1);
        this.xinList.splice(index, 1);
      });
    },
    // 删除胸片
    deleteXiong(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl66.splice(index, 1);
        this.xiongList.splice(index, 1);
      });
    },
    // 删除b超
    deleteb(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl77.splice(index, 1);
        this.bList.splice(index, 1);
      });
    },
    // 删除ct
    deleteCT(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl88.splice(index, 1);
        this.ctList.splice(index, 1);
      });
    },
    // 删除血生化
    deleteXSH(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl99.splice(index, 1);
        this.xshList.splice(index, 1);
      });
    },
    // 删除其他
    deleteOther(index) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btnClass",
        type: "warning",
      }).then(() => {
        this.qianxinUrl10.splice(index, 1);
        this.otherList.splice(index, 1);
      });
    },
    // 心电图
    uploadFile55(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl55.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl55, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove55(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl55) {
        if (this.qianxinUrl55[i].key === file.uid) {
          this.qianxinUrl55.splice(i, 1);
        }
        console.log(this.qianxinUrl55, "434");
      }
    },

    // 胸片
    uploadFile66(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl66.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl66, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove66(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl66) {
        if (this.qianxinUrl66[i].key === file.uid) {
          this.qianxinUrl66.splice(i, 1);
        }
        console.log(this.qianxinUrl66, "434");
      }
    },

    // B超
    uploadFile77(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl77.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl77, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove77(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl77) {
        if (this.qianxinUrl77[i].key === file.uid) {
          this.qianxinUrl77.splice(i, 1);
        }
        console.log(this.qianxinUrl77, "434");
      }
    },
    // CT
    uploadFile88(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl88.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl77, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove88(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl88) {
        if (this.qianxinUrl88[i].key === file.uid) {
          this.qianxinUrl88.splice(i, 1);
        }
        console.log(this.qianxinUrl88, "434");
      }
    },
    // 血生化
    uploadFile99(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl99.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl99, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove99(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl99) {
        if (this.qianxinUrl99[i].key === file.uid) {
          this.qianxinUrl99.splice(i, 1);
        }
        console.log(this.qianxinUrl99, "434");
      }
    },
    // 其他
    uploadFile10(item) {
      console.log(item.file, "1234");
      let formData = new FormData();
      formData.append("files", item.file);
      const uid = item.file.uid;
      this.$axios
        .post(`${this.baseUrl}/Api/System/filesUpload`, formData)
        .then((res) => {
          console.log(res, "是对哦奥数");
          if (res.data.status == 1) {
            this.qianxinUrl10.push({ value: res.data.data.path, key: uid });
            console.log(this.qianxinUrl10, "是基地啊家死哦");
          }
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
        });
    },
    handleRemove10(file, fileList) {
      console.log(file, "三年大水牛的哈");
      for (const i in this.qianxinUrl10) {
        if (this.qianxinUrl10[i].key === file.uid) {
          this.qianxinUrl10.splice(i, 1);
        }
        console.log(this.qianxinUrl10, "434");
      }
    },

    formatTimeMills(timeMills) {
      var date = new Date(timeMills);
      var timeStr = date.getFullYear() + "-";
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += "0";
      }
      timeStr += date.getMonth() + 1 + "-";
      timeStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      timeStr += " ";
      timeStr += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      timeStr += ":";
      timeStr +=
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      timeStr += ":";
      timeStr +=
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    // 提交
    submit() {
      if (this.form.d_date) {
        this.form.d_date = this.formatTimeMills(this.form.d_date);
      } else {
        this.form.d_date;
      }
      if (this.form.a_date) {
        this.form.a_date = this.formatTimeMills(this.form.a_date);
      } else {
        this.form.a_date = "";
      }
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      (this.form.ecg_2 = this.qianxinUrl55),
        (this.form.x_ray_2 = this.qianxinUrl66),
        (this.form.super_2 = this.qianxinUrl77),
        (this.form.ct2 = this.qianxinUrl88),
        (this.form.upload_blood_biochemistry = this.qianxinUrl99),
        (this.form.upload_other = this.qianxinUrl10),
        (this.form.wound_healing2 = {
          shangeyu: this.yuhefenlei,
          selectjia: this.yuhedengji,
        });
      if (this.suiId == "") {
        if (this.form.a_date != "" && this.form.d_date != "") {
          this.$axios({
            method: "POST",
            url: `${this.baseUrl}/Api/Applets/add?user_access_token=${token}&type=2`,
            data: {
              list: this.form,
            },
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }).then((res) => {
            if (res.data.status == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$router.push("/DocHou");
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else if (this.form.a_date == "") {
          this.$message({
            message: "请输入复诊日期",
            type: "error",
          });
        } else {
          this.$message({
            message: "请输入手术日期",
            type: "error",
          });
        }
      } else {
        if (this.form.a_date != "" && this.form.d_date != "") {
          this.$axios({
            method: "POST",
            url: `${this.baseUrl}/Api/Applets/save?user_access_token=${token}&type=2`,
            data: {
              list: this.form,
            },
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }).then((res) => {
            if (res.data.status == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$router.push("/DocHou");
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else if (this.form.a_date == "") {
          this.$message({
            message: "请输入复诊日期",
            type: "error",
          });
        } else {
          this.$message({
            message: "请输入手术日期",
            type: "error",
          });
        }
      }
    },
    getUidData() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/Api/Applets/ysOne?user_access_token=${token}&type=2`,
        data: {
          list: [
            {
              id: this.suiId,
            },
          ],
        },
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        this.form = res.data.data;
        if (this.form.ecg_2 != null) {
          this.qianxinUrl55 = this.form.ecg_2;
        }
        if (this.form.x_ray_2 != null) {
          this.qianxinUrl66 = this.form.x_ray_2;
        }
        if (this.form.super_2 != null) {
          this.qianxinUrl77 = this.form.super_2;
        }
        if (this.form.ct2 != null) {
          this.qianxinUrl88 = this.form.ct2;
        }
        if (this.form.upload_blood_biochemistry != null) {
          this.qianxinUr99 = this.form.upload_blood_biochemistry;
        }
        if (this.form.upload_other != null) {
          this.qianxinUrl10 = this.form.upload_other;
        }
        if (this.form.wound_healing2 != null) {
          this.yuhedengji = this.form.wound_healing2.selectjia;
          this.yuhefenlei = this.form.wound_healing2.shangeyu;
        }
        let value = "";
        let value2 = "";
        let value3 = "";
        let value4 = "";
        let value5 = "";
        let value6 = "";
        for (let i in this.qianxinUrl55) {
          value = this.baseUrl + this.qianxinUrl55[i].value;
          this.xinList.push(value);
          this.xinList = [...new Set(this.xinList)];
        }
        for (let i in this.qianxinUrl66) {
          value2 = this.baseUrl + this.qianxinUrl66[i].value;
          this.xiongList.push(value2);
          this.xiongList = [...new Set(this.xiongList)];
        }
        for (let i in this.qianxinUrl77) {
          value3 = this.baseUrl + this.qianxinUrl77[i].value;
          this.bList.push(value3);
          this.bList = [...new Set(this.bList)];
        }
        for (let i in this.qianxinUrl88) {
          value4 = this.baseUrl + this.qianxinUrl88[i].value;
          this.ctList.push(value4);
          this.ctList = [...new Set(this.ctList)];
        }
        for (let i in this.qianxinUrl88) {
          value4 = this.baseUrl + this.qianxinUrl88[i].value;
          this.ctList.push(value4);
          this.ctList = [...new Set(this.ctList)];
        }
        for (let i in this.qianxinUrl99) {
          value5 = this.baseUrl + this.qianxinUrl99[i].value;
          this.xshList.push(value5);
          this.xshList = [...new Set(this.xshList)];
        }
        for (let i in this.qianxinUrl10) {
          value6 = this.baseUrl + this.qianxinUrl10[i].value;
          this.otherList.push(value6);
          this.otherList = [...new Set(this.otherList)];
        }
      });
    },
  },
  created() {
    if (this.suiId != "") {
      this.getUidData();
    } else {
      this.form.name = ""; // 姓名
      this.form.age = ""; // 年龄
      this.form.sex = ""; // 性别
      this.form.profession = ""; // 职业
      this.form.country = ""; // 国籍
      this.form.nationality = "";
      this.form.hometown = "";
      this.form.card = "";
      this.form.phone = "";
      this.form.tel = "";
      this.form.hospital = "";
      this.form.department = "";
      this.form.d_date = "";
      this.form.a_date = "";
      this.form.follow_up_interval = "";
      this.form.fever = "";
      this.form.pain_score_vas = "";
      this.form.wound_healing2 = "";
      this.form.complications_2 = "";
      this.form.recurrence = "";
      this.form.ecg_2 = "";
      this.form.x_ray_2 = "";
      this.form.super_2 = "";
      this.form.ct2 = "";
      // this.form.blood_routine_2 = ""
      // this.form.urine_routine1 = ""
      // this.form.fecalroutin_2 = ""
      // this.form.blood_sugar_2 = ""
      // this.form.liver_function_2 = ""
      // this.form.kidney_function_2 = ""
      // this.form.electrolyte_2 = ""
      // this.form.hemagglutination_2 = ""
      this.form.is_follow_up = "";
      this.qianxinUrl55 = [];
      this.qianxinUrl66 = [];
      this.qianxinUrl77 = [];
      this.qianxinUrl88 = [];
    }
  },
};
</script>

<style lang="stylus" scoped>
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height 100%;
    font-size: 16px;
}

.one .el-form,.two .el-form,.three .el-form {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px !important;
}
::v-deep .one .el-form-item,::v-deep .three .el-form-item {
    flex: 50%;
    display: flex;
    height 5vh;
    margin-bottom: 2.5vh;
}
::v-deep .two .el-form-item {
    flex: 50%;
    display: flex;
    margin-bottom: 2.5vh;
}
::v-deep .one .el-input__inner,::v-deep .two .el-input__inner,::v-deep .three .el-input__inner {
    // width: 29.9vw;
    height: 4vh !important
}
::v-deep .one .el-form-item__content,::v-deep .one .el-select,::v-deep .three .el-select {
    width: 29.9vw;
}
::v-deep .one .el-form-item__label,::v-deep .three .el-form-item__label,::v-deep .four .el-form-item__label {
    width: 25%;
    font-size: 14px;
}
::v-deep .two .el-form-item__label {
  width: 20%;
}
::v-deep .six .el-form-item__label {
    width: 28%;
    font-size: 14px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15px !important;
}
::v-deep.date-picker  .el-input__inner {
  padding: 0 15px; // 这个15是el-input组件默认的值
}
::v-deep .el-input__prefix {
    position: absolute;
    left: 27vw; // left根据实际情况定值
    top: 0;
}
.inhospital {
    width: 100%;
    display:flex;
    justify-content: space-between;
    // align-items:center;
    line-height:5.5vh;
    // position: relative;
    height:5vh;
    margin-bottom: 2.5vh;
}
.sign  {
    width:2.5vw;
    font-size: rem(9);
    // position: absolute;
    // right: 43vw;
    // top: 2.5vh;
}

.el-pagination {
  position:relative;
  margin-left: 60vw;
}
::v-deep .el-upload--picture-card {
    display:flex;
    flex-direction:column;
    justify-content: center;
    line-height:4vh;
    border: 1px solid rgba(0,0,0,0.15);
    width: 12.5vw;
    height: 16.6vh;
    background: #fff;
}
.three .el-button {
    margin-top:50vh;
    margin-left:74vw;
    margin-bottom: 2vh;
    width: 7vw;
}
::v-deep .el-upload-list--picture-card {
  width:33.9vw;
  // height:10vh;
  display:flex;
  flex-wrap: wrap;
  margin-bottom:0.5vh;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width:10vw;
  height: 10vh;
  display: normal;
}
.hasUpload_pic {
  width:33.9vw;
  // height:10vh;
  display:flex;
  // overflow-x:hidden;
  flex-wrap:wrap;
}
.hasUpload_pic img {
  width:10vw;
  height:10vh;
  margin-right:0.5vw;
}
</style>
