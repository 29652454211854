import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {

        token: "",
        uid:"",
        status:"",
        id:"",
        arrData:[]

    },
    getters: {

        setToken(state) {
            if (state) {
                return state.token
            }
        },
        getUid(state){
            if(state){
                return state.uid
            }
        },
        getStatus(state){
            if(state){
                return state.status
            }
        },
        getID(state){
            if(state){
                return state.id
            }
        },
        getArrayData(state){
            if(state){
                return state.arrData
            }
        }
    },
    mutations: {

        setToken(state) {
            state.token = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user_access_token"))))
        },
        setUID(state){
            state.uid=JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("uid"))))
        },
        setStatus(state){
            state.status=JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("status"))))
        },
        setID(state){
            state.id=JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("id"))))
        },
        setArrayData(state){
            state.arrData=JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("arrData"))))
        }

    },
    actions: {
        setUserToken({ commit }) {
            commit("setToken")
        },
        setUid({commit}){
            commit("setUID")
        },
        setStatus({commit}){
            commit("setStatus")
        },
        //用户登录id
        setId({commit}){
            commit("setID")
        },
        setArrayData({commit}){
            commit("setArrayData")
        }

    },
})