import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import store from "./store/index"
import $ from 'jquery'
import $axios from "axios"
import ElementUI from 'element-ui';
// import ElementArea from 'element-china-area-data'
import 'element-ui/lib/theme-chalk/index.css';
// 引入css
import 'vue-happy-scroll/docs/happy-scroll.css'
import { HappyScroll } from 'vue-happy-scroll'
//自定义组件名
Vue.component('happy-scroll', HappyScroll)
Vue.config.productionTip = false

Vue.prototype.$ = $;
Vue.prototype.$axios = $axios
Vue.use(ElementUI);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: false,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
// Vue.use(ElementArea)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
