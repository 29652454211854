<template>
  <div class="container">
    <div class="top_box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="留言" name="first">
          <div
            class="msg_content"
            v-for="(item, index) in msgList"
            :key="index"
          >
            <div class="content_title">
              <span style="font-size:0.9vw;">{{ item.user_name }}</span>
              <span style="font-size:0.7vw;color:#000000">{{
                item.create_dt
              }}</span>
            </div>
            <div class="msg_body">{{ item.forum_content }}</div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
      @current-change="handleCurrentChange"
        :current-page="currentPage4"
        layout=" total,prev, pager, next"
        :page-size="pageSize"
        :total="num"
      >
      </el-pagination>
    </div>
    <div class="bottom_box">
      <el-tabs
        v-model="activeNameTwo"
        style="margin-top:7vh;margin-bottom: 2vh;"
      >
        <el-tab-pane label="我要留言" name="first">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6 }"
            placeholder="请输入留言"
            v-model="textarea2"
          >
          </el-input>
        </el-tab-pane>
      </el-tabs>
      <div class="btn_box">
        <el-button type="primary" class="submit_btn" @click="submitMsg"
          >发布</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "Message",
  data() {
    return {
      baseUrl: "https://api.dahuayoushanyun.com",
      activeName: "first",
      activeNameTwo: "first",
      msgList: [],
      num: 0,
      currentPage4: 1,
      pageSize: 3,
      textarea2: "",
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getMsg();
    },
    getMsg() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      let id = "";
      if (this.$route.query.id != undefined) {
        id = this.$route.query.id;
      }
      this.$axios({
        method: "GET",
        url: `${this.baseUrl}/Api/newpublic/forumlist?user_access_token=${token}&page=${this.pageSize}&page_num=${this.currentPage4}&literature_id=${id}`,
        headers: { "content-type": "application/json;charset=utf8" },
      }).then((res) => {
        this.msgList = res.data.data.forumlist;
        this.num = res.data.data.num;
      });
    },
    // 发布留言
    submitMsg() {
      this.$store.dispatch("setUserToken");
      let token = this.$store.getters.setToken;
      let status = "";
      let id = "";
      if (this.$route.query.status != undefined) {
        status = this.$route.query.status;
      }else {
        status = '1'
      }
      if (this.$route.query.id != undefined) {
        id = this.$route.query.id;
      }
      let username = localStorage.getItem("username");
      this.$axios({
        method: "POST",
        url: `${this.baseUrl}/api/newpublic/addforum`,
        data:qs.stringify({
          user_access_token:token,
          forum_type:status,
          forum_content:this.textarea2,
          user_name:username,
          literature_id:id
        }),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        if (res.data.status == "1") {
          console.log(1111111111)
          this.getMsg()
          this.textarea2 = ''
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          
        }
      });
    },
  },
  mounted() {
    this.getMsg();
  },
};
</script>

<style lang="stylus" scoped>
.container {
    width: 100%;
    height: 100%;
    padding: 2vh 7.7vw;
    box-sizing: border-box;
    font-size: 14px;
}
::v-deep .top_box .el-tabs__item {
    font-size: 20px !important;
    width: 3vw;
    text-align: center;
}
::v-deep .bottom_box .el-tabs__item {
    font-size: 20px !important;
    width: 5vw;
    text-align: center;
}
::v-deep .top_box .el-tabs__active-bar {
    width: 3vw !important;
    height: 0.5vh;
    background: #184E77;
    border-radius: 4px;
}
::v-deep .bottom_box  .el-tabs__active-bar {
    width: 5vw !important;
    height: 0.5vh;
    background: #184E77;
    border-radius: 4px;
}
::v-deep .el-tabs__item.is-active {
    color: #184E77;
}
.msg_content {
    width: 100%;
    border-bottom: 1px solid #F0F0F0;
    padding: 2vh 0 2vh 0;
    box-sizing: border-box;
}
.content_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
}
.msg_body {
    color: #000000;
    line-height: 22px;
    font-size: 0.9vw;
}
.el-pagination {
    right: 5vw;
}
::v-deep .bottom_box .el-textarea__inner {
    resize: none !important;
    background: #F4F4F4;
    border: none;
}
.btn_box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.submit_btn {
    width: 6vw;
}
</style>
