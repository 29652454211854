<template>
  <div class="login">
    <PhoneLogin
      :rule-form="PhoneForm"
 
    />
  </div>
</template>

<script>
import PhoneLogin from "./components/HelloWorld.vue";
export default {
  name: "MyAppFirst",

  data() {
    return {
      PhoneForm: {
        phone: "",
        code: "",
      },
    };
  },
  components: {
    PhoneLogin,
  },

  mounted() {},

  methods: {
  
    
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}


</style>