<template>
  <div id="app">
    <el-container
      style="width:100vw;height:100vh"
      v-if="$route.path != '/' && $route.path != '/Register' && $route.path != '/404'"
    >
      <el-header style="height:rem(110);padding:0;">
        <div class="loginbox_title">
          <span class="loginbox_title_middle"
            >上海市徐汇区腹壁疝多中心联合信息注册平台</span
          >
          <span class="top_title_right">{{ userName }}</span>
        </div>
        <div class="top_title_box">
          <div
            v-for="(item, index) in titleList"
            :key="index"
            :class="
              titleIndex == index
                ? 'top_title_item activeTitle'
                : 'top_title_item'
            "
            @click="changeTitle(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </el-header>
      <el-container>
        <router-view @handleTab="handleTabChange" ></router-view>
      </el-container>
    </el-container>
    <div v-if="$route.path == '/' || $route.path == '/Register' || $route.path == '/404'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      titleList: [
        { name: "首页" },
        { name: "审核" },
        { name: "文献上传" },
        { name: "留言" },
        { name: "退出" },
      ],
      titleIndex: 0,
      userName: "",
    };
  },
  methods: {
    // 切换头部选项
    changeTitle(index) {
      this.titleIndex = index;
      if (this.titleIndex == 0) {
        this.$router.push("/DocHou");
      } else if (this.titleIndex == 1) {
        this.$router.push("/Shenhe");
      } else if (this.titleIndex == 2) {
        this.$router.push("/Literature");
      } else if (this.titleIndex == 3) {
        this.$router.push("/Message");
      } else if (this.titleIndex == 4) {
        localStorage.removeItem("token");
        this.$router.push("/");
        this.$message({
          message: "退出成功",
          type: "success",
        });
      }
    },
    handleTabChange(index){
      console.log(index,'sjadijoi')
      this.titleIndex = index
    }
  },
  mounted() {
    this.userName = localStorage.getItem("username");
  },
};
</script>

<style lang="stylus">
vw_fontsize = 192;
vw_design = 1920;

rem(px) {
  (px / vw_fontsize) * 1rem;
}
.el-header {
  background-color: #fff;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 160px;
}

.loginbox_title {
  width: 100%;
  line-height: rem(50);
  height: rem(50);
  line-height: rem(50);
  background: #184e77;
  font-size: rem(20);
  color: #fff;
  position: relative;
}

.loginbox_title_middle {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.top_title_right {
  position: absolute;
  right: rem(20);
  font-size: rem(16);
}

.top_title_box {
  height: rem(60);
  // line-height: rem(60);
  padding: 0 rem(75);
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top_title_item {
  font-size: rem(20);
  height: rem(30);
  cursor: pointer;
}
.activeTitle {
  color: #184E77;
  border-bottom: rem(3) solid #184E77;
}

.el-menu-vertical-demo {
  border: none !important;
  background: none !important;
}

.el-menu-item {
  font-size: rem(18) !important;
}

.el-menu-item:hover {
  background: none !important;
}

.el-menu-item.is-active {
  color: #184E77 !important;
  background: #fff !important;
}
</style>
